import DataRenderer from "components/utility/DataRenderer";
import React, { forwardRef } from "react";
import { API_ENDPOINTS, RISK_VALUES } from "utils/constants";
import Allocater from "./_Allocater";
import MFInfo from "./_MFInfo";
import PeerComparisions from "./_PeerComparisions";
import styled from "styled-components/macro";
import HoldingsTable from "./_HoldingsTable";
import ReactSpeedometer from "react-d3-speedometer";
import RiskStatistics from "./_RiskStatistics";
import OtherInfo from "./_OtherInfo";

const SchemeDetails = ({
  nav_latest = null,
  nav_latest_date = null,
  day_change = null,
  min_sip = null,
  min_lumpsum = null,
  schemeDetails,
}) => {
  return (
    <div style={{ marginTop: "4rem" }}>
      <MFInfo
        asset_size={schemeDetails?.data.asset_size}
        risk={schemeDetails?.data.riskometer_value}
        day_change={day_change}
        nav_latest={nav_latest}
        nav_latest_date={nav_latest_date}
        min_sip={min_sip}
        min_lumpsum={min_lumpsum}
      />
      {/* <ReturnCalculatorSection ref={refs.returnCalculator}>
        <SectionHeading>Return Calculator</SectionHeading>
      </ReturnCalculatorSection> */}
      <HoldingAnalysisSection>
        <SectionHeading>Asset Holdings</SectionHeading>
        <Allocater
          data={schemeDetails.data.asset_allocations}
          nameMapper={"asset_type"}
          valueMapper={"asset_percentage"}
        />
        <h4 style={{ textAlign: "center", color: "#868e96" }}>Top Holdings</h4>
        <HoldingsTable holdings={schemeDetails.data.portfolio_holdings} />
      </HoldingAnalysisSection>
      <PeerComparisionSection>
        <SectionHeading>Peer Comparision</SectionHeading>
        <PeerComparisions funds={schemeDetails.data.peer_comparisons} />
      </PeerComparisionSection>
      <OtherStatisticsSection>
        <SectionHeading>Other Statistics</SectionHeading>
        <h4 style={{ textAlign: "center", color: "#868e96" }}>
          Risk Statistics
        </h4>
        <div
          style={{
            height: "180px",
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <ReactSpeedometer
            value={RISK_VALUES[schemeDetails.data.riskometer_value]}
            segments={5}
            labelFontSize="0px"
            valueTextFontSize="0px"
            segmentColors={[
              "#a3be8c",
              "#b48ead",
              "#ebcb8b",
              "#d08770",
              "#bf616a",
            ]}
            // startColor will be ignored
            // endColor will be ignored
          />
        </div>
        <p style={{ textAlign: "center", color: "#868e96" }}>
          {schemeDetails.data.riskometer_value} Risk
        </p>
        <RiskStatistics holdings={schemeDetails.data.risk_statistics} />
        <OtherInfo
          exit_load={schemeDetails?.data.exit_load}
          exit_load_comment={schemeDetails?.data.exit_load_comment}
          expense_ratio={schemeDetails?.data.expense_ratio}
          scheme_status={schemeDetails?.data.scheme_status}
          scheme_turnover={schemeDetails?.data.scheme_turnover}
          is_dividend_scheme={schemeDetails?.data.is_dividend_scheme}
        />
      </OtherStatisticsSection>
      <FundManagementSection>
        <SectionHeading>Fund Management</SectionHeading>
        <div style={{ margin: "4rem 0" }}>
          <h4 style={{ marginBottom: "6px" }}>Scheme Objective</h4>
          <p style={{ color: "#868e96" }}>
            {schemeDetails?.data.scheme_objective}
          </p>
        </div>
        <div>
          <h4 style={{ marginBottom: "6px" }}>Fund Managers</h4>
          <div style={{}}>
            {schemeDetails?.data.scheme_managers.map((curr, i) => {
              return (
                <p
                  style={{
                    marginBottom: "4px",
                    paddingLeft: 0,
                    color: "#868e96",
                  }}
                  key={i}
                >
                  {curr.name}
                </p>
              );
            })}
          </div>
        </div>
      </FundManagementSection>
    </div>
  );
};

export default SchemeDetails;

const Section = styled.section`
  margin-bottom: 8rem;
  /* border-bottom: 1px solid #ddd; */
  padding: 1rem 0;
`;

const SchemeDetailsSection = forwardRef((props, ref) => (
  <Section ref={ref} id="schemeDetails">
    {props.children}
  </Section>
));

const ReturnCalculatorSection = forwardRef((props, ref) => (
  <Section ref={ref} id="returnCalculator">
    {props.children}
  </Section>
));

const HoldingAnalysisSection = forwardRef((props, ref) => (
  <Section id="holdingAnalysis" ref={ref}>
    {props.children}
  </Section>
));

const PeerComparisionSection = forwardRef((props, ref) => (
  <Section id="peerComparision" ref={ref}>
    {props.children}
  </Section>
));

const OtherStatisticsSection = forwardRef((props, ref) => (
  <Section id="otherStatistics" ref={ref}>
    {props.children}
  </Section>
));

const FundManagementSection = forwardRef((props, ref) => (
  <Section id="fundManagement" ref={ref}>
    {props.children}
  </Section>
));

const SectionHeading = styled.h3`
  font-size: 1.6rem;
  margin-bottom: 1rem;
`;
