import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { InputWrapper, InputContainer, Icon, Input } from "./InputField.styles";
import { ErrorMessage, Label, Label2 } from "styles/common-styled-components";

const InputField = ({
  label,
  error,
  appendLeft,
  appendRight,
  noSpin,
  passwordField = false,
  labelInputDirection = "vertical",
  ...inputProps
}) => {
  const hasError = Boolean(error);
  const appendPosition = appendLeft ? "left" : appendRight ? "right" : "";

  const [showPassword, setShowPassword] = useState(!passwordField);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      <InputWrapper labelInputDirection={labelInputDirection}>
        {label && (
          <Label2 labelInputDirection={labelInputDirection}>{label}</Label2>
        )}
        <div style={{ flex: 2 }}>
          <InputContainer>
            {appendLeft && <Icon position="left">{appendLeft}</Icon>}
            <Input
              noSpin={noSpin}
              hasError={hasError}
              appendPosition={appendPosition}
              type={showPassword ? "text" : "password"}
              {...inputProps}
            />
            {passwordField && (
              <Icon position="right" onClick={handleTogglePassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </Icon>
            )}
            {appendRight && <Icon position="right">{appendRight}</Icon>}
          </InputContainer>
        </div>
      </InputWrapper>
      {hasError && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {labelInputDirection === "horizontal" ? (
            <div style={{ flex: 1 }} />
          ) : (
            ""
          )}
          <ErrorMessage show={hasError} style={{ flex: 2 }}>
            {error}
          </ErrorMessage>
        </div>
      )}
    </div>
  );
};

export default InputField;
