import React from "react";
import { TabCont, TabsContainer } from "./Tab.styles";

const Tab = ({ options, selectedOption, onSelect }) => {
  return (
    <TabsContainer>
      {options.map((curr, index) => {
        return (
          <TabCont
            selectedStyle={curr === selectedOption ? true : false}
            key={index}
            onClick={() => {
              onSelect(curr);
            }}
          >
            {curr}
          </TabCont>
        );
      })}
    </TabsContainer>
  );
};

export default Tab;
