import React, { useState } from "react";

import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";

import RadioComp from "components/ui/RadioComp/RadioComp";
import SelectBankForm from "components/forms/SelectBankForm";
import FetchBankFromIfsc from "components/forms/FetchBankFromIfsc";
import { StyledAlignCenter } from "styles/common-styled-components";

const SelectBank = () => {
  const [selectFormat, setSelectFormat] = useState("name");

  return (
    <>
      <RegistrationCapsule
        heading={"Add your Bank account"}
        para={"Select your Bank to proceed with the adding Bank."}
      >
        <div style={{ padding: "2rem" }}>
          <RadioComp
            selected={selectFormat === "name"}
            label={"Select your Bank"}
            onChange={() => setSelectFormat("name")}
          >
            <SelectBankForm />
          </RadioComp>
          <StyledAlignCenter
            style={{
              gap: "2rem",
              margin: "2rem 0",
              marginLeft: "calc(14px +  4rem)",
            }}
          >
            <div
              style={{
                height: "1px",
                backgroundColor: "#ddd",
                width: "100%",
              }}
            />
            <div>or</div>
            <div
              style={{
                height: "1px",
                backgroundColor: "#ddd",
                width: "100%",
              }}
            />
          </StyledAlignCenter>
          <RadioComp
            selected={selectFormat === "ifsc"}
            label={"Enter your Bank IFSC Code"}
            onChange={() => setSelectFormat("ifsc")}
          >
            <FetchBankFromIfsc />
          </RadioComp>
        </div>
      </RegistrationCapsule>
    </>
  );
};

export default SelectBank;
