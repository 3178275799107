import { Radio } from "@mui/material";
import { Label, StyledAlignCenter } from "styles/common-styled-components";

const RadioComp = ({ selected, onChange, label, children }) => {
  return (
    <div>
      <StyledAlignCenter style={{ gap: "1rem", marginBottom: "1rem" }}>
        <Radio
          checked={selected}
          onChange={onChange}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 24,
            },
            color: "var(--secondary-light-grey)",
            "&.Mui-checked": {
              color: "var(--primary)",
            },
          }}
        />
        {label && (
          <Label
            style={{ marginBottom: 0, cursor: "pointer" }}
            onClick={onChange}
          >
            {label}
          </Label>
        )}
      </StyledAlignCenter>
      {selected ? <div>{children}</div> : null}
    </div>
  );
};

export default RadioComp;
