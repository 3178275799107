import { configureStore } from "@reduxjs/toolkit";
import userReducer from "redux/user/user-slice";
import themeReducer from "redux/theme/theme-slice";
import recentSearchReducer from "redux/recent-search/recent-search-slice";

import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, userReducer);
const persistedTheme = persistReducer(persistConfig, themeReducer);
const persistedSearch = persistReducer(persistConfig, recentSearchReducer);
const store = configureStore({
  reducer: {
    user: persistedReducer,
    theme: persistedTheme,
    search: persistedSearch,
    middleware: [thunk],
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
