import styled from "styled-components";
import { STYLE_VALUES } from "utils/constants";

const MainCont = styled.div`
  display: flex;
  gap: 4rem;
  align-items: center;
  box-shadow: ${STYLE_VALUES.boxShadow};
  padding: 2rem 4rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const LeftCont = styled.div`
  flex: 1;
  width: 48rem;
  height: 48rem;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 600px) {
    height: 28rem;
    width: 28rem;
  }
`;

const RightCont = styled.div`
  flex: 1;
  width: 40rem;

  @media (max-width: 600px) {
    width: 28rem;
  }
`;

export const BannerFormComp = ({ leftCont, rightCont }) => {
  return (
    <MainCont>
      <LeftCont>{leftCont}</LeftCont>
      <RightCont>{rightCont}</RightCont>
    </MainCont>
  );
};
