import { Event } from "@mui/icons-material";
import BottomSheet from "components/common/BottomSheet/BottomSheet";
import { DateSelectorComponent } from "components/common/InvestOptions/InvestOptions";
import MobileNav from "components/common/MobileNav/MobileNav";
import Button from "components/ui/Button/Button";
import CustomPinKeyboard from "components/ui/CustomPinKeyboard/CustomPinKeyboard";
import styled from "styled-components";

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ErrorMessage,
  StyledAlignCenter,
  StyledCentering,
  StyledSpaceBetweenAlignCenter,
} from "styles/common-styled-components";
import { convertNumberToCurrency } from "utils/functions";
import { centering } from "styles/common-styles";

const MobileSIP = ({ noSip }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data, editStateFromPaymentMethod: editState } = state;

  const montly_frequency = noSip
    ? null
    : data.sip_schemes.filter((curr) => curr.sip_frequency === "MONTHLY")[0];

  const dates = noSip ? null : montly_frequency.sip_start_dates.split(",");

  const minAmount =
    data.sip_schemes.length === 0 ? null : montly_frequency?.min_sip_inst;

  const maxAmount =
    data.sip_schemes.length === 0 ? null : montly_frequency?.max_sip_inst;

  const [investValue, setInvestValue] = useState(
    editState !== null && editState?.amount ? editState?.amount : "0"
  );
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(true);
  const [error, setError] = useState("");
  const [date, setDate] = useState({ number: "1", suffix: "st" });

  const handleDigit = (digit) => {
    if (digit === ".") {
      return;
    }
    setInvestValue((prev) => {
      if (digit === "-") {
        const newValue = prev.slice(0, -1);
        return newValue;
      } else {
        return prev + `${digit}`;
      }
    });
  };

  const handleInvest = () => {
    if (!investValue || Number(investValue) < minAmount) {
      setError(`Minimum SIP amount is ${minAmount}`);
      return;
    } else if (Number(investValue) > maxAmount) {
      setError(`Maximum SIP amount is ${maxAmount}`);
      return;
    } else {
      setError("");
      const { bse_code, id, name } = data;
      navigate(`/mutual-funds/${data.id}/payment`, {
        state: {
          amount: parseInt(investValue),
          investmentType: "sip",
          monthlySipDate: `${date.number}${date.suffix}`,
          bse_code,
          id,
          name,
        },
        replace: true,
      });
    }
  };
  return (
    <>
      <MobileNav headerTitle={"SIP Investment"} />
      <div>
        <div style={{ marginBottom: "1rem" }}>
          <p
            style={{
              textAlign: "center",
              fontWeight: "600",
              margin: "2.4rem",
              fontSize: "2rem",
            }}
          >
            Enter the Installment amount
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "2.8rem",
              justifyContent: "center",
              fontFamily: "'Inter Tight', sans-serif",
              margin: "2.4rem",
              color: "var(--themeColor)",
            }}
            onClick={() => setShowKeyboard(true)}
          >
            {convertNumberToCurrency(investValue)}
          </p>
          {error ? (
            <ErrorMessage style={{ textAlign: "center", marginBottom: "2rem" }}>
              {error}
            </ErrorMessage>
          ) : null}

          <StyledCentering
            style={{
              flexWrap: "wrap",
              margin: "2.4rem",
              gap: "1rem",
            }}
          >
            <AddSpecificSIPAmount
              onClick={() => setInvestValue(String(minAmount))}
            >
              {convertNumberToCurrency(minAmount)}
            </AddSpecificSIPAmount>
            {2 * minAmount < maxAmount ? (
              <AddSpecificSIPAmount
                onClick={() => setInvestValue(String(2 * minAmount))}
              >
                {convertNumberToCurrency(2 * minAmount)}
              </AddSpecificSIPAmount>
            ) : null}

            {3 * minAmount < maxAmount ? (
              <AddSpecificSIPAmount
                onClick={() => setInvestValue(String(3 * minAmount))}
              >
                {convertNumberToCurrency(3 * minAmount)}
              </AddSpecificSIPAmount>
            ) : null}
          </StyledCentering>
        </div>

        <StyledSpaceBetweenAlignCenter
          onClick={() => setShowDateSelector(true)}
          style={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "1rem",
            fontSize: "1.6rem",
          }}
        >
          <StyledAlignCenter>
            <Event style={{ fontSize: "24px" }} />
            <p style={{}}>SIP Date</p>
          </StyledAlignCenter>
          <p style={{ color: "var(--themeColor)" }}>
            {date.number}
            {date.suffix} of every month
          </p>
        </StyledSpaceBetweenAlignCenter>
        <div style={{ position: "fixed", left: 0, right: 0, bottom: 0 }}>
          {showKeyboard ? (
            <>
              <CustomPinKeyboard onKeyPress={handleDigit} />
            </>
          ) : null}
          <div style={{ padding: "1rem" }}>
            <Button title={"Start SIP"} onClick={handleInvest} />
          </div>
        </div>
      </div>
      <BottomSheet
        containerStyles={{ padding: "3rem 2rem" }}
        show={showDateSelector}
        close={() => {
          setShowDateSelector(false);
        }}
      >
        <DateSelectorComponent
          style={{ boxShadow: "none", position: "static" }}
          closeModal={() => setShowDateSelector(false)}
          dates={dates}
          editState={editState}
          onConfirm={(d, suffix) => {
            setDate({ number: d, suffix });
          }}
        />
      </BottomSheet>
    </>
  );
};

export default MobileSIP;

const AddSpecificSIPAmount = styled.div`
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-size: 16px;
  ${centering}
`;
