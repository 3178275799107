import styled from "styled-components";

export const DatePickerWrapper = styled.div`
  display: inline-block;
  flex: 2;
  width: 100%;
`;

export const CalendarContainer = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

export const CalendarHeader = styled.div`
  background-color: #f2f2f2;
  padding: 8px;
  text-align: center;
  display: flex;
  justify-content: space-between;

  button {
    cursor: pointer;
    background-color: white;
    border: 1px solid #ccc;
  }
`;

export const YearDropdown = styled.select`
  padding: 1rem;
  border-radius: 4px;
  color: #333;
  font-size: 1.6rem;
  width: 100%;
  margin: 0 1rem;
  border: 1px solid #ccc;
`;

export const CalendarDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export const CalendarDay = styled.div`
  padding: 8px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;
