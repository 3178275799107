import { Info } from "@mui/icons-material";
import React from "react";

const Note = ({
  note = "Please do not refresh or close this page",
  contStyle,
}) => {
  return (
    <div
      style={{
        padding: "1rem",
        border: "1px solid #FFA500",
        borderRadius: "4px",
        color: "#FFA500",
        marginBottom: "2.4rem",
        width: "100%",
        textAlign: "center",
        ...contStyle,
      }}
    >
      <p>
        <Info /> {note}
      </p>
    </div>
  );
};

export default Note;
