import styled from "styled-components/macro";
import CurrencyInput from "react-currency-input-field";
import { InputCurrencyStyle, InputStyle } from "styles/common-styles";

export const Container = styled.div`
  /* margin: 2rem 0; */
`;

export const Label = styled.label`
  font-size: 1.6rem;
  display: block;
  margin-bottom: 6px;
  color: var(--medium-grey);
`;

export const Input = styled.input`
  ${InputStyle}
`;

export const InputAndForward = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`;

export const CurrencyStyledInput = styled(CurrencyInput)`
  ${InputCurrencyStyle}
  color: ${({ error, theme }) =>
    error ? theme.errorTextColor : "var(--themeColor)"};
`;
