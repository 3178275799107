import styled, { css, keyframes } from "styled-components";
import { HighlightWrapper } from "styles/common-styled-components";
import { AlignCenter } from "styles/common-styles";

export const UserFieldsCont = styled.div`
  width: 40rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 6px;
  min-height: 40vh;
  text-align: center;
  position: fixed;
  background-color: ${({ theme }) => theme.cardBackground};
  padding: 4rem;
  z-index: 10;
`;

export const NoneSelected = styled.div`
  height: 10rem;
  width: 100%;
  border-top: ${({ theme }) => `1px solid ${theme.borderColor}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
  margin-bottom: 3rem;
  ${AlignCenter}
`;

export const MobileContainer = styled.div`
  display: none;

  @media (max-width: 980px) {
    padding: 1rem 0;
    display: block;
    height: calc(100vh - 12rem);
    overflow: auto;
  }
`;

export const InvestmentsOverviewCont = styled.div`
  flex: 2;

  @media (max-width: 980px) {
    display: none;
  }
`;

export const InvestmentsTableCont = styled.div`
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.cardBackground};
  /* overflow: hidden; */
`;

export const InvestmentsOverviewHead = styled.div`
  background-color: rgba(32, 178, 171, 0.1);
  display: flex;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  justify-content: space-between;
`;

export const InvestmentOverviewFilter = styled.div`
  display: grid;
  grid-template-columns: 10fr repeat(3, 6fr);
  padding: 2rem;
  border-bottom: 1px dotted #eee;

  div {
    justify-self: end;
  }
`;

export const InvestmentsOverviewList = styled.div`
  /* height: 50vh;
  overflow: auto; */
`;

const active_investment = css`
  background-color: ${({ theme }) => theme.tagBackground};
`;

const inactive_investment = css`
  background-color: ${({ theme }) => theme.cardBackground};
`;
export const InvestmentDetailCard = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 10fr repeat(3, 6fr);
  padding: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  cursor: pointer;

  ${({ active }) => (active ? active_investment : inactive_investment)}

  p {
    justify-self: end;
  }

  &:hover {
    background-color: ${({ theme }) => theme.tagBackground};
  }
`;

export const MoreInfoWrapper = styled.div`
  position: absolute;
  right: 0;
  z-index: 1;
  display: ${({ open }) => (open ? "block" : "none")};
  background-color: ${({ theme }) => theme.cardBackground};
  min-width: 300px;
  box-shadow: rgba(32, 178, 171, 0.3) 0px 7px 29px 0px;
`;

export const MoreInfoCard = styled.div`
  background-color: white;
  border-radius: 4px;
`;

export const LabelAndValue = styled.div`
  display: flex;
`;

export const SelectedInvestmentData = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  gap: 10px;
  margin-top: 3rem;
  cursor: default;

  label {
    color: #777;
    font-size: 13px;
    flex: 1;
  }

  p {
    font-weight: 500;
    font-size: 13px;
    flex: 1;
  }
`;

export const MoreInfoButton = styled.button`
  background: none;
  border: 0;
  padding: 2rem;
  border-top: 1px solid var(--secondaryLightGrey);
  width: 100%;
  transition: all 0.3s ease-in-out;
  text-align: left;
  font-weight: 500;
  color: var(--themeBluishGrey);
  /* color: var(--themeColor); */
  display: flex;
  align-items: center;
  gap: 1rem;

  &:hover {
    background-color: var(--lightGrey);
    cursor: pointer;
  }
`;

export const LinkType = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 500;
  color: "#222";

  line-height: 1.6;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const InvestmentCont = styled.div`
  flex: 1;
  @media (max-width: 980px) {
    display: none;
  }
`;

export const MobileMainContainer = styled.div`
  display: none;
  @media (max-width: 980px) {
    display: block;
    margin-top: 6rem;
  }
`;

export const MobileInvestmentCardComp = styled.div`
  padding: 2rem 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  display: flex;
  gap: 2rem;
  justify-content: space-between;
`;

export const InvestmentFilterTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
`;

export const InvestmentFilterTitleName = styled.span`
  color: ${({ color, theme }) => (color === "dark" ? theme.text : "#888")};
`;

export const InvestmentTypeTag = styled.span`
  opacity: 0.9;
  color: ${({ theme }) => theme.text};
  font-weight: 500;
  background-color: ${({ theme }) => theme.tagBackground};
  padding: 1rem 1.4rem;
  border-radius: 100px;
  display: inline-block;
  justify-self: start;
`;

export const RedeemCard = styled(HighlightWrapper)`
  width: 40rem;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  /* height: calc(100vh - 22rem); */
  overflow: auto;
`;
