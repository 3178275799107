import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { createPortal } from "react-dom";

import useScrollLock from "hooks/useScrollLock";
import { ANIMATION_STYLES } from "utils/constants";

import {
  ModalBackDrop,
  ModalContent,
  ModalContentWrapper,
} from "./ModalPopup.styles";

const ModalPopup = ({
  children,
  show,
  onClose,
  style,
  animationStyle = "scale",
}) => {
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    if (show) {
      lockScroll();
    } else {
      unlockScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return createPortal(
    <AnimatePresence>
      {show ? (
        <>
          <ModalBackDrop
            key={"modal-backdrop"}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
            }}
            as={motion.div}
          >
            <ModalContentWrapper
              initial={ANIMATION_STYLES[animationStyle].initial}
              animate={ANIMATION_STYLES[animationStyle].animate}
              exit={ANIMATION_STYLES[animationStyle].exit}
              as={motion.div}
            >
              <div style={{ position: "relative" }}>
                <ModalContent
                  style={{ ...style }}
                  as={motion.div}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  exit={{
                    opacity: 0,
                    transition: {
                      delay: 0,
                    },
                  }}
                >
                  {children}
                </ModalContent>
              </div>
            </ModalContentWrapper>
          </ModalBackDrop>
        </>
      ) : null}
    </AnimatePresence>,
    document.body
  );
};

export default ModalPopup;
