import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import timeImage from "assets/banner/Time-management.gif";
import styled from "styled-components";
import { useMutation } from "react-query";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { StyledCentering } from "styles/common-styled-components";
import Note from "components/common/Note/Note";
import Lottie from "lottie-react";
import paymentSuccessAnimation from "assets/json/payment-succes.json";
import paymentRejectedAnimation from "assets/json/14651-error-animation.json";

import DataTable from "components/common/DataTable";
import Button from "components/common/Button/Button";
import { convertNumberToCurrency } from "utils/functions";

const UpiTransactionTimer = () => {
  const PAYMENT_STATUSES = {
    progress: "Progress",
    approved: "Approved",
    rejected: "Rejected",
    timedOut: "Timed Out",
  };
  const NUMBER_OF_MINUTES = 5;
  const NUMBER_OF_SECONDS = NUMBER_OF_MINUTES * 60;
  const CALL_EVERY = 15;

  const expiryTimestamp = Date.now() + NUMBER_OF_SECONDS * 1000;
  const location = useLocation();
  const navigate = useNavigate();
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUSES.progress);

  const checkStatusOfPayment = () => {
    return credentialAxios.post(API_ENDPOINTS.checkTransactionStatus, {
      order_id: location.state.order_id,
    });
  };

  const onSuccess = (res) => {
    if (res.data.status === "APPROVED") {
      setPaymentStatus(PAYMENT_STATUSES.approved);
    }
    if (res.data.status === "REJECTED") {
      setPaymentStatus(PAYMENT_STATUSES.rejected);
    }
  };

  const { mutate } = useMutation(checkStatusOfPayment, {
    onSuccess,
  });

  useEffect(() => {
    if (seconds % CALL_EVERY === 0) {
      mutate();
    }

    if (seconds === 0 && minutes === 0) {
      setPaymentStatus(PAYMENT_STATUSES.timedOut);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);
  return (
    <Container>
      <StyledCentering>
        {paymentStatus !== PAYMENT_STATUSES.progress ? (
          paymentStatus === PAYMENT_STATUSES.rejected ||
          paymentStatus === PAYMENT_STATUSES.timedOut ? (
            <StyledCentering
              style={{ maxWidth: "35rem", flexDirection: "column" }}
            >
              <StyledCentering style={{ width: "100%" }}>
                <Lottie
                  animationData={paymentRejectedAnimation}
                  style={{
                    width: "60%",
                  }}
                  loop={false}
                />
              </StyledCentering>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.8rem",
                  fontWeight: "800",
                  color: "var(--errorTextColor)",
                  marginBottom: "1rem",
                }}
              >
                {paymentStatus === PAYMENT_STATUSES.rejected
                  ? "Payment request is rejected"
                  : "Payment Timed Out"}
              </p>
              <p style={{ textAlign: "center", marginBottom: "3rem" }}>
                {paymentStatus === PAYMENT_STATUSES.rejected
                  ? "We cannot process your payment as request has been declined."
                  : "We apologize, but it seems that the request for payment has exceeded the allotted time."}
              </p>

              <div>
                <DataTable
                  data={{
                    "Order ID": location.state.order_id,
                    Amount: convertNumberToCurrency(location.state.amount),
                  }}
                />
                <p style={{ margin: "1rem 0", textAlign: "center" }}>
                  For more details, go to orders in your account or press button
                  below.
                </p>
              </div>
              <Button
                variant="error"
                name="GO TO ORDERS"
                onClick={() =>
                  navigate(`/orders/${location.state.order_id}`, {
                    replace: true,
                  })
                }
              />
            </StyledCentering>
          ) : (
            <div style={{ maxWidth: "35rem" }}>
              <StyledCentering style={{ width: "100%" }}>
                <Lottie
                  animationData={paymentSuccessAnimation}
                  style={{
                    width: "60%",
                  }}
                  loop={false}
                />
              </StyledCentering>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.8rem",
                  fontWeight: "800",
                  color: "var(--themeColor)",
                  marginBottom: "1rem",
                }}
              >
                Order Placed Successfully
              </p>
              <p style={{ textAlign: "center", marginBottom: "3rem" }}>
                Your order has been successfully processed. Details of
                transaction are included below.
              </p>
              <div>
                <DataTable
                  data={{
                    "Order ID": location.state.order_id,
                    Amount: convertNumberToCurrency(location.state.amount),
                  }}
                />
                <p style={{ margin: "1rem 0", textAlign: "center" }}>
                  For more details, go to orders in your account or press button
                  below.
                </p>
                <Button
                  name="GO TO ORDERS"
                  onClick={() =>
                    navigate(`/orders/${location.state.order_id}`, {
                      replace: true,
                    })
                  }
                />
              </div>
            </div>
          )
        ) : (
          <div style={{ maxWidth: "35rem" }}>
            <Note note={"Please do not refresh or close this page."} />
            <StyledCentering>
              <LazyLoadImage
                src={timeImage}
                alt="man with clock in his hand"
                style={{
                  position: "absolute",
                  opacity: 0.1,
                  top: "20%",
                  maxWidth: "40rem",
                }}
              />
            </StyledCentering>

            <StyledCentering>
              <div style={{ width: 150, height: 150, position: "relative" }}>
                <CircularProgressbar
                  minValue={0}
                  maxValue={NUMBER_OF_SECONDS}
                  value={minutes * 60 + seconds}
                  strokeWidth={8}
                  styles={{
                    // Customize the root svg element
                    root: {},
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: "var(--themeColor)",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      // Customize transition animation
                      transition: "stroke-dashoffset 0.5s ease 0s",
                      // Rotate the path
                      transform: "rotate(0turn)",
                      transformOrigin: "center center",
                    },
                    trail: {
                      stroke: "#efefef",
                    },
                  }}
                ></CircularProgressbar>
                <Timer>
                  {`${minutes}`.padStart(2, 0)}:{`${seconds}`.padStart(2, 0)}
                </Timer>
              </div>
            </StyledCentering>
            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "bold",
                textAlign: "center",
                margin: "2rem",
              }}
            >
              Please check your UPI app and accept payment request.
            </p>
          </div>
        )}
      </StyledCentering>
    </Container>
  );
};

export default UpiTransactionTimer;

const Container = styled.div`
  padding-top: 7rem;
`;

const Timer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.4rem;
  font-weight: bold;

  letter-spacing: 2px;
`;
