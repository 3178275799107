import React from "react";
import { usePagination, DOTS } from "hooks/usePagination";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  ChevronCont,
  Container,
  PageNumber,
  PaginationCont,
} from "./Pagination.styles";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 0,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Container>
      <PaginationCont>
        <ChevronCont onClick={onPrevious} disable={currentPage === 1}>
          <ChevronLeft />
        </ChevronCont>

        {paginationRange.map((pageNumber, i) => {
          if (pageNumber === DOTS) {
            return <DOTS key={`${i}`} />;
          }

          return (
            <PageNumber
              key={`${i}`}
              active={pageNumber === currentPage}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </PageNumber>
          );
        })}

        <ChevronCont onClick={onNext} disable={currentPage === lastPage}>
          <ChevronRight />
        </ChevronCont>
      </PaginationCont>
    </Container>
  );
};

export default Pagination;
