import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import {
  ArrowUpward,
  ArrowDownward,
  ViewModule,
  ViewList,
} from "@mui/icons-material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DataRenderer from "components/utility/DataRenderer";
import { API_ENDPOINTS, COLLECTIONS, TEMP_CATEGORIES } from "utils/constants";
import Image from "components/ui/Image/Image";
import { MFImageCont } from "pages/MutualFundsFilter/MutualFundsFilter.styles";
import {
  DesktopView,
  MobileView,
  StyledAlignCenter,
  StyledLink,
} from "styles/common-styled-components";
import Select from "components/ui/Select/Select";
import { capitalizeText } from "utils/functions";

const CollectionsDataCont = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 0 1.8rem;
  }
`;
const Collections = ({ category }) => {
  const { state } = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(TEMP_CATEGORIES[0]);
  return (
    <>
      <MobileView style={{ height: "6rem" }} />
      <DataRenderer
        apiEndpoint={API_ENDPOINTS.topPerformingFunds}
        queryName={"top-funds"}
        requestBody={{ category: category || selectedCategory }}
        requestType="POST"
        queryDependencies={[selectedCategory]}
        renderData={(data) => {
          const {
            category_return: categoryData,
            benchmark_return: benchmarkData,
          } = data.data || {};
          return (
            <CollectionsDataCont style={{}}>
              <div style={{ flex: 4.5 }}>
                <FundListComponent
                  showCategoryDropdown={!category}
                  funds={data.data.schemes}
                  selectedCategory={selectedCategory}
                  onChange={(v) => {
                    setSelectedCategory(v);
                  }}
                  INDEX={state.INDEX}
                />
              </div>
              {/* <div style={{ flex: 2.5 }}>
                <CategoryReturnChart
                  categoryData={categoryData}
                  benchmarkData={benchmarkData}
                />
              </div> */}
            </CollectionsDataCont>
          );
        }}
      />
    </>
  );
};

export default Collections;

const CategoryReturnChart = ({ categoryData = {}, benchmarkData = {} }) => {
  const chartData = useMemo(() => {
    const years = [
      "2007",
      "2008",
      "2009",
      "2010",
      "2011",
      "2012",
      "2013",
      "2014",
      "2015",
      "2016",
      "1year",
      "ytd",
      "7days",
      "1month",
      "3month",
      "6month",
      "2year",
      "3year",
      "4year",
      "5year",
      "10year",
      "inception",
    ];

    return years.map((year) => ({
      name: year,
      category:
        categoryData[`returns_abs_${year}`] ??
        categoryData[`returns_cmp_${year}`] ??
        0,
      benchmark:
        benchmarkData[`returns_abs_${year}`] ??
        benchmarkData[`returns_cmp_${year}`] ??
        0,
    }));
  }, [categoryData, benchmarkData]);

  return (
    <ResponsiveContainer
      width="100%"
      height={400}
      style={{
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.075)",
        padding: "1rem 0",
        borderRadius: 8,
      }}
    >
      <BarChart
        data={chartData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tick={{ fontSize: 12 }}
          label={{
            value: "Years",
            position: "insideBottom",
            offset: -5,
            style: { textAnchor: "middle", fontSize: 14 },
          }}
        />
        <YAxis
          tick={{ fontSize: 12 }}
          label={{
            value: "Returns (%)",
            angle: -90,
            position: "insideLeft",
            style: { textAnchor: "middle", fontSize: 14 },
          }}
        />
        <Tooltip />
        <Legend
          verticalAlign="bottom"
          height={36}
          content={({ payload }) => (
            <div
              style={{
                display: "flex",
                fontSize: 14,
                margin: "20px 0",
              }}
            >
              {payload.map((entry, index) => (
                <span key={`legend-${index}`} style={{ margin: "0 10px" }}>
                  <span
                    style={{
                      display: "inline-block",
                      width: 10,
                      height: 10,
                      backgroundColor: entry.color,
                      marginRight: 5,
                      textAlign: "left",
                    }}
                  />
                  {entry.value}
                </span>
              ))}
            </div>
          )}
        />
        <Bar dataKey="category" fill="#8884d8" name="Category Return" />
        <Bar dataKey="benchmark" fill="#82ca9d" name="Benchmark Return" />
      </BarChart>
    </ResponsiveContainer>
  );
};

const Container = styled.div``;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 2rem;
`;

const Title = styled.p`
  font-size: 1.4rem;
`;

const SortControls = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SortButton = styled.button`
  background-color: ${(props) =>
    props.active ? "var(--themeColor)" : "#f0f0f0"};
  color: ${(props) => (props.active ? "white" : "#333")};
  border: none;
  padding: 1rem 1.6rem;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    background-color: ${(props) =>
      props.active ? "var(--themeColorShade5)" : "#e0e0e0"};
  }
`;

const ViewToggleButton = styled.button`
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 1rem;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  &:hover {
    background-color: #e0e0e0;
  }

  @media (max-width: 600px) {
    padding: 0;
    background-color: #0000;

    &:hover {
      background-color: #0000;
    }

    svg {
      font-size: 2.4rem;
    }
  }
`;

const FundGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const FundCard = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;

const FundHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 15px;
  background-color: #f8f9fa;
`;

const FundInfo = styled.div``;

const FundName = styled.p`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  &:hover {
    color: var(--themeColor);
    cursor: pointer;
  }
`;

const FundCategory = styled.p`
  margin: 5px 0 0;
  font-size: 1.2rem;
  color: #868e96;
`;

const ReturnsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const ReturnsRow = styled.tr`
  &:nth-child(even) {
    background-color: #f8f9fa;
  }
`;

const ReturnsCell = styled.td`
  padding: 10px 15px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: ${({ highlight }) =>
    highlight ? "var(--themeColor)" : "var(--themeBluishGrey)"};

  @media (max-width: 600px) {
    color: var(--themeBluishGrey);
  }
`;

const ReturnsHeader = styled.th`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--light-grey);
  font-weight: 300;
  color: ${({ highlight }) =>
    highlight ? "var(--themeColor)" : "var(--themeBluishGrey)"};

  @media (max-width: 600px) {
    color: var(--themeBluishGrey);
  }
`;

const ListViewContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  border: 1px solid var(--light-grey);
`;

const ListViewHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  padding: 16px;
  border-bottom: 1px solid var(--light-grey);
  font-weight: bold;

  @media (max-width: 600px) {
    grid-template-columns: 3fr 1fr;
  }
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align || "left"};
  cursor: pointer;
  color: ${(props) => (props.active ? "var(--themeColor)" : "inherit")};
  font-weight: 500;

  @media (max-width: 600px) {
    display: ${({ dontShowInMobile }) => (dontShowInMobile ? "none" : "flex")};
    text-align: ${({ textAlignMobile }) => textAlignMobile};
  }

  &:hover {
    color: var(--themeColor);
  }
`;

const ListViewRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  padding: 16px;
  border-bottom: 1px solid var(--light-grey);
  align-items: center;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 600px) {
    grid-template-columns: 3fr 1fr;
  }
`;

const FundDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ReturnValue = styled.p`
  margin: 0;
  text-align: right;
  font-weight: ${({ highlight }) => (highlight ? "bold" : "normal")};
  color: ${({ highlight }) => (highlight ? "var(--themeColor)" : "inherit")};

  @media (max-width: 600px) {
    display: ${({ dontShowInMobile }) => (dontShowInMobile ? "none" : "block")};
  }
`;

const ListView = ({ funds, sortConfig, requestSort }) => (
  <ListViewContainer>
    <ListViewHeader>
      <HeaderItem>Fund name</HeaderItem>
      <HeaderItem
        align="right"
        active={sortConfig.key === "returns_abs_1year"}
        onClick={() => requestSort("returns_abs_1year")}
        textAlignMobile="right"
        dontShowInMobile={sortConfig.key !== "returns_abs_1year"}
      >
        <p>1Y</p>
        {sortConfig.key === "returns_abs_1year" &&
          (sortConfig.direction === "asc" ? (
            <ArrowUpward />
          ) : (
            <ArrowDownward />
          ))}
      </HeaderItem>
      <HeaderItem
        align="right"
        active={sortConfig.key === "returns_cmp_3year"}
        onClick={() => requestSort("returns_cmp_3year")}
        dontShowInMobile={sortConfig.key !== "returns_cmp_3year"}
        textAlignMobile="right"
      >
        <p>3Y</p>
        {sortConfig.key === "returns_cmp_3year" &&
          (sortConfig.direction === "asc" ? (
            <ArrowUpward />
          ) : (
            <ArrowDownward />
          ))}
      </HeaderItem>
      <HeaderItem
        align="right"
        active={sortConfig.key === "returns_cmp_5year"}
        onClick={() => requestSort("returns_cmp_5year")}
        textAlignMobile="right"
        dontShowInMobile={sortConfig.key !== "returns_cmp_5year"}
      >
        <p>5Y</p>{" "}
        {sortConfig.key === "returns_cmp_5year" &&
          (sortConfig.direction === "asc" ? (
            <ArrowUpward />
          ) : (
            <ArrowDownward />
          ))}
      </HeaderItem>
    </ListViewHeader>
    {funds.map((fund) => (
      <ListViewRow key={fund.id}>
        <FundDetails>
          <MFImageCont
            style={{ height: "4.8rem", width: "4.8rem", flexShrink: 0 }}
          >
            <Image
              src={fund.logo}
              alt={`${fund.wb_name} logo`}
              size={"4.8rem"}
            />
          </MFImageCont>
          <FundInfo>
            <StyledLink to={`/mutual-funds/${fund.scheme_plan_id}`}>
              <FundName>{capitalizeText(fund.wb_name)}</FundName>
            </StyledLink>
            <FundCategory>{fund.scheme_category}</FundCategory>
          </FundInfo>
        </FundDetails>
        <ReturnValue
          highlight={sortConfig.key === "returns_abs_1year"}
          dontShowInMobile={sortConfig.key !== "returns_abs_1year"}
        >
          {fund.returns_abs_1year}%
        </ReturnValue>
        <ReturnValue
          highlight={sortConfig.key === "returns_cmp_3year"}
          dontShowInMobile={sortConfig.key !== "returns_cmp_3year"}
        >
          {fund.returns_cmp_3year}%
        </ReturnValue>
        <ReturnValue
          highlight={sortConfig.key === "returns_cmp_5year"}
          dontShowInMobile={sortConfig.key !== "returns_cmp_5year"}
        >
          {fund.returns_cmp_5year}%
        </ReturnValue>
      </ListViewRow>
    ))}
  </ListViewContainer>
);

const FundListComponent = ({
  funds,
  selectedCategory,
  onChange,
  showCategoryDropdown,
  INDEX,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: "returns_abs_1year",
    direction: "desc",
  });
  const [viewMode, setViewMode] = useState("list");

  const sortedFunds = useMemo(() => {
    return [...funds].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [funds, sortConfig]);

  const requestSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  return (
    <Container>
      <Header>
        <div>
          <h2>{COLLECTIONS[INDEX].label}</h2>
          <Title>{COLLECTIONS[INDEX].description}</Title>
        </div>

        <StyledAlignCenter>
          <DesktopView>
            {viewMode === "grid" && (
              <SortControls>
                <SortButton
                  active={sortConfig.key === "returns_abs_1year"}
                  onClick={() => requestSort("returns_abs_1year")}
                >
                  1Y
                  {sortConfig.key === "returns_abs_1year" &&
                    (sortConfig.direction === "asc" ? (
                      <ArrowUpward />
                    ) : (
                      <ArrowDownward />
                    ))}
                </SortButton>
                <SortButton
                  active={sortConfig.key === "returns_cmp_3year"}
                  onClick={() => requestSort("returns_cmp_3year")}
                >
                  3Y
                  {sortConfig.key === "returns_cmp_3year" &&
                    (sortConfig.direction === "asc" ? (
                      <ArrowUpward />
                    ) : (
                      <ArrowDownward />
                    ))}
                </SortButton>
                <SortButton
                  active={sortConfig.key === "returns_cmp_5year"}
                  onClick={() => requestSort("returns_cmp_5year")}
                >
                  5Y
                  {sortConfig.key === "returns_cmp_5year" &&
                    (sortConfig.direction === "asc" ? (
                      <ArrowUpward />
                    ) : (
                      <ArrowDownward />
                    ))}
                </SortButton>
              </SortControls>
            )}
          </DesktopView>

          <ViewToggleButton
            onClick={() => setViewMode(viewMode === "grid" ? "list" : "grid")}
          >
            {viewMode === "grid" ? <ViewList /> : <ViewModule />}
            <DesktopView>{viewMode === "grid" ? "List" : "Grid"}</DesktopView>
          </ViewToggleButton>
        </StyledAlignCenter>
      </Header>

      <div style={{ justifySelf: "flex-start", width: "300px" }}>
        {showCategoryDropdown ? (
          <Select
            options={TEMP_CATEGORIES.map((curr) => {
              return {
                label: curr,
                value: curr,
              };
            })}
            value={selectedCategory}
            onChange={onChange}
          />
        ) : null}
      </div>

      <MobileView style={{ marginBottom: "1rem" }}>
        <SortControls>
          <SortButton
            active={sortConfig.key === "returns_abs_1year"}
            onClick={() => requestSort("returns_abs_1year")}
          >
            1Y
            {sortConfig.key === "returns_abs_1year" &&
              (sortConfig.direction === "asc" ? (
                <ArrowUpward />
              ) : (
                <ArrowDownward />
              ))}
          </SortButton>
          <SortButton
            active={sortConfig.key === "returns_cmp_3year"}
            onClick={() => requestSort("returns_cmp_3year")}
          >
            3Y
            {sortConfig.key === "returns_cmp_3year" &&
              (sortConfig.direction === "asc" ? (
                <ArrowUpward />
              ) : (
                <ArrowDownward />
              ))}
          </SortButton>
          <SortButton
            active={sortConfig.key === "returns_cmp_5year"}
            onClick={() => requestSort("returns_cmp_5year")}
          >
            5Y
            {sortConfig.key === "returns_cmp_5year" &&
              (sortConfig.direction === "asc" ? (
                <ArrowUpward />
              ) : (
                <ArrowDownward />
              ))}
          </SortButton>
        </SortControls>
      </MobileView>

      {viewMode === "grid" ? (
        <FundGrid>
          {sortedFunds.map((fund) => (
            <FundCard key={fund.id}>
              <FundHeader>
                <MFImageCont style={{ height: "4.8rem", width: "4.8rem" }}>
                  <Image
                    src={fund.logo}
                    alt={`${fund.wb_name} logo`}
                    size={"4.8rem"}
                  />
                </MFImageCont>
                <FundInfo>
                  <StyledLink to={`/mutual-funds/${fund.scheme_plan_id}`}>
                    <FundName>{capitalizeText(fund.wb_name)}</FundName>
                  </StyledLink>

                  <FundCategory>{fund.scheme_category}</FundCategory>
                </FundInfo>
              </FundHeader>
              <ReturnsTable>
                <thead>
                  <ReturnsRow>
                    <ReturnsHeader
                      highlight={sortConfig.key === "returns_abs_1year"}
                    >
                      1Y Returns
                    </ReturnsHeader>
                    <ReturnsHeader
                      highlight={sortConfig.key === "returns_cmp_3year"}
                    >
                      3Y Returns
                    </ReturnsHeader>
                    <ReturnsHeader
                      highlight={sortConfig.key === "returns_cmp_5year"}
                    >
                      5Y Returns
                    </ReturnsHeader>
                  </ReturnsRow>
                </thead>
                <tbody>
                  <ReturnsRow>
                    <ReturnsCell
                      highlight={sortConfig.key === "returns_abs_1year"}
                    >
                      {fund.returns_abs_1year}%
                    </ReturnsCell>
                    <ReturnsCell
                      highlight={sortConfig.key === "returns_cmp_3year"}
                    >
                      {fund.returns_cmp_3year}%
                    </ReturnsCell>
                    <ReturnsCell
                      highlight={sortConfig.key === "returns_cmp_5year"}
                    >
                      {fund.returns_cmp_5year}%
                    </ReturnsCell>
                  </ReturnsRow>
                </tbody>
              </ReturnsTable>
            </FundCard>
          ))}
        </FundGrid>
      ) : (
        <ListView
          funds={sortedFunds}
          sortConfig={sortConfig}
          requestSort={requestSort}
        />
      )}
    </Container>
  );
};
