import React from "react";
import { useLocation } from "react-router-dom";

import MobileLumpsum from "./_MobileLumpsum";
import MobileSIP from "./_MobileSIP";

const MobielInvest = () => {
  const { state } = useLocation();
  console.log(state);

  if (state.investmentType === "sip") {
    return <MobileSIP />;
  } else {
    return <MobileLumpsum />;
  }
};

export default MobielInvest;
