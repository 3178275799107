import { ArrowDropDown } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";

const AccordionComp = ({ title, content }) => {
  return (
    <Accordion
      sx={{
        color: "var(--medium-grey)",
        boxShadow: "none",
        border: "1px solid #ccc",
      }}
      defaultExpanded
    >
      <AccordionSummary expandIcon={<ArrowDropDown />}>
        {title}
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionComp;
