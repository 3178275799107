import styled, { css } from "styled-components";
import { StyledSpaceBetween } from "styles/common-styled-components";
import { centering } from "styles/common-styles";
import { STYLE_VALUES } from "utils/constants";

export const RegistationCapsuleCont = styled.div`
  /* min-width: 100rem; */
  width: 100%;
  height: calc(100vh - 10rem);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    height: auto;
    gap: 2rem;
    box-shadow: none;
  }
`;

export const RegGrey = styled.div`
  background-color: #f5f5f5;
`;

export const RegistrationLeftCont = styled.div`
  /* background-color: #ccc; */
  flex: 1;
  position: relative;
  height: calc(100vh - 10rem);
  overflow: auto;
  padding: 4rem;
  background: linear-gradient(to top, #20b2abc2, rgba(32, 178, 170, 0.2));
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey color for the track */
  }
  &::-webkit-scrollbar-thumb {
    background: #aaa; /* Grey color for the thumb */
    transition: all 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999; /* Darker grey color on hover */
  }

  @media (max-width: 600px) {
    overflow: visible;
    height: auto;
    background: none;
    padding: 2rem;
    display: none;
  }
`;

const nonRegStyles = css`
  ${centering}
`;

export const RegistrationRightCont = styled.div`
  padding: 2rem 4rem;
  padding-top: 6rem;
  height: calc(100vh - 10rem);
  overflow: auto;
  flex: 1;

  ${({ nonReg }) => (nonReg ? nonRegStyles : null)}

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey color for the track */
  }
  &::-webkit-scrollbar-thumb {
    background: #aaa; /* Grey color for the thumb */
    transition: all 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999; /* Darker grey color on hover */
  }

  @media (max-width: 600px) {
    overflow: visible;
    height: auto;
    margin-top: 3rem;
    padding: 4rem 1rem;
  }
`;

export const Content = styled.div`
  margin: 0 4rem;
  margin-bottom: 4rem;
  @media (max-width: 600px) {
    margin: 0;
  }
`;

export const SimpleNavCont = styled(StyledSpaceBetween)`
  padding: 1rem 10rem;
  height: 10rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1000;

  @media (max-width: 600px) {
    height: 6rem;
    margin: 0;
    padding: 1rem;
  }
`;

export const NomineeCont = styled.div`
  padding: 1rem 4rem;
  margin-bottom: 2rem;

  @media (max-width: 600px) {
    padding: 0;
  }
`;
