import React from "react";
import Lottie from "lottie-react";

import paymentSuccessAnimation from "assets/json/payment-succes.json";
import { useLocation } from "react-router-dom";
import {
  StyledCentering,
  StyledLink,
  SubHeading,
} from "styles/common-styled-components";
import Button from "components/common/Button/Button";

const PaymentSuccess = () => {
  const location = useLocation();

  return (
    <div>
      <StyledCentering style={{ width: "100%" }}>
        <Lottie
          animationData={paymentSuccessAnimation}
          loop={false}
          style={{ width: window.innerWidth < 480 ? "100%" : "40rem" }}
        />
      </StyledCentering>

      <SubHeading style={{ textAlign: "center" }}>
        Your Payment of {location.state.amount} is successful{" "}
      </SubHeading>
      <StyledCentering style={{ width: "100%" }}>
        <StyledLink to="/">
          <Button name={"HOME"} style={{ width: "100%" }} />
        </StyledLink>
      </StyledCentering>
    </div>
  );
};

export default PaymentSuccess;
