import Skeleton from "@mui/material/Skeleton";
import styled from "styled-components";
import { TextSkeleton } from "pages/MutualFundsFilter/MutualFundsFilter.skeletons";
import { OrderCard } from "./Orders.styles";

export const StyledSkeleton = styled(Skeleton)`
  background-color: ${({ theme }) => theme.skeletonBg};
`;

export const OrderCardSkeleton = ({ height = 120, width = "100%" }) => {
  return (
    <OrderCard style={{ alignItems: "start" }}>
      <div>
        <TextSkeleton
          width={"80%"}
          style={{
            marginBottom: "1.4rem",
            textAlign: "center",
          }}
        />
        <TextSkeleton
          variant="rounded"
          width={"50%"}
          style={{
            marginBottom: "1.4rem",
            textAlign: "center",
          }}
        />
      </div>
      <TextSkeleton
        width={"50%"}
        style={{
          marginBottom: "1.4rem",
          textAlign: "center",
        }}
      />{" "}
      <TextSkeleton
        width={"90%"}
        style={{
          marginBottom: "1.4rem",
          textAlign: "center",
        }}
      />
      <TextSkeleton
        width={"70%"}
        style={{
          marginBottom: "1.4rem",
          textAlign: "center",
        }}
      />
    </OrderCard>
  );
};

export const MobileOrderCardSkeleton = () => {
  return (
    <div style={{ padding: "2rem" }}>
      <div style={{ display: "flex", gap: "3rem" }}>
        <TextSkeleton
          width={"100%"}
          style={{
            marginBottom: "1rem",
          }}
        />
        <TextSkeleton
          variant="rounded"
          width={"20%"}
          style={{
            marginBottom: "1.4rem",
            textAlign: "center",
          }}
        />
      </div>

      <TextSkeleton
        width={"70%"}
        style={{
          marginBottom: "1.4rem",
        }}
      />
    </div>
  );
};
