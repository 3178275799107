import React from "react";
import { useLocation } from "react-router-dom";
import { Label } from "components/common/InputField/InputField.styles";
import MobileNav from "components/common/MobileNav/MobileNav";
import useDocumentTitle from "hooks/useDocumentTitle";
import RedeemForm from "components/forms/RedeemForm";

const Redeem = () => {
  const { state } = useLocation();
  useDocumentTitle(
    `Redeem ${state.selectedInvestmentDetails.scheme_name} - WiseBulls`
  );
  return (
    <>
      <MobileNav headerTitle={"Redeem"} />
      <Label
        style={{
          marginBottom: "2rem",
        }}
      >
        (Redeeming {state.selectedInvestmentDetails.scheme_name})
      </Label>
      <RedeemForm
        state={state.selectedInvestmentDetails}
        mfData={state.mfData}
      />
    </>
  );
};

export default Redeem;
