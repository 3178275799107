import React, { useEffect } from "react";
import useScrollLock from "hooks/useScrollLock";
import { MainContainer, Overlay, Container } from "./BottomSheet.styles";

const BottomSheet = ({
  show,
  children,
  containerStyles,

  close,
  desktop = false,
  backgroundOpacity,
}) => {
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    if (show) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [show, lockScroll, unlockScroll]);

  return (
    <MainContainer $visibility={show} desktop={desktop}>
      <Overlay
        onClick={close}
        $visibility={show}
        desktop={desktop}
        backgroundOpacity={backgroundOpacity}
      />
      <Container
        style={{ ...containerStyles }}
        $visibility={show}
        show={show}
        desktop={desktop}
      >
        {children}
      </Container>
    </MainContainer>
  );
};

export default BottomSheet;
