import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";

import { AlertContext } from "App";
import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import NomineeForm from "components/forms/NomineeForm";
import { useLocation, useNavigate } from "react-router-dom";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS, GENERIC_SERVER_ERROR } from "utils/constants";
import Button from "components/ui/Button/Button";
import MiniLoader from "components/common/MiniLoader/MiniLoader";
import {
  CloseButton,
  OrSeperator,
  StyledAlignCenter,
  StyledCentering,
} from "styles/common-styled-components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";

const AddNominee = () => {
  const location = useLocation();

  const { userDetails, kycInfo } = useSelector((state) => state.user);

  const fromLocation = location.state?.fromLocation;
  const navigateTo = fromLocation || "/registration/add-address";

  return (
    <>
      <RegistrationCapsule
        heading={`${fromLocation ? "Edit" : "Add"} Nominee`}
        para={`${fromLocation ? "Edit" : "Add"} Nominee for your account`}
        fromLocation={fromLocation}
      >
        {userDetails.nomination_flag && kycInfo ? (
          <div
            style={{
              padding: "0 4rem",
            }}
          >
            <p>You opted out of adding nominee.</p>
            {/* <OrSeperator style={{ textAlign: "center", margin: "2rem 0" }}>
              Or
            </OrSeperator> */}
            {/* <p>You can add a nominee if you choose to opt in.</p> */}
          </div>
        ) : (
          <>
            {userDetails.nomination_flag && kycInfo ? null : (
              <SkipAddingNominee />
            )}

            <NomineeForm fromLocation={fromLocation} navigateTo={navigateTo} />
          </>
        )}
      </RegistrationCapsule>
    </>
  );
};

export default AddNominee;

const SkipAddingNominee = () => {
  const { openModal, closeModal } = useContext(AlertContext);
  const [generalServerError, setGeneralServerError] = useState("");
  const location = useLocation();
  const fromLocation = location.state?.fromLocation;
  const navigateTo = fromLocation || "/registration/add-address";

  const navigate = useNavigate();

  const handleAddNominee = () => {
    return credentialAxios.post(API_ENDPOINTS.addNominee, [
      {
        opt_out: true,
        name: "a",
        relation: "a",
      },
    ]);
  };

  const onSuccess = () => {
    setGeneralServerError("");
    closeModal();
    navigate(navigateTo);
  };

  const onError = () => {
    setGeneralServerError(GENERIC_SERVER_ERROR);
  };

  const { mutate, isLoading } = useMutation(handleAddNominee, {
    onSuccess,
    onError,
  });

  return (
    <div style={{ padding: "0 4rem", marginBottom: "1rem" }}>
      <Button
        title="I want to opt out adding Nominee"
        variant="outlined"
        onClick={() => {
          openModal({
            comp: (
              <div
                style={{
                  padding: "2rem",
                }}
              >
                {isLoading ? (
                  <StyledCentering>
                    <MiniLoader contStyle={{ borderBottom: 0 }} />
                  </StyledCentering>
                ) : (
                  <>
                    <div style={{ marginBottom: "1rem" }}>
                      <CloseButton
                        onClick={() => {
                          closeModal();
                          setGeneralServerError("");
                        }}
                      />
                    </div>

                    {generalServerError ? (
                      <div>
                        <ErrorMessage
                          show={generalServerError}
                          message={generalServerError}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          padding: "1.2rem",
                          borderRadius: 4,
                        }}
                      >
                        <p style={{ textAlign: "center", fontSize: "1.6rem" }}>
                          Are you sure you want to opt out adding Nominee
                        </p>

                        <StyledAlignCenter>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              gap: "1rem",
                              margin: "1rem 0",
                            }}
                          >
                            <Button
                              title="YES"
                              variant="outlined"
                              onClick={mutate}
                            />
                            <Button
                              title="NO"
                              onClick={() => {
                                closeModal();
                              }}
                            />
                          </div>
                        </StyledAlignCenter>
                      </div>
                    )}
                  </>
                )}
              </div>
            ),
            style: {
              minWidth: "200px",
            },
          });
        }}
      />
    </div>
  );
};
