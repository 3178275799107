import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";

import {
  ArrowUpward,
  ArrowDownward,
  ViewModule,
  ViewList,
} from "@mui/icons-material";

import DataRenderer from "components/utility/DataRenderer";
import { API_ENDPOINTS } from "utils/constants";
import Image from "components/ui/Image/Image";
import { MFImageCont } from "pages/MutualFundsFilter/MutualFundsFilter.styles";
import { StyledAlignCenter } from "styles/common-styled-components";
import { capitalizeText } from "utils/functions";

const Collections = () => {
  return <Outlet />;
};

export default Collections;

const Container = styled.div``;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.p`
  font-size: 1.4rem;
`;

const SortControls = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SortButton = styled.button`
  background-color: ${(props) =>
    props.active ? "var(--themeColor)" : "#f0f0f0"};
  color: ${(props) => (props.active ? "white" : "#333")};
  border: none;
  padding: 1rem 1.6rem;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    background-color: ${(props) =>
      props.active ? "var(--themeColorShade5)" : "#e0e0e0"};
  }
`;

const ViewToggleButton = styled.button`
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 1rem;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const FundGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const FundCard = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;

const FundHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 15px;
  background-color: #f8f9fa;
`;

const FundInfo = styled.div``;

const FundName = styled.p`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  &:hover {
    color: var(--themeColor);
    cursor: pointer;
  }
`;

const FundCategory = styled.p`
  margin: 5px 0 0;
  font-size: 1.2rem;
  color: #868e96;
`;

const ReturnsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const ReturnsRow = styled.tr`
  &:nth-child(even) {
    background-color: #f8f9fa;
  }
`;

const ReturnsCell = styled.td`
  padding: 10px 15px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: ${({ highlight }) =>
    highlight ? "var(--themeColor)" : "var(--themeBluishGrey)"};
`;

const ReturnsHeader = styled.th`
  padding: 1rem 1.5rem;
  border-bottom: var(--secondary-light-grey);
  font-weight: 300;
  color: ${({ highlight }) =>
    highlight ? "var(--themeColor)" : "var(--themeBluishGrey)"};
`;

const ListViewContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
`;

const ListViewHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  padding: 16px;
  background-color: #f8f9fa;
  font-weight: bold;
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align || "left"};
  cursor: pointer;
  color: ${(props) => (props.active ? "var(--themeColor)" : "inherit")};

  &:hover {
    color: var(--themeColor);
  }
`;

const ListViewRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`;

const FundDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ReturnValue = styled.p`
  margin: 0;
  text-align: right;
  font-weight: ${({ highlight }) => (highlight ? "bold" : "normal")};
  color: ${({ highlight }) => (highlight ? "var(--themeColor)" : "inherit")};
`;

const ListView = ({ funds, sortConfig, requestSort }) => (
  <ListViewContainer>
    <ListViewHeader>
      <HeaderItem>Fund name</HeaderItem>
      <HeaderItem
        align="right"
        active={sortConfig.key === "returns_abs_1year"}
        onClick={() => requestSort("returns_abs_1year")}
      >
        1Y
        {sortConfig.key === "returns_abs_1year" &&
          (sortConfig.direction === "asc" ? (
            <ArrowUpward />
          ) : (
            <ArrowDownward />
          ))}
      </HeaderItem>
      <HeaderItem
        align="right"
        active={sortConfig.key === "returns_cmp_3year"}
        onClick={() => requestSort("returns_cmp_3year")}
      >
        3Y
        {sortConfig.key === "returns_cmp_3year" &&
          (sortConfig.direction === "asc" ? (
            <ArrowUpward />
          ) : (
            <ArrowDownward />
          ))}
      </HeaderItem>
      <HeaderItem
        align="right"
        active={sortConfig.key === "returns_cmp_5year"}
        onClick={() => requestSort("returns_cmp_5year")}
      >
        5Y
        {sortConfig.key === "returns_cmp_5year" &&
          (sortConfig.direction === "asc" ? (
            <ArrowUpward />
          ) : (
            <ArrowDownward />
          ))}
      </HeaderItem>
    </ListViewHeader>
    {funds.map((fund) => (
      <ListViewRow key={fund.id}>
        <FundDetails>
          <MFImageCont style={{ height: "4.8rem", width: "4.8rem" }}>
            <Image
              src={fund.logo}
              alt={`${fund.wb_name} logo`}
              size={"4.8rem"}
            />
          </MFImageCont>
          <FundInfo>
            <FundName>{fund.wb_name}</FundName>
            <FundCategory>{fund.scheme_category}</FundCategory>
          </FundInfo>
        </FundDetails>
        <ReturnValue highlight={sortConfig.key === "returns_abs_1year"}>
          {fund.returns_abs_1year}%
        </ReturnValue>
        <ReturnValue highlight={sortConfig.key === "returns_cmp_3year"}>
          {fund.returns_cmp_3year}%
        </ReturnValue>
        <ReturnValue highlight={sortConfig.key === "returns_cmp_5year"}>
          {fund.returns_cmp_5year}%
        </ReturnValue>
      </ListViewRow>
    ))}
  </ListViewContainer>
);

const FundListComponent = ({ funds }) => {
  const [sortConfig, setSortConfig] = useState({
    key: "returns_abs_1year",
    direction: "desc",
  });
  const [viewMode, setViewMode] = useState("list");

  const sortedFunds = useMemo(() => {
    return [...funds].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [funds, sortConfig]);

  const requestSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  return (
    <Container>
      <Header>
        <Title>Best funds that invest in Large Cap companies</Title>
        <StyledAlignCenter>
          {viewMode === "grid" && (
            <SortControls>
              <SortButton
                active={sortConfig.key === "returns_abs_1year"}
                onClick={() => requestSort("returns_abs_1year")}
              >
                1Y
                {sortConfig.key === "returns_abs_1year" &&
                  (sortConfig.direction === "asc" ? (
                    <ArrowUpward />
                  ) : (
                    <ArrowDownward />
                  ))}
              </SortButton>
              <SortButton
                active={sortConfig.key === "returns_cmp_3year"}
                onClick={() => requestSort("returns_cmp_3year")}
              >
                3Y
                {sortConfig.key === "returns_cmp_3year" &&
                  (sortConfig.direction === "asc" ? (
                    <ArrowUpward />
                  ) : (
                    <ArrowDownward />
                  ))}
              </SortButton>
              <SortButton
                active={sortConfig.key === "returns_cmp_5year"}
                onClick={() => requestSort("returns_cmp_5year")}
              >
                5Y
                {sortConfig.key === "returns_cmp_5year" &&
                  (sortConfig.direction === "asc" ? (
                    <ArrowUpward />
                  ) : (
                    <ArrowDownward />
                  ))}
              </SortButton>
            </SortControls>
          )}
          <ViewToggleButton
            onClick={() => setViewMode(viewMode === "grid" ? "list" : "grid")}
          >
            {viewMode === "grid" ? <ViewList /> : <ViewModule />}
            {viewMode === "grid" ? "List View" : "Grid View"}
          </ViewToggleButton>
        </StyledAlignCenter>
      </Header>
      {viewMode === "grid" ? (
        <FundGrid>
          {sortedFunds.map((fund) => (
            <FundCard key={fund.id}>
              <FundHeader>
                <MFImageCont style={{ height: "4.8rem", width: "4.8rem" }}>
                  <Image
                    src={fund.logo}
                    alt={`${fund.wb_name} logo`}
                    size={"4.8rem"}
                  />
                </MFImageCont>
                <FundInfo>
                  <FundName>{capitalizeText(fund.wb_name)}</FundName>
                  <FundCategory>{fund.scheme_category}</FundCategory>
                </FundInfo>
              </FundHeader>
              <ReturnsTable>
                <thead>
                  <ReturnsRow>
                    <ReturnsHeader
                      highlight={sortConfig.key === "returns_abs_1year"}
                    >
                      1Y Returns
                    </ReturnsHeader>
                    <ReturnsHeader
                      highlight={sortConfig.key === "returns_cmp_3year"}
                    >
                      3Y Returns
                    </ReturnsHeader>
                    <ReturnsHeader
                      highlight={sortConfig.key === "returns_cmp_5year"}
                    >
                      5Y Returns
                    </ReturnsHeader>
                  </ReturnsRow>
                </thead>
                <tbody>
                  <ReturnsRow>
                    <ReturnsCell
                      highlight={sortConfig.key === "returns_abs_1year"}
                    >
                      {fund.returns_abs_1year}%
                    </ReturnsCell>
                    <ReturnsCell
                      highlight={sortConfig.key === "returns_cmp_3year"}
                    >
                      {fund.returns_cmp_3year}%
                    </ReturnsCell>
                    <ReturnsCell
                      highlight={sortConfig.key === "returns_cmp_5year"}
                    >
                      {fund.returns_cmp_5year}%
                    </ReturnsCell>
                  </ReturnsRow>
                </tbody>
              </ReturnsTable>
            </FundCard>
          ))}
        </FundGrid>
      ) : (
        <ListView
          funds={sortedFunds}
          sortConfig={sortConfig}
          requestSort={requestSort}
        />
      )}
    </Container>
  );
};
