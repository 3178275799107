import React from "react";

import helpSvg from "assets/banner/Questions-cuate.svg";
import {
  BigHeading,
  HeadingPrimaryBold,
  SubHeading,
} from "styles/common-styled-components";
import MobileNav from "components/common/MobileNav/MobileNav";
import { Container, NumberLink } from "./Help.styles";
import useDocumentTitle from "hooks/useDocumentTitle";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Help = () => {
  useDocumentTitle("Help - WiseBulls");
  return (
    <Container>
      <MobileNav headerTitle={"Help"} />
      <LazyLoadImage
        style={{ flex: 1 }}
        src={helpSvg}
        alt="customer confused about mutual funds"
      />
      <div style={{ flex: 1, textAlign: "center" }}>
        <BigHeading style={{ fontWeight: "900" }}>Got a question?</BigHeading>
        <HeadingPrimaryBold>Contact us</HeadingPrimaryBold>
        <div style={{ margin: "2rem 0" }}>
          <NumberLink href="tel:8762234697">8762234697</NumberLink>
          <NumberLink href="tel:9980018625">9980018625</NumberLink>
          <NumberLink
            style={{ fontSize: "2rem" }}
            href="mailto:info@wisebulls.com"
          >
            info@wisebulls.com
          </NumberLink>
        </div>
        <SubHeading>
          Our relationship and client managers are available from 8AM TO 8PM to
          help you
        </SubHeading>
      </div>
    </Container>
  );
};

export default Help;
