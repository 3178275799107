import React, { useState } from "react";

import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import RadioComp from "components/ui/RadioComp/RadioComp";

import { StyledAlignCenter } from "styles/common-styled-components";
import UploadSignature from "components/forms/UploadSignature";
import SignSignature from "components/forms/SignSignature";
import { useSelector } from "react-redux";
import Image from "components/ui/Image/Image";
import { useLocation } from "react-router-dom";
import { NomineeCont } from "components/ui/RegistrationCapsule/RegistrationCapsule.styles";

const AddSignature = () => {
  const { kycInfo: localKycInfo } = useSelector((state) => state.user);

  const location = useLocation();

  const fromLocation = location?.state?.fromLocation;
  const navigateTo = fromLocation || "/registration/confirm-details";

  const currentSign = localKycInfo?.signature;

  const [selectFormat, setSelectFormat] = useState("upload");

  return (
    <>
      <RegistrationCapsule
        heading={`${fromLocation ? "Edit" : "Add"} your signature`}
        para={"Upload image of your signature or sign your signature"}
        fromLocation={fromLocation}
      >
        <NomineeCont>
          {currentSign ? (
            <>
              <p>Your current Signature</p>
              <Image
                alt={"_sign"}
                src={`data:image;base64,${currentSign}`}
                width="100%"
                style={{
                  maxWidth: "40rem",
                  maxHeight: "30rem",
                  border: "1px solid #ccc",
                }}
              />
            </>
          ) : null}
          <RadioComp
            selected={selectFormat === "upload"}
            label={"Upload your signature"}
            onChange={() => setSelectFormat("upload")}
          >
            <UploadSignature
              fromLocation={fromLocation}
              navigateTo={navigateTo}
            />
          </RadioComp>
          <StyledAlignCenter
            style={{
              gap: "2rem",
              margin: "2rem 0",
              marginLeft: "calc(14px +  4rem)",
            }}
          >
            <div
              style={{
                height: "1px",
                backgroundColor: "#ddd",
                width: "100%",
              }}
            />
            <div>or</div>
            <div
              style={{
                height: "1px",
                backgroundColor: "#ddd",
                width: "100%",
              }}
            />
          </StyledAlignCenter>
          <RadioComp
            selected={selectFormat === "sign"}
            label={"Sign your signature"}
            onChange={() => setSelectFormat("sign")}
          >
            <SignSignature
              fromLocation={fromLocation}
              navigateTo={navigateTo}
            />
          </RadioComp>
        </NomineeCont>
      </RegistrationCapsule>
    </>
  );
};

export default AddSignature;
