import React, { useState, useContext, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { COMP_DIMENSIONS } from "utils/constants";

import notFoundImg from "assets/banner/404-Error-bro.svg";

import { publicAxios } from "utils/api-calls";
import { ANIMATION_STYLES, API_ENDPOINTS } from "utils/constants";
import {
  DesktopView,
  MobileView,
  StyledAlignCenter,
  StyledCentering,
  StyledSpaceBetweenAlignCenter,
} from "styles/common-styled-components";
import {
  GraphDataContainer,
  MainContainer,
  GraphButtonsCont,
  GraphButton,
  LeftCont,
  RightCont,
  NameAndShare,
  ShareIconCont,
  Categories,
  Category,
  MutualFundNavBar,
  MutualFundNavBarMenu,
  ActiveLink,
} from "./SingleMutualFund.styles";
import LineChartComp from "components/graph/LineChartComp/LineChartComp";
import InvestOptions from "components/common/InvestOptions/InvestOptions";
import { AlertContext } from "App";
import { motion, AnimatePresence } from "framer-motion";
import MobileNav from "components/common/MobileNav/MobileNav";
import NoData from "pages/NoData/NoData";
import {
  LeftContSkeleton,
  RightContSkeleton,
} from "./SingleMutualFund.skeletons";
import { useEffect } from "react";
import {
  calculateCAGR,
  calculateOneDayAbsoluteReturn,
  capitalizeText,
  dMYtoDateString,
} from "utils/functions";
import MiniLoader, { Container } from "components/common/MiniLoader/MiniLoader";
import { useLayoutEffect } from "react";
import { MFImageCont } from "pages/MutualFundsFilter/MutualFundsFilter.styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MobileInvestButtons from "./_MobileInvestButtons";
import ShareComp from "./_ShareComp";
import { Reply } from "@mui/icons-material";
import SchemeDetails from "./SchemeDetails";
import { useNavBar } from "components/global/NavBar/NavBarContext";
import ScrollToTop from "components/ui/ScrollToTop/ScrollToTop";
import DataRenderer from "components/utility/DataRenderer";

const graphButtons = [
  { label: "1M", value: 1 },
  { label: "6M", value: 6 },
  { label: "1Y", value: 12 },
  { label: "3Y", value: 36 },
  { label: "5Y", value: 60 },
  { label: "ALL", value: "all" },
];

const LABELS_FOR_ANNUAL = {
  1: "1M",
  6: "6M",
  12: "1Y",
  36: "3Y",
  60: "5Y",
  all: "Annualised",
};

const SingleMutualFund = () => {
  const { mfId } = useParams();

  const { hideNavBar, showNavBar } = useNavBar();

  const { state: editStateFromPaymentMethod } = useLocation();

  const [selectedTimePeriod, setSelectedTimePeriod] = useState("all");

  const [noMutualFundFound, setNoMutualFundFound] = useState(false);

  const [showMutualFundNavBar, setShowMutualFundNavBar] = useState(false);

  const [activeSection, setActiveSection] = useState(null);

  const { openModal, closeModal } = useContext(AlertContext);

  const [categoryData, setCategoryData] = useState({
    asset_class: "",
    scheme_category: "",
    riskometer_value: "",
  });

  const investOptionsRef = useRef();
  const categoriesRef = useRef();

  // API CALLS

  const getMutualFundDetails = () => {
    return publicAxios.get(API_ENDPOINTS.getMFDetails(mfId));
  };

  const getGraphData = (scheme_code) => {
    return publicAxios({
      method: "get",
      url: API_ENDPOINTS.getGraphData,
      params: {
        scheme_code,
      },
    });
  };

  // SERVER STATE

  const onMfDataError = () => {
    setNoMutualFundFound(true);
  };

  const mutualFundData = useQuery(
    ["mutualFundData", mfId],
    getMutualFundDetails,
    {
      onError: onMfDataError,
    }
  );

  const schemeCode = mutualFundData?.data?.data?.scheme_code;

  const graphData = useQuery(
    ["graphData", schemeCode],
    () => getGraphData(schemeCode),
    {
      enabled: !!schemeCode,
    }
  );

  const scrollToSection = (section) => {
    let element;
    element = document.getElementById(section);

    const yOffset = -120; // adjust for the desired offset
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    if (mutualFundData?.data?.data.name) {
      document.title = `${capitalizeText(
        mutualFundData.data.data.name
      )} | WiseBulls`;
    }
  }, [mutualFundData]);

  const sections = [
    { id: "overview", label: "Overview" },
    { id: "holdingAnalysis", label: "Holding Analysis" },
    { id: "peerComparision", label: "Peer Comparisons" },
    { id: "otherStatistics", label: "Other Statistics" },
    { id: "fundManagement", label: "Fund Management" },
  ];

  useLayoutEffect(() => {
    if (editStateFromPaymentMethod && investOptionsRef.current) {
      investOptionsRef.current.scrollIntoView({ behavior: "smooth" });
    } else window.scrollTo(0, 0);
  }, [investOptionsRef.current]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;

      for (let i = sections.length - 1; i >= 0; i--) {
        const section = sections[i];
        const element = document.getElementById(section.id);
        if (element) {
          const offsetTop = element.offsetTop - 121; // adjust for the offset and height of navbar
          if (scrollPosition >= offsetTop) {
            setActiveSection(section.id);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sections]);

  useEffect(() => {
    const element = document.getElementById("overview");
    const handleScroll = () => {
      if (element) {
        const offsetTop = element.offsetTop;
        const scrollPosition = window.pageYOffset;
        setShowMutualFundNavBar(scrollPosition >= offsetTop);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const getFilteredGraphButtons = (graphData) => {
    if (!graphData || !graphData.data || !graphData.data.length) return [];
    const dayString = dMYtoDateString(graphData.data[0].date);
    const initialYear = new Date(dayString).getFullYear();
    const currentYear = new Date().getFullYear();
    const yearsDifference = currentYear - initialYear;
    console.log(initialYear);
    if (yearsDifference < 1) {
      return graphButtons.filter((button) => button.value <= 12);
    } else if (yearsDifference < 3) {
      return graphButtons.filter((button) => button.value <= 36);
    } else if (yearsDifference < 5) {
      return graphButtons.filter((button) => button.value <= 60);
    } else {
      return graphButtons;
    }
  };

  return noMutualFundFound ? (
    <>
      <NoData
        message={"Lost your way?"}
        subMessage={"No Mutual fund found with that id."}
        imageSrc={notFoundImg}
      />
    </>
  ) : mutualFundData.isLoading || graphData.isLoading ? (
    <MainContainer>
      <MobileNav heightBelow={"0"} />
      <LeftCont>
        <LeftContSkeleton />
      </LeftCont>
      <RightCont>
        <RightContSkeleton />
      </RightCont>
    </MainContainer>
  ) : (
    <AnimatePresence>
      <MainContainer
        key="child1"
        as={motion.div}
        initial={ANIMATION_STYLES.opacity.initial}
        animate={ANIMATION_STYLES.opacity.animate}
        exit={ANIMATION_STYLES.opacity.exit}
      >
        {showMutualFundNavBar ? (
          <MutualFundNavBar
            key="child2"
            as={motion.div}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            <NameAndShare>
              <StyledSpaceBetweenAlignCenter style={{ width: "100%" }}>
                <StyledAlignCenter>
                  <MFImageCont style={{ height: "4.8rem", width: "4.8rem" }}>
                    <LazyLoadImage
                      src={mutualFundData.data.data.logo_url}
                      alt={`${mutualFundData.data.data.name} LOGO`}
                    />
                  </MFImageCont>
                  <DesktopView>
                    <h1
                      style={{
                        marginBottom: 0,
                        fontSize: "1.6rem",
                        fontWeight: "600",
                      }}
                    >
                      {mutualFundData.data.data.name}
                    </h1>
                  </DesktopView>
                </StyledAlignCenter>

                <ShareIconCont
                  onClick={() =>
                    openModal({
                      comp: (
                        <ShareComp
                          name={mutualFundData.data.data.name}
                          closeModal={closeModal}
                          title="Share this comparision link"
                        />
                      ),
                      animationStyle: "slideFromBottom",
                      style: { minWidth: "40rem" },
                    })
                  }
                >
                  <Reply
                    style={{
                      transform: "rotateY(180deg)",
                      fontSize: "2.4rem",
                      marginRight: "6px",
                    }}
                  />
                  <span style={{}}>Share</span>
                </ShareIconCont>
              </StyledSpaceBetweenAlignCenter>
              <MobileView>
                <h1
                  style={{
                    marginBottom: 0,
                    fontSize: "1.6rem",
                    fontWeight: "600",
                  }}
                >
                  {mutualFundData.data.data.name}
                </h1>
              </MobileView>
            </NameAndShare>
            <MutualFundNavBarMenu style={{ gap: "3.4rem" }}>
              <ActiveLink
                onClick={() => scrollToSection("overview")}
                active={activeSection === "overview" ? "active" : ""}
              >
                Overview
              </ActiveLink>
              {/* <ActiveLink
              onClick={() => scrollToSection("returnCalculator")}
              active={
                activeSection === "returnCalculator" ? "active" : ""
              }
            >
              Return Calculator
            </ActiveLink> */}
              <ActiveLink
                onClick={() => scrollToSection("holdingAnalysis")}
                active={activeSection === "holdingAnalysis" ? "active" : ""}
              >
                Asset Holdings
              </ActiveLink>
              <ActiveLink
                onClick={() => scrollToSection("peerComparision")}
                active={activeSection === "peerComparision" ? "active" : ""}
              >
                Peer Comparision
              </ActiveLink>
              <ActiveLink
                onClick={() => scrollToSection("otherStatistics")}
                active={activeSection === "otherStatistics" ? "active" : ""}
              >
                Other Statistics
              </ActiveLink>
              <ActiveLink
                onClick={() => scrollToSection("fundManagement")}
                active={activeSection === "fundManagement" ? "active" : ""}
              >
                Fund Management
              </ActiveLink>
            </MutualFundNavBarMenu>
          </MutualFundNavBar>
        ) : null}

        {/* MOBILE */}
        <MobileNav heightBelow={"0"} />
        <LeftCont>
          <>
            <NameAndShare id="overview">
              <StyledSpaceBetweenAlignCenter style={{ width: "100%" }}>
                <StyledAlignCenter>
                  <MFImageCont style={{ height: "4.8rem", width: "4.8rem" }}>
                    <LazyLoadImage
                      src={mutualFundData.data.data.logo_url}
                      alt={`${mutualFundData.data.data.name} LOGO`}
                    />
                  </MFImageCont>
                  <DesktopView>
                    <h1
                      style={{
                        marginBottom: 0,
                        fontSize: "1.6rem",
                        fontWeight: "600",
                      }}
                    >
                      {mutualFundData.data.data.name}
                    </h1>
                  </DesktopView>
                </StyledAlignCenter>

                <ShareIconCont
                  onClick={() =>
                    openModal({
                      comp: (
                        <ShareComp
                          name={mutualFundData.data.data.name}
                          closeModal={closeModal}
                        />
                      ),
                      animationStyle: "slideFromBottom",
                      style: { minWidth: "40rem" },
                    })
                  }
                >
                  <Reply
                    style={{
                      transform: "rotateY(180deg)",
                      fontSize: "2.4rem",
                      marginRight: "6px",
                    }}
                  />
                  <span style={{}}>Share</span>
                </ShareIconCont>
              </StyledSpaceBetweenAlignCenter>
              <MobileView>
                <h1
                  style={{
                    marginBottom: 0,
                    fontSize: "1.6rem",
                    fontWeight: "600",
                  }}
                >
                  {mutualFundData.data.data.name}
                </h1>
              </MobileView>
            </NameAndShare>

            <Categories ref={categoriesRef}>
              <Category>{categoryData?.asset_class}</Category>
              <Category>{categoryData?.scheme_category}</Category>
              <Category>{categoryData?.riskometer_value}</Category>
            </Categories>
            <StyledSpaceBetweenAlignCenter
              style={{
                marginTop: "2rem",
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: "2rem",
                    fontWeight: "800",
                    color: !mutualFundData.data.data.scheme_code
                      ? ""
                      : calculateCAGR(graphData.data.data, selectedTimePeriod) <
                        0
                      ? "var(--errorTextColor)"
                      : "var(--themeColor)",
                  }}
                >
                  <StyledAlignCenter>
                    <p>
                      {!mutualFundData.data.data.scheme_code
                        ? ""
                        : calculateCAGR(
                            graphData.data.data,
                            selectedTimePeriod
                          )}
                      %
                    </p>
                  </StyledAlignCenter>
                </p>
                <p style={{ fontSize: "1.4rem", color: "#ccc" }}>
                  {LABELS_FOR_ANNUAL[selectedTimePeriod]} Return
                </p>
              </div>
              <div style={{ fontSize: "1.4rem" }}>
                <p
                  style={{
                    fontSize: "2rem",
                    fontWeight: "800",
                    color: !mutualFundData.data.data.scheme_code
                      ? ""
                      : calculateOneDayAbsoluteReturn(graphData?.data?.data) < 0
                      ? "var(--errorTextColor)"
                      : "var(--themeColor)",
                  }}
                >
                  {!mutualFundData.data.data.scheme_code
                    ? ""
                    : calculateOneDayAbsoluteReturn(graphData.data.data)}
                  %
                </p>
                <p style={{ fontSize: "1.4rem", color: "#ccc" }}>Day change</p>
              </div>
            </StyledSpaceBetweenAlignCenter>
            <div>
              <GraphDataContainer>
                {graphData.isLoading ? (
                  <MiniLoader height={`${COMP_DIMENSIONS.HEIGHTS.graph}px`} />
                ) : !mutualFundData.data.data.scheme_code ? (
                  <Container>
                    <p>Something went wrong in fetching graph Data</p>
                  </Container>
                ) : (
                  <LineChartComp
                    data={graphData.data.data}
                    fromMonthsBefore={selectedTimePeriod}
                    graphColor={
                      !mutualFundData.data.data.scheme_code
                        ? ""
                        : calculateCAGR(
                            graphData.data.data,
                            selectedTimePeriod
                          ) < 0
                        ? "var(--errorTextColor)"
                        : "var(--themeColor)"
                    }
                  />
                )}
                {graphData.isLoading ? null : (
                  <StyledCentering>
                    <GraphButtonsCont>
                      {getFilteredGraphButtons(graphData.data).map(
                        (curr, index) => {
                          return (
                            <GraphButton
                              key={index}
                              selectedStyle={
                                curr.value === selectedTimePeriod ? true : false
                              }
                              onClick={() => setSelectedTimePeriod(curr.value)}
                            >
                              {curr.label}
                            </GraphButton>
                          );
                        }
                      )}
                    </GraphButtonsCont>
                  </StyledCentering>
                )}
              </GraphDataContainer>
              <DataRenderer
                apiEndpoint={API_ENDPOINTS.schemeDetails(mfId)}
                queryName={`scheme-details-${mfId}`}
                onSuccess={(data) => {
                  const details = data?.data;
                  setCategoryData({
                    asset_class: details.asset_class,
                    scheme_category: details.scheme_category,
                    riskometer_value: details.riskometer_value,
                  });
                }}
                renderData={(schemeDetails) => {
                  return (
                    <SchemeDetails
                      schemeDetails={schemeDetails}
                      nav_latest={mutualFundData.data.data.nav_latest}
                      nav_latest_date={mutualFundData.data.data.nav_latest_date}
                      day_change={
                        !mutualFundData.data.data.scheme_code
                          ? ""
                          : calculateOneDayAbsoluteReturn(graphData.data.data)
                      }
                      min_sip={
                        mutualFundData.data.data.sip_schemes.length === 0
                          ? null
                          : mutualFundData.data.data.sip_schemes.filter(
                              (curr) => curr.sip_frequency === "MONTHLY"
                            )[0].min_sip_inst
                      }
                      min_lumpsum={mutualFundData.data.data.min_inv}
                    />
                  );
                }}
              />
            </div>
          </>
        </LeftCont>

        <RightCont ref={investOptionsRef}>
          <InvestOptions
            editState={editStateFromPaymentMethod}
            data={mutualFundData.data.data}
            noSip={
              mutualFundData.data.data.sip_schemes.length === 0 ? true : false
            }
            fromLocation={`/mutual-funds/${mfId}`}
          />
        </RightCont>
        {mutualFundData.isLoading ? null : (
          <MobileView>
            <MobileInvestButtons
              data={mutualFundData.data.data}
              noSip={
                mutualFundData.data.data.sip_schemes.length === 0 ? true : false
              }
            />
          </MobileView>
        )}
      </MainContainer>
      <ScrollToTop />
    </AnimatePresence>
  );
};

export default SingleMutualFund;
