import styled, { css } from "styled-components/macro";
import { Centering } from "styles/common-styles";

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.cardBackground2};
  bottom: 2rem;
  border-radius: 4px;
  ${Centering}
`;
export const PaginationCont = styled.div`
  padding: 6px;
  display: flex;
`;

const ActivePage = css`
  background-color: var(--themeColor);
  color: #fff;
`;

const InActivePage = css`
  background-color: transparent;
`;

export const PageNumber = styled.div`
  padding: 1rem 1.4rem;
  border-radius: 4px;
  ${({ active }) => (active ? ActivePage : InActivePage)}

  cursor: pointer;
`;

export const ChevronCont = styled.div`
  height: 4rem;
  width: 4rem;
  background-color: ${({ theme }) => theme.tagBackground};
  cursor: ${({ disable }) => (disable ? "not-allowed" : "pointer")};
  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};
  ${Centering}
  margin:0 1rem;
`;
