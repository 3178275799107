import styled from "styled-components/macro";
import { Centering } from "styles/common-styles";
import { COMP_DIMENSIONS } from "utils/constants";

export const GraphCont = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  padding-bottom: 2rem;
`;

export const TooltipContainer = styled.div`
  padding: 1.2rem;
  border: 1px solid ${({ theme }) => theme.borderColor};
  background-color: ${({ theme }) => theme.background};
`;

export const DateLabel = styled.h4`
  margin-bottom: 6px;
`;

export const NoGraphDataCont = styled.div`
  height: ${`${COMP_DIMENSIONS.HEIGHTS.graph}px`};
  ${Centering}
`;
export const Value = styled.p`
  color: var(--themeColor);
  font-weight: bolder;
`;
