import React, { useContext, useState } from "react";
import OtpInput from "react-otp-input";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import Timer from "components/ui/Timer/Timer";
import Button from "components/ui/Button/Button";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS, GENERIC_ERROR_RES_MSG } from "utils/constants";
import { mapServerErrorsToLocal } from "utils/functions";

import {
  CloseButton,
  ErrorMessage,
  Form2,
  StyledAlignCenter,
  UnderLineButton,
} from "styles/common-styled-components";
import { AlertContext } from "App";

const ConfirmKycOtp = () => {
  const { openModal, closeModal } = useContext(AlertContext);

  const LogoutComp = ({ onClose, onNo, onYes }) => {
    return (
      <div style={{ padding: "2rem", marginTop: "3rem" }}>
        <div style={{ width: "100%", textAlign: "right" }}>
          <CloseButton onClick={onClose} />
        </div>
        <h3 style={{ marginBottom: "2rem" }}>
          You will be redirected for KYC verification.
        </h3>
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "1rem",
            margin: "1rem 0",
          }}
        >
          <Button title={"Proceed"} variant="outlined" onClick={onYes} />
          <Button title="No" onClick={onNo} />
        </div>
      </div>
    );
  };
  const initialErrorState = { otp: "", general: "" };
  const resendInitialErrorState = { general: "" };

  const NUM_INPUTS = 6;

  const navigate = useNavigate();

  const [verifyOtpErrors, setVerifyOtpErrors] = useState(initialErrorState);
  const [resendOtpError, setSetResendOtpErrors] = useState(
    resendInitialErrorState
  );
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [otpValue, setOtpValue] = useState(null);

  const verifyOtp = (otp) => {
    return credentialAxios.post(API_ENDPOINTS.confirmKycData, {
      otp_required: false,
      otp,
      confirm: true,
    });
  };

  const onVerifyOtpSuccess = (res) => {
    setVerifyOtpErrors(initialErrorState);
    if (res.data.kyc_verified) {
      navigate("/registration-successful");
    } else if (res.data.autoLogin_url) {
      openModal({
        comp: (
          <LogoutComp
            onClose={closeModal}
            onNo={closeModal}
            onYes={() => {
              window.location.replace(res.data.autoLogin_url);
            }}
          />
        ),
        style: { minWidth: "12rem" },
      });
    } else {
      setVerifyOtpErrors((prev) => {
        return { ...prev, general: GENERIC_ERROR_RES_MSG };
      });
    }
  };

  const onVerifyOtpError = (error) => {
    const newErrors = mapServerErrorsToLocal(
      error,
      initialErrorState,
      ["otp"],
      { error: "otp" }
    );
    setVerifyOtpErrors(newErrors);
  };

  const verifyRes = useMutation(verifyOtp, {
    onError: onVerifyOtpError,
    onSuccess: onVerifyOtpSuccess,
  });

  const resendOtp = () => {
    return credentialAxios.post(API_ENDPOINTS.confirmKycData, {
      otp_required: true,
      otp: "",
      confirm: true,
    });
  };

  const onResendOtpSuccess = (res) => {};

  const onResendOtpError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, resendInitialErrorState, [
      "phone_number",
    ]);
    setSetResendOtpErrors(newErrors);
  };

  const resendRes = useMutation(resendOtp, {
    onSuccess: onResendOtpSuccess,
    onError: onResendOtpError,
  });

  const handleValidateNoOtp = (e) => {
    e.preventDefault();
    if (!otpValue) {
      setVerifyOtpErrors((prev) => {
        return {
          ...prev,
          otp: "OTP is required",
        };
      });
      return;
    } else if (otpValue.length < 6) {
      setVerifyOtpErrors((prev) => {
        return {
          ...prev,
          otp: "Enter all digits",
        };
      });
      return;
    }
    verifyRes.mutate(otpValue);
  };

  return (
    <>
      <RegistrationCapsule
        heading={"OTP Verification"}
        para={
          "OTP has been sent to your mobile phone. Please enter it below. OTP is valid for 3 minutes"
        }
      >
        <Form2 onSubmit={handleValidateNoOtp}>
          <OtpInput
            shouldAutoFocus={true}
            value={otpValue}
            onChange={(otp) => {
              setVerifyOtpErrors((prev) => {
                return { ...prev, otp: "" };
              });
              setOtpValue(otp);
            }}
            numInputs={NUM_INPUTS}
            inputStyle={{
              width: "5rem",
              height: "5rem",
              borderRadius: "6px",
              border: `1px solid ${
                verifyOtpErrors.otp ? "var(--danger)" : "#ccc"
              }`,
              fontSize: "2.4rem",
              fontWeight: "800",
            }}
            focusStyle={{
              outlineColor: "var(--primary)",
            }}
            containerStyle={{
              display: "flex",
              justifyContent: "space-between",
            }}
          />

          <ErrorMessage show={verifyOtpErrors.otp}>
            {verifyOtpErrors.otp}
          </ErrorMessage>

          <Button
            style={{ width: "100%" }}
            title={"VERIFY OTP"}
            type={"submit"}
            isLoading={verifyRes.isLoading}
            error={verifyOtpErrors.general}
          />
        </Form2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "2rem 4rem",
          }}
        >
          {resendRes.isLoading ? (
            <span>Sending OTP...</span>
          ) : showResendOtp ? (
            <UnderLineButton
              onClick={() => {
                setSetResendOtpErrors({});
                resendRes.mutate();
                setShowResendOtp(false);
              }}
            >
              Resend otp
            </UnderLineButton>
          ) : (
            <div>
              <StyledAlignCenter>
                <span>Resend OTP in</span>
                <Timer onExpire={() => setShowResendOtp(true)} />
              </StyledAlignCenter>
              {resendRes.isError ? (
                <ErrorMessage show={resendRes.isError}>
                  {resendOtpError.phone_number || resendOtpError.general}
                </ErrorMessage>
              ) : null}
            </div>
          )}
        </div>
      </RegistrationCapsule>
    </>
  );
};

export default ConfirmKycOtp;
