import styled from "styled-components/macro";
import React from "react";
import { Tooltip } from "react-tooltip";
import { InfoOutlined } from "@mui/icons-material";
const OtherInfo = ({
  exit_load,
  exit_load_comment,
  expense_ratio,
  scheme_status,
  scheme_turnover,
  is_dividend_scheme,
}) => {
  const properties = [
    {
      label: (
        <div>
          <p>
            Exit Load{" "}
            <InfoOutlined
              data-tooltip-id="my-tooltip"
              data-tooltip-content={exit_load_comment}
            />
          </p>

          <Tooltip id="my-tooltip" />
        </div>
      ),
      value: exit_load || (
        <div>
          <p>{exit_load || "N/A"}</p>
        </div>
      ),
    },
    {
      label: "Expense Ratio",
      value: expense_ratio,
    },
    { label: "Scheme Status", value: scheme_status },
    { label: "Scheme Turnover", value: scheme_turnover },
    { label: "Dividend Scheme", value: is_dividend_scheme ? "Yes" : "No" },
  ];

  return (
    <GridContainer>
      {properties.map((property, index) => (
        <PropertyValueCont key={index} isLast={index === properties.length - 1}>
          <div>
            <PropertyLabel>{property.label}</PropertyLabel>
            <PropertyValue>{property.value}</PropertyValue>
          </div>
        </PropertyValueCont>
      ))}
    </GridContainer>
  );
};

export default OtherInfo;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Three columns */
  gap: 20px;

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const PropertyValueCont = styled.div`
  display: flex;
  padding: 0 10px; /* Adjust padding as needed */
  border-right: ${({ isLast }) => (isLast ? "none" : "1px solid #ccc")};
  &:last-child {
    border-right: none;
  }
  margin-bottom: 12px;
`;

const PropertyLabel = styled.p`
  font-weight: 600;
  margin-bottom: 6px;
`;

const PropertyValue = styled.p`
  color: #868e96;
`;
