import styled from "styled-components/macro";

export const Container = styled.div``;

export const AccordionTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
`;

export const Heading = styled.h1`
  font-size: 1.4rem;
`;

export const Options = styled.div`
  padding: 4px 0;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  flex-wrap: wrap;
  height: 25vh;
  overflow-y: scroll;
  margin-top: 15px;

  @media (max-width: 980px) {
    height: 25vh;
  }
`;

export const OptionLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  font-weight: 500;
`;

export const OptionCheckBox = styled.input`
  border: 1px solid red;
  height: 1.8rem;
  width: 1.8rem;
  flex: 1;
`;

export const OptionTitle = styled.p`
  font-size: 1.3rem;
  flex: 9;
`;
