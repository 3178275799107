import React, { useState, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import { Add, Delete, Edit } from "@mui/icons-material";

import AccordionComp from "components/ui/AccordionComp/AccordionComp";
import Button from "components/ui/Button/Button";
import CheckboxComp from "components/ui/CheckboxComp/CheckboxComp";
import DatePicker from "components/ui/DatePicker/DatePicker";
import InputField from "components/ui/InputField/InputField";
import Select from "components/ui/Select/Select";
import Table from "components/ui/Table/Table";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS, RELATION_OPTIONS } from "utils/constants";

import {
  ErrorMessage,
  Form,
  StyledAlignCenter,
  StyledSpaceBetween,
} from "styles/common-styled-components";
import {
  DateStringtoDmy,
  calculateAge,
  dMYtoDateString,
  mapServerErrorsToLocal,
} from "utils/functions";
import { NomineeCont } from "components/ui/RegistrationCapsule/RegistrationCapsule.styles";

Yup.addMethod(Yup.string, "checkMinorAge", function (message) {
  return this.test("minor-age", message, function (value) {
    const dobStr = dMYtoDateString(value);

    const age = calculateAge(dobStr);
    if (age > 18) {
      return this.createError({
        path: this.path,
        message,
      });
    } else {
      return true;
    }
  });
});

const NomineeForm = ({ fromLocation, navigateTo }) => {
  const { kycInfo: localKycInfo } = useSelector((state) => state.user);

  const kycnominee =
    localKycInfo?.kycnominee?.length !== 0
      ? localKycInfo?.kycnominee
      : {
          nominee1: {
            name: "",
            relation: "",
            applicable: 100,
            minor: false,
            minor_dob: "",
            minor_guardian: "",
          },
        };

  let initialNominee = {
    nominee1: {
      name: "",
      relation: "",
      applicable: 100,
      minor: false,
      minor_dob: "",
      minor_guardian: "",
    },
  };

  if (kycnominee?.length !== 0) {
    for (let i = 0; i < kycnominee?.length; i++) {
      initialNominee[`nominee${i + 1}`] = {
        ...kycnominee[i],
        minor_dob: kycnominee[i]?.minor_dob
          ? DateStringtoDmy(kycnominee[i].minor_dob)
          : "",
      };
    }
  }

  const initialErrorState = { submit: "", addMoreNominee: "", editNominee: "" };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    relation: Yup.string().required("Relation with Nominee is required"),
    applicable: Yup.number()
      .min(0, "Percentage should be between 0 and 100")
      .max(100, "Percentage should be between 0 and 100")
      .required("Applicable Percentage is required"),
    minor: Yup.bool(),
    minor_guardian: Yup.string().when("minor", {
      is: true,
      then: (schema) => schema.required("Minor Guardian is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    minor_dob: Yup.string().when("minor", {
      is: true,
      then: (schema) =>
        schema
          .required("Minor DOB is required")
          .checkMinorAge("Minor should be less than 18 years old"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const formRef = useRef();
  const navigate = useNavigate();

  const [nominees, setNominees] = useState(initialNominee);
  const [editingIndex, setEditingIndex] = useState("nominee1");
  const [error, setError] = useState(initialErrorState);
  const [serverError, setServerError] = useState({ general: "" });

  const handleAddNominee = (data) => {
    return credentialAxios.post(API_ENDPOINTS.addNominee, data);
  };

  const onSuccess = (res) => {
    setServerError({ general: "" });
    navigate(navigateTo);
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState, []);
    setServerError(newErrors);
  };

  const { mutate, isLoading } = useMutation(handleAddNominee, {
    onSuccess,
    onError,
  });

  const getNextNomineeKey = () => {
    const currentNomineeKeys = Object.keys(nominees);
    const lastNomineeKey = currentNomineeKeys[currentNomineeKeys.length - 1];
    const lastNomineeNumber = parseInt(
      lastNomineeKey.replace("nominee", ""),
      10
    );
    const nextNomineeNumber = lastNomineeNumber + 1;
    return `nominee${nextNomineeNumber}`;
  };

  const addMoreNominee = () => {
    if (!formRef.current) {
      const nextNomineeKey = getNextNomineeKey();
      if (Object.keys(nominees).length < 3) {
        setNominees((prevNominees) => ({
          ...prevNominees,
          [nextNomineeKey]: {
            name: "",
            relation: "",
            applicable: 100,
            minor: false,
            minor_dob: "",
            minor_guardian: "",
          },
        }));
        setEditingIndex(nextNomineeKey); // Set editing index to the newly added nominee
      }
    } else {
      const currentNominee = formRef.current.values;

      if (currentNominee) {
        setError((prev) => {
          return {
            ...prev,
            addMoreNominee: "Fill/Add the above Nominee to add more nominees.",
          };
        });
        return;
      } else {
        setError(initialErrorState); // Reset errors
      }

      const nextNomineeKey = getNextNomineeKey();

      if (Object.keys(nominees).length < 3) {
        setNominees((prevNominees) => ({
          ...prevNominees,
          [editingIndex]: { ...currentNominee },
          // Add an empty nominee for the next slot
          [nextNomineeKey]: {
            name: "",
            relation: "",
            applicable: 100,
            minor: false,
            minor_dob: "",
            minor_guardian: "",
          },
        }));
        setEditingIndex(nextNomineeKey); // Set editing index to the newly added nominee
      }
    }
  };

  const removeNominee = (nomineeKey) => {
    const { [nomineeKey]: removedNominee, ...updatedNominees } = nominees;
    setNominees(updatedNominees);
    setEditingIndex(null); // Reset editing index
  };

  const calculateTotalApplicable = () => {
    return Object.values(nominees).reduce(
      (total, nominee) => total + parseFloat(nominee.applicable || 0),
      0
    );
  };

  const handleLogData = () => {
    if (!formRef.current) {
      setError(initialErrorState);
      if (
        Object.keys(nominees).length > 1 &&
        calculateTotalApplicable() !== 100
      ) {
        setError((prev) => {
          return {
            ...prev,
            submit:
              "Total applicable percentage must be 100. (Edit andMake sure total of nominee applicable percentages is 100)",
          };
        });
      } else {
        const tempNominees = _.cloneDeep(
          _.pick(nominees, ["nominee1", "nominee2", "nominee3"])
        );
        const nomineesArray = Object.values(tempNominees).map((nominee) => {
          nominee.opt_out = false;
          // If minor is false, set minor_guardian and minor_dob to empty
          if (!nominee.minor) {
            delete nominee.minor_guardian;
            delete nominee.minor_dob;
          } else {
            nominee.minor_dob = dMYtoDateString(nominee.minor_dob);
          }
          return nominee;
        });

        // If there is only one nominee, set its applicable percentage to 100
        if (nomineesArray.length === 1) {
          nomineesArray[0].applicable = 100;
        }

        mutate(nomineesArray);
      }
    } else {
      const currentNominee = formRef?.current?.values;
      if (currentNominee) {
        setError((prev) => {
          return {
            ...prev,
            submit: "Fill/Add the pending Nominee to Submit.",
          };
        });
        return;
      }
    }
  };

  const handleEditNominee = (nomineeKey) => {
    if (!formRef.current) {
      setError(initialErrorState);
      setEditingIndex(nomineeKey);
    } else {
      const currentNominee = formRef?.current?.values;

      if (currentNominee) {
        setError((prev) => {
          return {
            ...prev,
            editNominee: "Fill/Add the pending Nominee to edit",
          };
        });
        return;
      }
    }
  };

  const handleSubmit = (values) => {
    // Perform any additional actions on form submission
    // Update nominees state with the current form values
    setError(initialErrorState);
    setNominees((prevNominees) => ({
      ...prevNominees,
      [editingIndex]: values,
    }));
    setEditingIndex(null); // Reset editing index
  };

  useEffect(() => {
    // Set editing index to the first nominee when component mounts
    setEditingIndex("nominee1");
  }, []);

  return (
    <div>
      {Object.entries(nominees).map(([nomineeKey, nominee], index) => (
        <NomineeCont key={index}>
          {Object.keys(nominees).length === 1 ? (
            ""
          ) : (
            <StyledSpaceBetween>
              <h2 style={{ marginBottom: "1rem" }}>{`Nominee ${index + 1}`}</h2>
              {index !== 0 && editingIndex === nomineeKey ? (
                <Button
                  style={{ width: "10rem" }}
                  contStyle={{ width: "10rem" }}
                  variant="outlined"
                  title={"Discard"}
                  onClick={() => removeNominee(nomineeKey)}
                />
              ) : null}
            </StyledSpaceBetween>
          )}

          {editingIndex !== nomineeKey ? (
            <>
              <AccordionComp
                title={"Details"}
                content={
                  <Table
                    data={{
                      Name: nominee.name,
                      "Relation with Nominee": nominee.relation,
                      "Applicable Percentage": nominee?.applicable,
                      "Is Minor": nominee.minor ? "Yes" : "No",
                      "Minor DOB":
                        nominee.minor && nominee?.minor_dob
                          ? nominee?.minor_dob
                          : "-",
                      "Minor Guardian":
                        nominee.minor && nominee?.minor_guardian
                          ? nominee?.minor_guardian
                          : "-",
                    }}
                  />
                }
              />
              <StyledSpaceBetween style={{ gap: "2rem", margin: "2rem 0" }}>
                <span
                  style={{
                    color: "#aaa",
                    fontSize: "2.4rem",
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                >
                  <Edit
                    onClick={() => handleEditNominee(nomineeKey)}
                    fontSize="2.4rem"
                  />
                </span>

                {Object.keys(nominees).length > 1 && (
                  <span
                    style={{
                      color: "var(--danger)",
                      fontSize: "2.4rem",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                  >
                    <Delete
                      onClick={() => removeNominee(nomineeKey)}
                      fontSize="2.4rem"
                    />
                  </span>
                )}
              </StyledSpaceBetween>

              <ErrorMessage show={error.editNominee}>
                {error.editNominee}
              </ErrorMessage>
            </>
          ) : (
            <Formik
              initialValues={nominee}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              innerRef={formRef}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                setFieldValue,
              }) => (
                <Form style={{ padding: 0 }} onSubmit={handleSubmit}>
                  <CheckboxComp
                    label={"Is Nominee a Minor?"}
                    checked={values.minor}
                    onChange={(bool) => setFieldValue("minor", bool)}
                  />
                  <InputField
                    label={"Name of Nominee"}
                    value={values.name}
                    onChange={handleChange("name")}
                    error={touched.name && errors.name}
                    autoFocus
                  />
                  <Select
                    options={RELATION_OPTIONS}
                    value={values.relation}
                    onChange={handleChange("relation")}
                    error={touched.relation && errors.relation}
                  />

                  {values.minor && (
                    <>
                      <DatePicker
                        label={"Minor Date of Birth"}
                        selectedValue={values.minor_dob}
                        placeholder="Select"
                        onSelect={(date) => setFieldValue("minor_dob", date)}
                        error={touched.minor_dob && errors.minor_dob}
                      />

                      <InputField
                        label={"Guardian of the Minor"}
                        value={values.minor_guardian}
                        onChange={handleChange("minor_guardian")}
                        error={touched.minor_guardian && errors.minor_guardian}
                      />
                    </>
                  )}
                  {Object.keys(nominees).length > 1 && (
                    <InputField
                      type="number"
                      label={"Applicable Percentage"}
                      value={values.applicable}
                      onChange={handleChange("applicable")}
                      error={touched.applicable && errors.applicable}
                    />
                  )}
                  <Button type="submit" title="ADD NOMINEE" />
                </Form>
              )}
            </Formik>
          )}
        </NomineeCont>
      ))}
      <NomineeCont>
        {Object.keys(nominees).length < 3 ? (
          <div>
            <Button
              onClick={addMoreNominee}
              contStyle={{ marginBottom: "2rem" }}
              variant="outlined"
              title={
                <StyledAlignCenter style={{ gap: "1rem" }}>
                  <Add />
                  <span>Add more Nominee</span>
                </StyledAlignCenter>
              }
            />

            <ErrorMessage show={error.addMoreNominee}>
              {error.addMoreNominee}
            </ErrorMessage>
          </div>
        ) : null}

        <Button
          title={fromLocation ? "EDIT & SUBMIT" : "SUBMIT"}
          onClick={handleLogData}
          error={error.submit || serverError.general}
          isLoading={isLoading}
        />
        <br />
      </NomineeCont>
    </div>
  );
};

export default NomineeForm;
