import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import Button from "components/ui/Button/Button";
import FileInput from "components/ui/FileInput/FileInput";
import Image from "components/ui/Image/Image";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import { mapServerErrorsToLocal } from "utils/functions";

import { ErrorMessage } from "styles/common-styled-components";

const UploadSignature = () => {
  const initialErrorState = { sign: "", general: "" };

  const navigate = useNavigate();

  const [base64URL, setBase64URL] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorState, setErrorState] = useState(initialErrorState);

  const handleAddSignature = () => {
    return credentialAxios.post(
      API_ENDPOINTS.uploadSignature,
      {
        signature: selectedFile,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };
  const onSuccess = () => {
    setErrorState(initialErrorState);
    navigate("/registration/confirm-details");
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState, ["sign"]);
    setErrorState(newErrors);
  };

  const { isLoading, mutate } = useMutation(handleAddSignature, {
    onSuccess,
    onError,
  });
  return (
    <div>
      {base64URL != null && (
        <Image
          alt={"_sign"}
          src={`data:image;base64,${base64URL}`}
          width="100%"
          style={{ maxWidth: "40rem", maxHeight: "30rem" }}
        />
      )}
      {selectedFile && (
        <div style={{ color: "#777", fontSize: "14px", marginBottom: "2rem" }}>
          {selectedFile.name}
        </div>
      )}
      <FileInput
        selectedFile={base64URL}
        setBase64URL={(url) => setBase64URL(url)}
        setSelectedFile={setSelectedFile}
      />
      <ErrorMessage show={errorState.sign}>{errorState.sign}</ErrorMessage>
      {base64URL ? (
        <Button
          onClick={mutate}
          isLoading={isLoading}
          title={"SUBMIT"}
          error={errorState.general}
        />
      ) : null}
    </div>
  );
};

export default UploadSignature;
