import React from "react";
import { Container } from "./WiseBullLoader.styles";
import wiseBullsLogo from "assets/logos/Wisebulls_hd_1523_1523.png";
import { BarLoader } from "react-spinners";
import { StyledCentering } from "styles/common-styled-components";
const WiseBullLoader = () => {
  return (
    <Container>
      <StyledCentering
        style={{
          flexDirection: "column",
        }}
      >
        <div style={{ height: "16rem", width: "16rem", margin: "2rem" }}>
          <img
            src={wiseBullsLogo}
            alt="WiseBulls Logo"
            style={{ width: "100%", height: "auto", objectFit: "contain" }}
          />
        </div>
        <BarLoader color={"var(--themeColor)"} />
      </StyledCentering>
    </Container>
  );
};

export default WiseBullLoader;
