import MobileNav from "components/common/MobileNav/MobileNav";
import Button from "components/ui/Button/Button";
import CustomPinKeyboard from "components/ui/CustomPinKeyboard/CustomPinKeyboard";
import styled from "styled-components";

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorMessage, StyledCentering } from "styles/common-styled-components";
import { convertNumberToCurrency } from "utils/functions";
import { centering } from "styles/common-styles";

const MobileLumpsum = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data, editStateFromPaymentMethod: editState } = state;

  const minAmount = data.min_inv;

  const [investValue, setInvestValue] = useState(
    editState !== null && editState?.amount ? editState?.amount : "0"
  );

  const [showKeyboard, setShowKeyboard] = useState(true);
  const [error, setError] = useState("");

  const handleDigit = (digit) => {
    if (digit === ".") {
      return;
    }
    setInvestValue((prev) => {
      if (digit === "-") {
        const newValue = prev.slice(0, -1);
        return newValue;
      } else {
        return prev + `${digit}`;
      }
    });
  };

  const handleInvest = () => {
    if (!investValue || Number(investValue) < minAmount) {
      setError(`Minimum SIP amount is ${minAmount}`);
      return;
    } else {
      setError("");
      const { bse_code, id, name } = data;
      navigate(`/mutual-funds/${data.id}/payment`, {
        state: {
          amount: parseInt(investValue),
          investmentType: "lumpsum",
          bse_code,
          id,
          name,
        },
        replace: true,
      });
    }
  };
  return (
    <>
      <MobileNav headerTitle={"Lumpsum Investment"} />
      <div>
        <div style={{ marginBottom: "1rem" }}>
          <p
            style={{
              textAlign: "center",
              fontWeight: "600",
              margin: "2.4rem",
              fontSize: "2rem",
            }}
          >
            Enter Amount
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "2.8rem",
              justifyContent: "center",
              fontFamily: "'Inter Tight', sans-serif",
              margin: "2.4rem",
              color: "var(--themeColor)",
            }}
            onClick={() => setShowKeyboard(true)}
          >
            {convertNumberToCurrency(investValue)}
          </p>
          {error ? (
            <ErrorMessage style={{ textAlign: "center", marginBottom: "2rem" }}>
              {error}
            </ErrorMessage>
          ) : null}

          <StyledCentering
            style={{
              flexWrap: "wrap",
              margin: "2.4rem",
              gap: "1rem",
            }}
          >
            <AddSpecificSIPAmount
              onClick={() => setInvestValue(String(minAmount))}
            >
              {convertNumberToCurrency(minAmount)}
            </AddSpecificSIPAmount>
          </StyledCentering>
        </div>

        <div style={{ position: "fixed", left: 0, right: 0, bottom: 0 }}>
          {showKeyboard ? (
            <>
              <CustomPinKeyboard onKeyPress={handleDigit} />
            </>
          ) : null}
          <div style={{ padding: "1rem" }}>
            <Button title={"Start Lumpsum"} onClick={handleInvest} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileLumpsum;

const AddSpecificSIPAmount = styled.div`
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-size: 16px;
  ${centering}
`;
