import React from "react";
import { useSelector } from "react-redux";
import MobileNav from "components/common/MobileNav/MobileNav";
import { AccountOptionsComp } from "components/global/NavBar/NavBar";
import useDocumentTitle from "hooks/useDocumentTitle";

const Account = () => {
  useDocumentTitle("Account - WiseBulls");
  const { userDetails } = useSelector((state) => state.user);

  return (
    <div>
      <MobileNav headerTitle={"Account"} />
      <AccountOptionsComp
        userDetails={userDetails}
        setShowAccountOptions={() => {}}
      />
    </div>
  );
};

export default Account;
