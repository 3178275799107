import styled from "styled-components/macro";
import { AlignCenter } from "styles/common-styles";

export const Container = styled.div`
  ${AlignCenter}

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;
export const NumberLink = styled.a`
  font-size: 3rem;
  color: var(--themeColor);
  font-weight: 800;
  display: block;
  margin-bottom: 2rem;
`;
