import Button from "components/ui/Button/Button";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  MobileView,
  Paragraph,
  StyledCentering,
} from "styles/common-styled-components";
import { STYLE_VALUES } from "utils/constants";
import signupBanner from "assets/banner/Completed-pana.svg";
import Image from "components/ui/Image/Image";

const OnBoardStatus = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  //   const data = {
  //     status: searchParams.get("status"),
  //     digio_doc_id: searchParams.get("digio_doc_id"),
  //     message: searchParams.get("message"),
  //     npci_txn_id: searchParams.get("npci_txn_id"),
  //   };

  return (
    <>
      <MobileView>
        <div style={{ height: "8rem", width: "2rem" }} />
      </MobileView>
      <StyledCentering style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: STYLE_VALUES.boxShadow,
            maxWidth: "34rem",
            padding: "2rem",
          }}
        >
          <Image
            src={signupBanner}
            alt={"Completed Registration"}
            size={"30rem"}
          />
          <div style={{ textAlign: "center" }}>
            <h2 style={{ marginBottom: "2rem" }}>
              Thanks! For registering with us.
            </h2>
            <Paragraph style={{ textAlign: "center" }}>
              Our team is verifying your KYC details. We will notify you when it
              got approved. Usually it takes 24 hours to get approved
            </Paragraph>
            <Button
              title="EXPLORE MUTUAL FUNDS"
              onClick={() => navigate("/mutual-funds")}
            />
          </div>
        </div>
      </StyledCentering>
    </>
  );
};

export default OnBoardStatus;
