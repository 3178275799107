import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 2;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
`;

export const Option = styled.div`
  text-transform: uppercase;
  font-size: 1.2rem;
  color: ${({ selectedStyling }) => (selectedStyling ? "#00BFA5" : "#666")};
  border: 1px solid #ccc;
  border-color: ${({ selectedStyling }) =>
    selectedStyling ? "#00BFA5" : "#ccc"};
  border-radius: 200px;
  cursor: pointer;
  padding: 1rem;
  min-width: 6rem;
  text-align: center;
`;
