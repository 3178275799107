import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Image = ({ src, alt, size, onClick, style }) => {
  return (
    <div
      style={{
        height: "auto",
        width: size,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClick}
    >
      <LazyLoadImage
        src={src}
        alt={alt}
        style={{
          objectFit: "contain",
          width: "100%",
          height: "100%",
          ...style,
        }}
      />
    </div>
  );
};

export default Image;
