import React from "react";
import { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import Button from "components/common/Button/Button";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import {
  Container,
  Label,
  Input,
  InputAndForward,
  CurrencyStyledInput,
} from "./InputField.styles";

const InputField = ({
  contStyles,
  as,
  label,
  placeholder,
  forwardButton = false,
  type = "text",
  value,
  paddingVertical,
  borderRadius,
  onChange,
  style,
  error,
  onValueChange,
  ...otherProps
}) => {
  const [hide, setHide] = useState(true);
  const styleObj = {
    paddingTop: paddingVertical,
    paddingBottom: paddingVertical,
    borderRadius,
    ...style,
    outline: error ? "1px solid #8e3232" : "",
  };
  return (
    <Container style={{ ...contStyles }}>
      {label && <Label htmlFor={label}>{label}</Label>}

      {forwardButton ? (
        <InputAndForward>
          <Input
            placeholder={placeholder}
            onChange={onChange}
            type={type}
            value={value}
            style={styleObj}
            as={as}
            {...otherProps}
          />
          <Button paddingTop="1.2rem" paddingBottom="1.2rem">
            <ArrowForwardIcon />
          </Button>
        </InputAndForward>
      ) : type === "password" ? (
        <div style={{ position: "relative" }}>
          <Input
            onChange={onChange}
            value={value}
            style={styleObj}
            placeholder={placeholder}
            type={hide ? "password" : "text"}
            as={as}
            {...otherProps}
          />
          {hide ? (
            <VisibilityIcon
              onClick={() => setHide(!hide)}
              style={{
                color: "var(--themeColor)",
                fontSize: "2.4rem",
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
          ) : (
            <VisibilityOffIcon
              onClick={() => setHide(!hide)}
              style={{
                color: "var(--theme-color)",
                fontSize: "2.4rem",
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
          )}
        </div>
      ) : type === "currency" ? (
        <CurrencyStyledInput
          style={styleObj}
          placeholder={placeholder}
          as={as}
          prefix="₹"
          onValueChange={(value) => onValueChange(value)}
          intlConfig={{ locale: "en-IN", currency: "INR" }}
          step={1}
          defaultValue={500}
          {...otherProps}
        />
      ) : (
        <Input
          style={styleObj}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          value={value}
          as={as}
          {...otherProps}
          id={label}
        />
      )}
      <ErrorMessage show={error} message={error} />
    </Container>
  );
};

export const CurrencyField = (props) => {
  const {
    contStyles,
    as,
    label,
    placeholder,
    paddingVertical,
    borderRadius,
    style,
    error,
    onValueChange,
    defaultValue,
    labelStyle,
  } = props;
  const styleObj = {
    paddingTop: paddingVertical,
    paddingBottom: paddingVertical,
    borderRadius,
    ...style,
    backgroundColor: error ? "var(--errorBgColor)" : "#20b2ab2c",
  };
  return (
    <>
      <Container
        style={{
          ...contStyles,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {label && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Label style={{ marginBottom: 0, ...labelStyle }}>{label}</Label>
          </div>
        )}

        <CurrencyStyledInput
          allowNegativeValue={false}
          error={error}
          style={{
            ...styleObj,
          }}
          placeholder={placeholder}
          as={as}
          prefix="₹"
          onValueChange={(value) => onValueChange(value)}
          intlConfig={{ locale: "en-IN", currency: "INR" }}
          step={100}
          defaultValue={defaultValue}
          {...props}
        />
      </Container>
      <ErrorMessage
        style={{ borderRadius: 0 }}
        show={error}
        message={error}
        variant="background"
      />
    </>
  );
};

export default InputField;
