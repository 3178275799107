import styled from "styled-components";
import { Centering } from "styles/common-styles";
import pattern from "assets/banner/abstract-background-with-squares.jpg";
export const Container = styled.div`
  position: fixed;
  inset: 0;
  background-color: white;
  ${Centering}
  /* background-image: url(${pattern}); */
  background-repeat: no-repeat;
  background-size: cover;
`;
