import { Checkbox } from "@mui/material";
import React from "react";
import {
  Label2,
  StyledSpaceBetweenAlignCenter,
} from "styles/common-styled-components";

const CheckboxComp = ({ onChange, label, checked }) => {
  return (
    <StyledSpaceBetweenAlignCenter style={{ gap: "1rem" }}>
      <Label2 style={{ marginBottom: 0 }}>{label}</Label2>
      <Checkbox
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        sx={{
          color: "var(--secondary-light-grey)",
          "&.Mui-checked": {
            color: "var(--primary)",
          },
          "& .MuiSvgIcon-root": { fontSize: 28 },
        }}
      />
    </StyledSpaceBetweenAlignCenter>
  );
};

export default CheckboxComp;
