import React from "react";

import { Container } from "./ErrorMessage.styles";

const ErrorMessage = ({ show, message, style, variant }) => {
  return (
    <Container variant={variant} style={style} show={show}>
      <p>{message}</p>
    </Container>
  );
};

export default ErrorMessage;
