import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DesktopView,
  Label,
  MobileView,
  StyledAlignCenter,
  StyledLink,
} from "styles/common-styled-components";
import styled from "styled-components";
import { convertNumberToCurrency } from "utils/functions";

const MoreInvestmentDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate("/investments");
    }
  }, []);

  return (
    <Container>
      <MobileView>
        <div style={{ height: "6rem" }} />
      </MobileView>
      <DetailsContainer>
        <StyledLink
          style={{
            textDecoration: "underline",
            fontSize: "1.6rem",
            display: "block",
          }}
          to={`/mutual-funds/${state.scheme_id}`}
        >
          {state.scheme_name}
        </StyledLink>
        <StyledAlignCenter
          style={{ fontSize: "1.3rem", marginBottom: "2rem", color: "#495057" }}
        >
          <p>{state.order_type}</p>
          <Divider />
          <p>Folio Number {state.folio_number}</p>
        </StyledAlignCenter>
        <Grid>
          <LabelValue>
            <Label>Invested Amount</Label>
            <p>{convertNumberToCurrency(state.invested_amount)}</p>
          </LabelValue>
          <LabelValue>
            <Label>Current Value</Label>
            <p>{convertNumberToCurrency(state.current_value)}</p>
          </LabelValue>
          <LabelValue>
            <Label>NAV</Label>
            <p>{state.nav}</p>
          </LabelValue>
          <LabelValue>
            <Label>Units</Label>
            <p>{state.units}</p>
          </LabelValue>
          <LabelValue>
            <Label>XIRR</Label>
            <p>{state.xirr}</p>
          </LabelValue>
          <LabelValue>
            <Label>Day Change</Label>
            <p>{state.day_change}</p>
          </LabelValue>
        </Grid>
      </DetailsContainer>

      {state.allotments.length > 0 && (
        <Section>
          <SectionTitle>Allotments</SectionTitle>
          <Table>
            <thead>
              <tr>
                <TableHeader>Date</TableHeader>

                <TableHeader>
                  <DesktopView>NAV</DesktopView>
                </TableHeader>

                <TableHeader>Units</TableHeader>
                <TableHeader style={{ color: "var(--themeColor)" }}>
                  Amount
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              {state.allotments.map((curr, i) => (
                <tr key={i}>
                  <TableCell>{formatDate(curr.report_date)}</TableCell>
                  <TableCell>
                    <DesktopView>{curr.allotted_nav}</DesktopView>
                  </TableCell>
                  <TableCell>{curr.allotted_unit}</TableCell>
                  <TableCell style={{ color: "var(--themeColor)" }}>
                    {convertNumberToCurrency(curr.allotted_amount)}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </Section>
      )}

      {state.redemptions.length > 0 && (
        <Section>
          <SectionTitle>Redemptions</SectionTitle>
          <Table>
            <thead>
              <tr>
                <TableHeader>Date</TableHeader>
                <TableHeader>Units</TableHeader>
                <TableHeader style={{ color: "var(--themeColor)" }}>
                  Amount
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              {state.redemptions.map((curr, i) => (
                <tr key={i}>
                  <TableCell>{formatDate(curr.report_date)}</TableCell>
                  <TableCell>{curr.units}</TableCell>
                  <TableCell style={{ color: "var(--themeColor)" }}>
                    {convertNumberToCurrency(curr.amount)}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </Section>
      )}
    </Container>
  );
};

// Helper function for formatting dates
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

// Styled Components
const Container = styled.div`
  padding: 2rem;
`;

const DetailsContainer = styled.div`
  padding: 2rem;
  border: 1px solid var(--secondaryLightGrey);
  border-radius: 4px;
  margin-bottom: 3.6rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 1rem 0;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
`;

const LabelValue = styled.div`
  label {
    font-size: 1.4rem;
    color: #868e96;
  }
  p {
    font-size: 1.5rem;
    font-weight: 500;
  }
`;

const Divider = styled.div`
  width: 3.2px;
  height: 3.2px;
  border-radius: 1.6px;
  background-color: var(--tertiaryLightGrey);
`;

const Section = styled.div`
  margin-top: 3rem;
`;

const SectionTitle = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

// Table Styled Components
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
  border: 1px solid var(--secondaryLightGrey);
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 1rem;
  background-color: #f1f3f5;
  font-size: 1.5rem;
  font-weight: 600;
  border-bottom: 2px solid var(--secondaryLightGrey);
`;

const TableCell = styled.td`
  padding: 1rem;
  font-size: 1.4rem;
  border-bottom: 1px solid var(--secondaryLightGrey);
`;

export default MoreInvestmentDetails;
