import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import Button from "components/ui/Button/Button";
import SignaturePad from "components/ui/SignaturePad/SignaturePad";
import Image from "components/ui/Image/Image";
import ModalPopup from "components/ui/ModalPopup/ModalPopup";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import { mapServerErrorsToLocal } from "utils/functions";

import {
  CloseButton,
  ErrorMessage,
  StyledAlignCenter,
} from "styles/common-styled-components";

const SignSignature = (fromLocation, navigateTo) => {
  const initialErrorState = { sign: "", general: "" };

  const navigate = useNavigate();

  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);

  const handleAddSignature = async () => {
    await credentialAxios.post(
      API_ENDPOINTS.uploadSignature,
      {
        signature: selectedFile,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  const onSuccess = () => {
    setErrorState(initialErrorState);
    navigate("/registration/confirm-details");
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState, ["sign"]);
    setErrorState(newErrors);
  };

  const { isLoading, mutate } = useMutation(handleAddSignature, {
    onSuccess,
    onError,
  });

  return (
    <div>
      <Button
        variant="outlined"
        title={"OPEN SIGNATURE PAD"}
        onClick={() => setShowSignaturePad(true)}
        contStyle={{ marginBottom: "1rem" }}
      />
      <ModalPopup show={showSignaturePad}>
        <CloseButton onClick={() => setShowSignaturePad(false)} />
        <StyledAlignCenter style={{ padding: "4rem", paddingTop: "6rem" }}>
          <SignaturePad
            setBase64URL={setSignatureDataURL}
            setError={(error) => setErrorState({ ...errorState, sign: error })}
            onSave={() => setShowSignaturePad(false)}
            setSelectedFile={setSelectedFile}
          />
        </StyledAlignCenter>
      </ModalPopup>

      {signatureDataURL && (
        <Image
          alt={"_sign"}
          src={signatureDataURL}
          width="100%"
          style={{
            maxWidth: "40rem",
            maxHeight: "30rem",
            border: "1px solid #ccc",
          }}
        />
      )}
      <ErrorMessage show={errorState.sign}>{errorState.sign}</ErrorMessage>

      {signatureDataURL ? (
        <Button
          onClick={mutate}
          isLoading={isLoading}
          title={"SUBMIT"}
          error={errorState.general}
        />
      ) : null}
    </div>
  );
};

export default SignSignature;
