import styled, { css } from "styled-components/macro";
import { Centering } from "styles/common-styles";
import { TopFundImgCont } from "pages/Explore/Explore.styles";

export const FiltersContainer = styled.div`
  padding: 2rem;
  border-radius: 4px;
  position: fixed;
  width: 35rem;
  box-shadow: 0 1px 5px 0 ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.cardBackground};

  @media (max-width: 980px) {
    width: 100%;
    box-shadow: none;
    position: static;
    padding: 0;
  }
  @media (min-width: 1024px) {
  }
  @media (min-width: 1400px) {
    width: 24vw;
  }
`;

export const FiltersTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  margin-bottom: 2rem;
`;

export const FilterMobileButton = styled.div`
  margin: 1rem 0px;
  padding: 1rem 2rem;
  background-color: ${({ theme }) => theme.cardBackground2};
  border-radius: 200px;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  @media (min-width: 980px) {
    display: none;
  }
`;

export const MutualFundsList = styled.div`
  padding-left: 5rem;
  flex: 2;
  @media (max-width: 980px) {
    padding: 0 1rem;
    margin-top: 6rem;
  }

  @media (min-width: 990px) {
    padding-left: 2rem;
  }
`;

export const MutualFundsContainer = styled.div``;

export const MutualFundCard = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 1.4rem;
  padding: 2rem;
  border-radius: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  background-color: ${({ theme }) => theme.cardBackground2};

  @media (max-width: 980px) {
    width: 100%;
    padding: 2rem 0;
    background-color: transparent;
  }
`;

export const MutualFundImage = styled.div`
  width: 15%;

  @media (max-width: 980px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MutualFundImageCont = styled.div`
  width: 15rem;
  height: 15rem;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (min-width: 990px) and (max-width: 1200px) {
    width: 10rem;
    height: 10rem;
  }
`;

export const MutualFundCardContent = styled.div`
  width: 85%;
`;

export const MutualFundCardContentTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
  border-bottom: 2px solid ${({ theme }) => theme.border};

  @media (max-width: 980px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const MutualFundName = styled.h1`
  font-size: 1.6rem;
  font-weight: 800;
  margin: 1.4rem 0;
`;

export const LightText = styled.p`
  font-size: 1.6rem;
  font-weight: 300;
  color: #aaa;
  font-weight: 500;
`;

export const TagContainer = styled.div`
  padding: 2rem;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  gap: 4rem;

  @media (max-width: 980px) {
    gap: 1rem;
    padding: 2rem 0;
    flex-wrap: wrap;
  }
`;

export const Tag = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  background-color: ${({ theme }) => theme.tagBackground};
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 100px;

  span {
    font-size: 1.8rem;
    font-weight: 300;
  }

  @media (max-width: 980px) {
    font-size: 1.4rem;
    padding: 1rem;

    span {
      font-size: 1.2rem;
      margin-left: 1rem;
    }
  }
`;

const ActivePage = css`
  background-color: var(--themeColor);
  color: #fff;
`;
const InActivePage = css`
  background-color: transparent;
  color: var(--themeBluishGrey);
`;

export const PaginationCont = styled.div`
  position: fixed;
  width: 100%;
  background-color: #fff;
  bottom: 2rem;
  box-shadow: ${({ theme }) => theme.boxShadow} 0px 4px 12px;
  padding: 6px;

  @media (max-width: 980px) {
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
`;

export const PageNumber = styled.div`
  padding: 1rem 1.4rem;
  border-radius: 4px;
  ${({ active }) => (active ? ActivePage : InActivePage)}
  cursor: pointer;
`;

export const ChevronCont = styled.div`
  height: 4rem;
  width: 4rem;
  background-color: var(--secondaryLightGrey);
  ${Centering}
`;

export const MFImageCont = styled(TopFundImgCont)`
  margin-bottom: 0;
`;
