import React from "react";
import ErrorBoundary from "./ErrorBoundary";

const ListRenderer = ({ items, renderItem, customKeyPropName }) => {
  return (
    <ErrorBoundary fallback={<>Oops! Something went wrong!</>}>
      {items.map((item, index) =>
        React.cloneElement(renderItem(item, index), {
          key: customKeyPropName ? item[customKeyPropName] : index,
        })
      )}
    </ErrorBoundary>
  );
};

export default ListRenderer;
