// DatePicker.js

import React, { useRef, useState } from "react";

import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import InputField from "components/ui/InputField/InputField";

import { Label, StyledCentering } from "styles/common-styled-components";
import {
  DatePickerWrapper,
  CalendarContainer,
  CalendarHeader,
  YearDropdown,
  CalendarDays,
  CalendarDay,
} from "./DatePicker.styles";
import { InputWrapper } from "../InputField/InputField.styles";
import { ErrorMessage } from "styles/common-styled-components";
import useOutsideClick from "hooks/useOutsideClick";
import { formatDateToString } from "utils/functions";

const DatePicker = ({
  onSelect = () => {},
  placeholder,
  selectedValue,
  label,
  error,
}) => {
  const ref = useRef(null);
  const [selectedDate, setSelectedDate] = useState(
    selectedValue ? selectedValue : null
  );

  const [showCalendar, setShowCalendar] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const toggleCalendar = () => {
    setShowCalendar(true);
  };

  const handleDateClick = (day) => {
    let tempDay = formatDateToString(day);
    setSelectedDate(tempDay);
    // for returning date via comp callback
    onSelect(tempDay);
    setShowCalendar(false);
  };

  const getDaysInMonth = (year, month) => {
    const lastDay = new Date(year, month + 1, 0).getDate();
    return Array.from({ length: lastDay }, (_, index) => index + 1);
  };

  const renderCalendarDays = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const daysInMonth = getDaysInMonth(year, month);

    return (
      <div>
        <CalendarHeader>
          <button
            style={{ padding: "4px" }}
            onClick={(e) => {
              e.preventDefault();
              setCurrentMonth(
                new Date(
                  currentMonth.getFullYear(),
                  currentMonth.getMonth() - 1
                )
              );
            }}
          >
            <ChevronLeft />
          </button>
          {renderYearDropdown()}
          <button
            style={{ padding: "4px" }}
            onClick={(e) => {
              e.preventDefault();
              setCurrentMonth(
                new Date(
                  currentMonth.getFullYear(),
                  currentMonth.getMonth() + 1
                )
              );
            }}
          >
            <ChevronRight />
          </button>
        </CalendarHeader>
        <StyledCentering>
          <strong style={{ color: "#333", margin: "1rem" }}>
            {currentMonth.toLocaleString("default", { month: "long" })}
          </strong>
        </StyledCentering>
        <CalendarDays>
          {daysInMonth.map((day) => (
            <CalendarDay
              key={day}
              onClick={() => handleDateClick(new Date(year, month, day))}
            >
              {day}
            </CalendarDay>
          ))}
        </CalendarDays>
      </div>
    );
  };
  useOutsideClick(ref, () => {
    setShowCalendar(false);
  });
  const renderYearDropdown = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from(
      { length: currentYear - 1899 },
      (_, index) => 1900 + index
    );

    return (
      <YearDropdown
        value={currentMonth.getFullYear()}
        onChange={(e) =>
          setCurrentMonth(
            new Date(parseInt(e.target.value, 10), currentMonth.getMonth())
          )
        }
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </YearDropdown>
    );
  };

  return (
    <div ref={ref}>
      <InputWrapper>
        {label && <Label>{label}</Label>}
        <DatePickerWrapper>
          <InputField
            variant="underlineInput"
            type="text"
            value={selectedDate ? selectedDate : ""}
            onFocus={toggleCalendar}
            readOnly
            placeholder={placeholder}
            style={{ cursor: "default" }}
          />
          {showCalendar && (
            <CalendarContainer>{renderCalendarDays()}</CalendarContainer>
          )}
        </DatePickerWrapper>
      </InputWrapper>
      {error && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ErrorMessage show={error} style={{ flex: 2 }}>
            {error}
          </ErrorMessage>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
