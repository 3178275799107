import styled, { css } from "styled-components";
import { align_center } from "styles/common-styles";
import { STYLE_VALUES } from "utils/constants";

const vertical = css``;

const horizontal = css`
  ${align_center}
`;

const VARIANTS = { horizontal, vertical };

const appendLeft = css`
  left: 10px;
`;

const appendRight = css`
  right: 10px;
`;

const APPEND_STYLES = { appendLeft, appendRight };

export const no_spin = css`
  -moz-appearance: textfield; /* Firefox */

  /* Remove default arrows in WebKit browsers (Chrome, Safari) */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  max-width: ${STYLE_VALUES.basicCompWidth};

  ${({ labelInputDirection = "vertical" }) => VARIANTS[labelInputDirection]}
`;

export const InputContainer = styled.div`
  flex: 2;
  position: relative;
`;

export const Icon = styled.span`
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  font-size: 1.6rem;
  ${({ position }) =>
    position === "left" ? APPEND_STYLES.appendLeft : APPEND_STYLES.appendRight}
`;

export const Input = styled.input`
  border: 0;
  width: 100%;
  padding: 10px 0;
  font-size: 1.6rem;
  border-bottom: 1px solid ${({ hasError }) =>
    hasError ? "var(--danger)" : "#ccc"};
  outline: none;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
  ${({ noSpin }) => (noSpin ? no_spin : null)}

  &:focus{
    border-bottom: 1px solid var(--primary);
  } 

  &::placeholder{
    color: #ccc
  }

  padding-${({ appendPosition }) =>
    appendPosition === "left" ? "left" : "right"}: ${({ appendPosition }) =>
  appendPosition ? "50px" : "10px"}; /* Adjust based on icon size *
`;
