import React from "react";
import styled from "styled-components";
import { Centering } from "styles/common-styles";
import { SyncLoader } from "react-spinners";

const MiniLoader = ({ height = "10rem", contStyle }) => {
  return (
    <Container height={height} style={contStyle}>
      <SyncLoader
        color={"var(--themeColor)"}
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </Container>
  );
};

export default MiniLoader;

export const Container = styled.div`
  height: ${({ height }) => height};
  ${Centering};
  padding-bottom: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  box-sizing: content-box;
`;
