import Skeleton from "@mui/material/Skeleton";
import styled from "styled-components";

export const StyledSkeleton = styled(Skeleton)`
  background-color: ${({ theme }) => theme.skeletonBg};
`;

export const MutualFundCardSkeletion = ({ height = 120, width = "100%" }) => {
  return (
    <StyledSkeleton
      variant="rounded"
      width={width}
      height={height}
      style={{ marginBottom: "1.4rem", display: "inline-block" }}
    />
  );
};

export const TextSkeleton = ({ width = "20%" }) => {
  return (
    <StyledSkeleton variant="text" width={width} sx={{ fontSize: "3.4rem" }} />
  );
};

export const FiltersSkeleton = () => {
  return (
    <StyledSkeleton
      variant="rounded"
      width={"100%"}
      height={"100%"}
      style={{ marginBottom: "1.4rem" }}
    />
  );
};
