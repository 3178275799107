import { ContentCopy, Done } from "@mui/icons-material";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { StyledCentering } from "styles/common-styled-components";

const CopyText = ({ copyText }) => {
  const [copied, setCopied] = useState(false);
  return (
    <CopyToClipboard
      text={copyText}
      onCopy={() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 10000);
      }}
    >
      {copied ? (
        <StyledCentering style={{ padding: "2px", border: "1px solid #ddd" }}>
          <Done style={{ color: "#888" }} />
        </StyledCentering>
      ) : (
        <ContentCopy style={{ color: "#888", cursor: "pointer" }} />
      )}
    </CopyToClipboard>
  );
};

export default CopyText;
