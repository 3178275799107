import React from "react";
import { useQuery } from "react-query";
import { Navigate, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import MobileNav from "components/common/MobileNav/MobileNav";
import NoData from "pages/NoData/NoData";
import useDocumentTitle from "hooks/useDocumentTitle";
import {
  OrderCard,
  OrderStatus,
  OrdersCont,
  StatusText,
  OrdersHead,
  ChevronWithBg,
  MobileOrderCard,
} from "./Orders.styles";
import { MobileOrderCardSkeleton, OrderCardSkeleton } from "./Orders.skeletons";
import { TagComp } from "pages/SingleMutualFund/SingleMutualFund.styles";
import {
  DesktopView,
  MobileView,
  StyledAlignCenter,
  StyledSpaceBetween,
  StyledSpaceBetweenAlignCenter,
} from "styles/common-styled-components";
import { capitalizeText } from "utils/functions";
import styled from "styled-components";

const arr = new Array(10).fill(0);

export const MyOrdersPage = ({
  data,
  isLoading,
  navigateToDetailsPage,
  noDataMessage,
}) => {
  return (
    <>
      {/* MOBILE */}
      <MobileNav headerTitle={"Orders"} heightBelow="0" />

      {!isLoading && data.length === 0 ? (
        <NoData
          message={noDataMessage}
          subMessage={"Start exploring Mutual Funds"}
          redirecting="Explore Mutual Funds"
          onRedirect={() => Navigate("/mutual-funds")}
        />
      ) : (
        <>
          <DesktopView>
            {isLoading ? (
              <OrdersCont>
                {arr.map((_, i) => {
                  return <OrderCardSkeleton key={i} height={250} />;
                })}
              </OrdersCont>
            ) : (
              <>
                <OrdersHead>
                  <p>SCHEME NAME</p>
                  <p>AMOUNT</p>
                  <p>ORDER PLACED AT</p>
                  <p>ORDER STATUS</p>
                </OrdersHead>
                {data.map((order, index) => {
                  return (
                    <OrdersCont key={index}>
                      <div
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() =>
                          navigateToDetailsPage(order.order_number)
                        }
                      >
                        <OrderStatus
                          failed={order.status === "Cancelled/Failed"}
                        />
                        <OrderCard>
                          <div>
                            <p style={{ marginBottom: "1rem" }}>
                              {capitalizeText(order.scheme_name)}
                            </p>
                            {order.transaction_type === "Redemption" ? (
                              <TagComp
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "800",
                                  padding: "1rem 2rem",
                                }}
                              >
                                Redemption
                              </TagComp>
                            ) : (
                              <TagComp
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "800",
                                  padding: "1rem 2rem",
                                }}
                              >
                                {order.order_type === "1" ? "Lumpsum" : "SIP"}
                              </TagComp>
                            )}
                          </div>
                          <div>
                            <p
                              style={{ fontSize: "1.4rem", fontWeight: "bold" }}
                            >
                              ₹{order.amount}
                            </p>
                          </div>
                          <p style={{ fontSize: "1.2rem" }}>
                            {order.transaction_type}, {order.type} placed at{" "}
                            {new Date(order.created).toString()}
                          </p>
                          <StyledSpaceBetweenAlignCenter
                            style={{ alignItems: "flex-start" }}
                          >
                            <StatusText
                              failed={order.status === "Cancelled/Failed"}
                              style={{ fontSize: "1.2rem" }}
                            >
                              {order.status.toUpperCase()}
                            </StatusText>
                            {order.status === "Cancelled/Failed" && (
                              <Tooltip
                                title="Continue Order"
                                disableInteractive
                                style={{ fontSize: "2.4rem" }}
                              >
                                <ChevronWithBg />
                              </Tooltip>
                            )}
                          </StyledSpaceBetweenAlignCenter>
                        </OrderCard>
                      </div>
                    </OrdersCont>
                  );
                })}
              </>
            )}
          </DesktopView>
          <MobileView>
            {isLoading ? (
              <OrdersCont>
                {arr.map((_, i) => {
                  return <MobileOrderCardSkeleton key={i} height={250} />;
                })}
              </OrdersCont>
            ) : (
              <>
                <OrdersCont>
                  {data.map((order, index) => {
                    return (
                      <div
                        key={index}
                        style={{ display: "grid", cursor: "pointer" }}
                        onClick={() =>
                          navigateToDetailsPage(order.order_number)
                        }
                      >
                        <OrderStatus
                          failed={order.status === "Cancelled/Failed"}
                        />
                        <MobileOrderCard>
                          <StyledSpaceBetween
                            style={{ marginBottom: "1.4rem", gap: "2rem" }}
                          >
                            <div>
                              <p
                                style={{
                                  marginBottom: "1rem",
                                  fontSize: "1.2rem",
                                }}
                              >
                                {order.scheme_name}
                              </p>
                              {order.transaction_type ===
                              "Redemption" ? null : (
                                <TagComp
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "800",
                                    padding: "1rem 2rem",
                                  }}
                                >
                                  {order.order_type === "1" ? "Lumpsum" : "SIP"}
                                </TagComp>
                              )}
                            </div>
                            <div>
                              <p
                                style={{
                                  fontSize: "1.4rem",
                                  fontWeight: "bold",
                                }}
                              >
                                ₹{order.amount}
                              </p>
                              <p style={{ fontSize: "1.2rem" }}>
                                {new Date(order.created).toLocaleDateString(
                                  "en-IN",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                              </p>
                            </div>
                          </StyledSpaceBetween>

                          <StyledAlignCenter>
                            <StatusText
                              failed={order.status === "Cancelled/Failed"}
                              style={{ fontSize: "1.2rem" }}
                            >
                              {order.status.toUpperCase()}
                            </StatusText>
                          </StyledAlignCenter>
                        </MobileOrderCard>
                      </div>
                    );
                  })}
                </OrdersCont>
              </>
            )}
          </MobileView>
        </>
      )}
    </>
  );
};

const Orders = () => {
  useDocumentTitle("Your Orders - WiseBulls");
  const navigate = useNavigate();

  const getOrdersDetail = () => {
    return credentialAxios.get(API_ENDPOINTS.getOrdersDetails);
  };

  const { data, isLoading } = useQuery("orders", getOrdersDetail);

  return (
    <Container>
      <MyOrdersPage
        data={data?.data || []}
        isLoading={isLoading}
        navigateToDetailsPage={(orderNumber) =>
          navigate(`/orders/${orderNumber}`)
        }
        noDataMessage={"You have no orders yet."}
      />
    </Container>
  );
};
const Container = styled.div`
  margin-top: 0;

  @media (max-width: 980px) {
    margin-top: 6rem;
  }
`;

export default Orders;
