import { ChevronRight } from "@mui/icons-material";
import styled from "styled-components/macro";

export const OrdersCont = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 5px 0 ${({ theme }) => theme.boxShadow};
`;

export const OrdersHead = styled.div`
  display: grid;
  padding: 2rem;
  grid-template-columns: 4fr 1fr 3fr 2fr;
  gap: 1rem;
  border: 1px solid ${({ theme }) => theme.borderColor};
  font-size: 1.2rem;
  font-weight: 800;
  background-color: ${({ theme }) => theme.cardBackground};
  width: 100%;
`;
export const OrderCard = styled.div`
  display: grid;
  padding: 2rem;
  grid-template-columns: 4fr 1fr 3fr 2fr;
  gap: 1rem;
  background-color: ${({ theme }) => theme.cardBackground};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

export const MobileOrderCard = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.cardBackground};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  justify-self: stretch;
`;

export const OrderStatus = styled.div`
  width: 1rem;
  background-color: ${({ failed, theme }) =>
    failed ? theme.failedText : theme.failedBg};
`;
export const StatusText = styled.p`
  color: ${({ failed, theme }) => (failed ? "#721c24" : theme.text)};
  font-size: 1.2rem;
  font-weight: 800;
`;

export const ChevronWithBg = styled(ChevronRight)`
  font-size: 2.4rem;
  background-color: ${({ theme }) => theme.inputBorderColor};
  cursor: pointer;
`;
