import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

const TableContainer = styled.div`
  margin: 0 auto;
  position: relative;
  font-size: 1.4rem;
`;

const Table = styled.table`
  width: 100%;
  margin: 20px 0;
  border: 1px solid #ddd; /* Border for entire table */
  border-radius: 4px; /* Border radius for the table */
  position: relative;
`;

const Thead = styled.thead`
  border-bottom: 1px solid #ddd; /* Border bottom for heading row */
  tr {
    padding-top: 20px; /* Add top spacing */
    padding-bottom: 20px; /* Add bottom spacing */
  }
`;

const Th = styled.th`
  padding: 20px 20px; /* More spacing on top and bottom */
  font-size: 1.4rem;
  font-weight: 400;
  color: #adb5bd;
  border-bottom: 1px solid #ddd; /* Border bottom for heading row */

  text-align: left;
  &:last-child {
    text-align: right; /* Right-align the second column heading */
  }
`;

const Tr = styled.tr``;

const Td = styled.td`
  padding: 16px 20px;
  &:last-child {
    text-align: right; /* Right-align the second column */
  }
`;

const Button = styled.button`
  display: block;
  margin: 0 auto; /* Center the button */
  padding: 5px 10px;
  font-size: 1.4rem;
  color: #fff;
  background-color: #efefef;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  right: 50%;
  bottom: 0;
  transform: translateY(50%);
  &:hover {
    background-color: #ddd;
  }
`;

const fadeIn = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 1000px; /* Arbitrary large value to ensure full expansion */
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    max-height: 1000px; /* Arbitrary large value to ensure full expansion */
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
`;

const Tbody = styled.tbody`
  animation: ${({ showAll }) => (showAll ? fadeIn : fadeOut)} 0.3s ease-in-out;
  overflow: hidden;
`;

const HoldingsTable = ({ holdings }) => {
  const [showAll, setShowAll] = useState(false);

  const displayedHoldings = showAll ? holdings : holdings.slice(0, 10);

  return (
    <TableContainer>
      <Table>
        <Thead>
          <tr>
            <Th>Company Name</Th>
            <Th>Holding Percentage</Th>
          </tr>
        </Thead>
        <Tbody showAll={showAll}>
          {displayedHoldings.map((holding, index) => (
            <Tr key={index}>
              <Td>{holding.company_name}</Td>
              <Td>{holding.holding_percentage}%</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {holdings.length > 10 && (
        <Button onClick={() => setShowAll(!showAll)}>
          {showAll ? (
            <KeyboardArrowUp
              style={{
                color: "#000",
                fontSize: "2rem",
              }}
            />
          ) : (
            <KeyboardArrowDown
              style={{
                color: "#000",
                fontSize: "2rem",
              }}
            />
          )}
        </Button>
      )}
    </TableContainer>
  );
};

export default HoldingsTable;
