import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  schemes: [],
};

const recentSearchSlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    addSearch: (state, action) => {
      state.schemes = state.schemes.filter(
        (curr) => curr.id !== action.payload.id
      );
      if (state.schemes.length === 5) {
        state.schemes.pop();
      }
      state.schemes = [action.payload, ...state.schemes];
    },
    removeSearch: (state, action) => {
      state.schemes = state.schemes.filter(
        (curr) => curr.id !== action.payload.id
      );
    },
  },
});

export const { addSearch, removeSearch } = recentSearchSlice.actions;

export default recentSearchSlice.reducer;
