import React from "react";
import { BarLoader } from "react-spinners";

import { StyledButton } from "./Button.styles";

const Button = ({
  name = "name=",
  onClick = () => {},
  variant = "contained",
  style,
  type,
  loading,
  loadingText = "LOADING...",
  contStyle,
}) => {
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        ...contStyle,
      }}
    >
      {loading ? (
        <BarLoader
          color="var(--themeColor)"
          style={{ width: "100%", position: "absolute", zIndex: 1 }}
        />
      ) : (
        <></>
      )}
      <StyledButton
        disableElevation
        loading={loading}
        variant={loading ? "disabled" : variant}
        type={type}
        style={{
          ...style,
        }}
        size="large"
        onClick={loading ? null : onClick}
      >
        {loading ? loadingText : name}
      </StyledButton>
    </div>
  );
};

export default Button;
