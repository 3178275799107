import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

const TableContainer = styled.div`
  margin: 0 auto;
  position: relative;
  font-size: 1.4rem;
`;

const Table = styled.table`
  width: 100%;
  margin: 20px 0;
  border: 1px solid #ddd; /* Border for entire table */
  border-radius: 4px; /* Border radius for the table */
`;

const Thead = styled.thead`
  tr {
    display: none; /* Hide the table header row */
  }
`;

const Th = styled.th`
  font-size: 1.4rem;
  font-weight: 400;
  color: #adb5bd;
`;

const Tr = styled.tr``;

const TdLabel = styled.td`
  padding: 16px 20px;
  text-align: left; /* Align labels to the left */
`;

const TdValue = styled.td`
  padding: 16px 20px;
  text-align: right; /* Align values to the right */
`;

const Button = styled.button`
  display: block;
  margin: 0 auto; /* Center the button */
  padding: 5px 10px;
  font-size: 1.4rem;
  color: #fff;
  background-color: #efefef;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  right: 50%;
  bottom: 0;
  transform: translateY(50%);
  &:hover {
    background-color: #ddd;
  }
`;

const fadeIn = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 1000px; /* Arbitrary large value to ensure full expansion */
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    max-height: 1000px; /* Arbitrary large value to ensure full expansion */
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
`;

const Tbody = styled.tbody`
  animation: ${({ showAll }) => (showAll ? fadeIn : fadeOut)} 0.3s ease-in-out;
  overflow: hidden;
`;

const formatLabel = (label) => {
  // Convert snake_case to Title Case
  return label
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const RiskStatistics = ({ holdings }) => {
  const [showAll, setShowAll] = useState(false);

  const displayedHoldings = showAll ? holdings : holdings.slice(0, 10);

  return (
    <TableContainer>
      <Table>
        <Thead>
          <tr>
            <Th>Headings</Th>
          </tr>
        </Thead>
        <Tbody showAll={showAll}>
          {Object.keys(displayedHoldings[0]).map((key, index) => (
            <Tr key={index}>
              <TdLabel>{formatLabel(key)}</TdLabel>
              <TdValue>{displayedHoldings[0][key]}</TdValue>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {holdings.length > 10 && (
        <Button onClick={() => setShowAll(!showAll)}>
          {showAll ? (
            <KeyboardArrowUp
              style={{
                color: "#000",
                fontSize: "2rem",
              }}
            />
          ) : (
            <KeyboardArrowDown
              style={{
                color: "#000",
                fontSize: "2rem",
              }}
            />
          )}
        </Button>
      )}
    </TableContainer>
  );
};

export default RiskStatistics;
