import React, { useState, useEffect } from "react";
import { credentialAxios } from "utils/api-calls";
import { MyOrdersPage } from "pages/Orders/Orders";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { convertNumberToCurrency } from "utils/functions";
import styled from "styled-components";

const Test = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    credentialAxios("/api/transaction/orders/")
      .then((response) => {
        const data = response.data;

        if (data && data.results) {
          setOrders(data.results);
        } else {
          setError("No results in the API response.");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(`Error: ${error.message}`);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Container>
      {orders.length === 0 ? (
        <p>No fund houses available.</p>
      ) : (
        orders.map((fundHouse) => (
          <AccordionForSections
            key={fundHouse.fund_house_id}
            title={fundHouse.fund_house_name}
          >
            <div style={{}}>
              <p style={{ paddingBottom: "10px", fontWeight: "bold" }}>
                Total Amount:{" "}
                {convertNumberToCurrency(
                  Number(parseFloat(fundHouse.total_amount).toFixed(2))
                )}
              </p>
            </div>
            <MyOrdersPage
              data={fundHouse.transactions || []}
              isLoading={loading}
              navigateToDetailsPage={(orderNumber) =>
                navigate(`/orders/${orderNumber}`)
              }
              noDataMessage="You have no orders yet."
              style={{ height: "none" }}
            />
          </AccordionForSections>
        ))
      )}
    </Container>
  );
};

export default Test;

export const AccordionForSections = ({
  title = "",
  children,
  defaultExpanded = true,
}) => {
  const [open, setOpen] = useState(defaultExpanded);

  return (
    <div
      style={{
        border: "1px solid #d3d3d3",
        padding: "16px",
        margin: "8px 0",
        boxSizing: "border-box",
      }}
    >
      <button
        onClick={() => setOpen(!open)}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          background: "none",
          border: "none",
          cursor: "pointer",
          padding: "8px 0",
          marginBottom: open ? "8px" : "0",
        }}
      >
        <span
          style={{
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          {title}
        </span>
        {open ? (
          <KeyboardArrowUp
            style={{
              fontSize: "20px",
            }}
          />
        ) : (
          <KeyboardArrowDown
            style={{
              fontSize: "20px",
            }}
          />
        )}
      </button>
      {open && (
        <div
          style={{
            padding: "8px 0",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
const Container = styled.div`
  margin-top: 0;

  @media (max-width: 980px) {
    margin-top: 7rem;
  }
`;
