import styled from "styled-components/macro";
import { Centering, InputCurrencyStyle } from "styles/common-styles";

export const FormTabsCont = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};

  @media (max-width: 980px) {
    padding: 0 6px;
  }
`;
export const Container = styled.div`
  position: fixed;
  width: 25%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.cardBackground};
  border: 1px solid #e9e9eb;

  @media (max-width: 1300px) {
    width: 30%;
  }
  @media (max-width: 980px) {
    width: 100%;
    position: relative;
    box-shadow: none;
  }
`;

export const DateSelectorComp = styled.div`
  position: absolute;
  box-shadow: rgba(32, 178, 171, 0.2) 0px 7px 29px 0px;
  width: 40rem;
  border-radius: 8px;
  top: 0;
  left: 0;
  transform: translate(-50%, 0);
  z-index: 10000;
  @media (max-width: 980px) {
    width: 100%;
    transform: translate(0, 0);
  }
`;

export const DateCompCont = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.background};

  @media (max-width: 980px) {
    padding: 1rem;
  }
`;

export const SelectedSIPDate = styled.div`
  ${InputCurrencyStyle}
  cursor: pointer;
`;

export const FormsCont = styled.div`
  padding: 2rem;

  @media (max-width: 980px) {
    padding: 2rem 6px;
  }
`;

export const DateButton = styled.div`
  ${Centering}
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background-color: ${({ active, theme }) =>
    active ? "var(--themeColor)" : theme.background};
  transition: font-size 0.1s ease;

  &:hover {
    background-color: ${({ active, theme }) =>
      active ? "var(--themeColor)" : theme.tagBackground};
    font-size: 1.4rem;
  }
`;

export const DateButtonTxt = styled.p`
  color: ${({ theme, active }) => (active ? "#fff" : theme.text)};
`;
