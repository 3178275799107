import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import AddBankForm from "components/forms/AddBankForm";
import Table from "components/ui/Table/Table";
import AccordionComp from "components/ui/AccordionComp/AccordionComp";

const AddBank = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.ifsc_code) {
      navigate("/registration/select-bank");
    }
  }, [state?.ifsc_code, navigate]);

  return (
    <>
      <RegistrationCapsule
        heading={"Add your Bank account"}
        para={"Please add your Bank details"}
        afterPara={
          <div style={{ marginBottom: "2rem" }}>
            <AccordionComp
              title={"See selected Bank"}
              content={
                <Table
                  data={{
                    "Bank Name": state?.bank_name,
                    "Branch Name": state?.branch_name,
                    "Branch City": state?.branch_city,
                    "IFSC Code": state?.ifsc_code,
                    "Branch State": state?.branch_state,
                  }}
                />
              }
            />
          </div>
        }
      >
        <AddBankForm ifsc_code={state?.ifsc_code} />
      </RegistrationCapsule>
    </>
  );
};

export default AddBank;
