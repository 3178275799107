export const boxShadows = {
  themed: `var(--transparentThemeColor) 0px 2px 8px 0px`,
};

export const lightTheme = {
  colorScheme: "light",
  text: "var(--themeBluishGrey)",
  background: "#fff",
  borderColor: "#ddd",
  borderColor2: "#f1f3f5",
  boxShadow: "rgba(0, 0, 0, 0.1)",
  searchBorder: "var(--tertiaryLightGrey)",
  cardBackground: "#fff",
  cardBackground2: "var(--lightGrey)",
  tagBackground: "#efeff4",
  skeletonBg: "rgba(0, 0, 0, 0.11)",
  failedText: "#f8d7da",
  failedBg: "#efefef",
  inputBorderColor: "#ddd",
  errorTextColor: "var(--errorTextColor)",
  transparentColor: "rgba(0, 0, 0, 0.6)",
  placeholderColor: "#ccc",
};

export const darkTheme = {
  colorScheme: "dark",
  text: "#dcdcdd",
  background: "#202124",
  borderColor: "#3a3b3d",
  borderColor2: "#212529",

  boxShadow: "rgba(0, 0, 0, 0.1)",
  searchBorder: "var(--themeDarkGrey)",
  cardBackground: "#292A2D",
  cardBackground2: "#292A2D",
  tagBackground: "#3a3b3d",
  skeletonBg: "rgba(255, 255, 255, 1)",
  failedText: "#f8d7da",
  failedBg: "#313131",
  inputBorderColor: "#3a3b3d",
  errorTextColor: "#af5353",
  transparentColor: "rgba(255, 255, 255, 0.1)",
  placeholderColor: "#555",
};
