import styled from "styled-components/macro";
import React from "react";
import { convertNumberToCurrency } from "utils/functions";

const MFInfo = ({
  risk,
  asset_size,
  nav_latest,
  nav_latest_date,
  day_change,
  min_sip,
  min_lumpsum,
}) => {
  const properties = [
    {
      label: "Fund Size",
      value: `${convertNumberToCurrency(parseFloat(asset_size).toFixed(2))} Cr`,
    },
    {
      label: (
        <div>
          <p>
            NAV <span>({nav_latest_date})</span>
          </p>
        </div>
      ),
      value: `${nav_latest}`,
    },
    { label: "Day Change", value: `${day_change}%` },
    { label: "Min SIP", value: convertNumberToCurrency(min_sip) },
    { label: "Min Lumpsum", value: convertNumberToCurrency(min_lumpsum) },
  ];

  return (
    <GridContainer id="mf-info">
      {properties.map((property, index) => (
        <PropertyValueCont key={index} isLast={index === properties.length - 1}>
          <div>
            <PropertyLabel>{property.label}</PropertyLabel>
            <PropertyValue>{property.value}</PropertyValue>
          </div>
        </PropertyValueCont>
      ))}
    </GridContainer>
  );
};

export default MFInfo;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 8rem;

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const PropertyValueCont = styled.div`
  display: flex;
  padding: 0 10px;
  border-right: ${({ isLast }) => (isLast ? "none" : "1px solid #ccc")};
  &:last-child {
    border-right: none;
  }
  margin-bottom: 12px;
`;

const PropertyLabel = styled.p`
  font-weight: 600;
  margin-bottom: 6px;
`;

const PropertyValue = styled.p`
  color: #868e96;
`;
