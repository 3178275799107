import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components/macro";
import {
  AlignCenter,
  Centering,
  SpaceBetween,
  SpaceBetweenAlignCenter,
} from "styles/common-styles";

export const HeightCont = styled.div`
  height: 10rem;

  @media (max-width: 980px) {
    display: none;
  }
`;

export const padding = css`
  padding: 4rem 18rem;

  @media (max-width: 1396px) {
    padding: 4rem 4rem;
  }
  @media (max-width: 1024px) {
    padding: 4rem 2rem;
  }
  @media (max-width: 980px) {
    padding: 1rem;
  }
`;

export const StyledPadding = styled.div`
  ${({ noPadding }) => (noPadding ? null : padding)}
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: padding 0.3s ease-in-out;
  justify-content: space-between;
`;

export const StyledSubContainer = styled.div`
  margin: 4rem 6rem;
`;

export const StyledCentering = styled.div`
  ${Centering}
`;

export const StyledAlignCenter = styled.div`
  ${AlignCenter};
  gap: 6px;
`;

export const StyledSpaceBetweenAlignCenter = styled.div`
  ${SpaceBetweenAlignCenter}
`;
export const StyledSpaceBetween = styled.div`
  ${SpaceBetween}
`;
export const PrimaryHeading = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.text};
`;

export const SubHeading = styled.h2`
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  font-weight: 800;
`;

export const BigHeading = styled.h2`
  font-size: 3rem;
  margin-bottom: 2.4rem;
  font-weight: 800;
`;

export const HeadingCont = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeadingPrimary = styled.h1`
  font-size: 2rem;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
  color: ${({ color }) => (color ? color : "")};
  margin-bottom: 1rem;
`;

export const HeadingPrimaryBold = styled.span`
  color: ${({ color, theme }) => (color ? color : theme.text)};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "2rem")};
  font-weight: 800;
`;

export const Paragraph = styled.p`
  font-size: 1.4rem;
  margin: 0;
  margin-bottom: 2rem;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
`;

export const Paragraph2 = styled.p`
  font-size: 1.4rem;
  margin: 1rem;
  color: #555;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Form2 = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 4rem;
  width: 100%;

  @media (max-width: 600px) {
    padding: 4px;
  }
`;

export const Label2 = styled.label`
  display: block;
  font-size: 1.6rem;
  margin-bottom: 6px;
  color: var(--medium-grey);
  flex: 1;
  /* text-align: ${({ labelInputDirection }) =>
    labelInputDirection === "horizontal" ? "right" : "default"}; */
`;

export const UnderLineButton = styled.span`
  color: var(--themeColor);
  font-size: 1.4rem;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
`;

export const ProfileButton = styled.div`
  font-size: 1.8rem;
  height: 4rem;
  width: 4rem;
  background-color: var(--themeColor);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  color: white;
  overflow: hidden;
`;

export const CloseButton = ({ onClick, style, contStyle, radius = "3rem" }) => {
  return (
    <CloseButtonCont onClick={onClick} radius={radius} contStyle={contStyle}>
      <Close style={{ fontSize: "1.8rem", ...style }} />
    </CloseButtonCont>
  );
};

const CloseButtonCont = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  ${Centering}
  height: ${({ radius }) => radius};
  width: ${({ radius }) => radius};
  background-color: ${({ theme }) => theme.cardBackground2};
  border-radius: 100px;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  &:link,
  &:visited,
  &:active {
    color: ${({ theme }) => theme.text};
    text-decoration: none;
  }
`;

// export const StyledLink = styled(Link)`
//   &:link,
//   &:visited,
//   &:active {
//     color: var(--secondary);
//   }
// `;

export const MobileView = styled.div`
  display: none;
  @media (max-width: 980px) {
    display: block;
  }
`;
export const DesktopView = styled.div`
  display: block;
  @media (max-width: 980px) {
    display: none;
  }
`;

export const Table = styled.table`
  margin-bottom: 2rem;
`;

export const TableRow = styled.tr``;

export const TableHead = styled.th`
  text-align: left;
  padding-right: 2.4rem;
  padding-bottom: 1rem;
`;

export const TableData = styled.td`
  padding-bottom: 1rem;
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
`;

export const BottomMessageCont = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background-color: var(--lightGrey);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

  @media (min-width: 600px) {
    left: 50%;
    transform: translateX(-50%);
    max-width: 45rem;
  }
`;

// //////TEMP

export const ErrorMessage = styled.div`
  color: #e61515;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  display: block;
  font-size: 1.6rem;
  margin-bottom: 6px;
`;

const blinkAnimation = keyframes`
  0% {
    border-color: transparent;
  }
  50% {
    border-color: rgba(32, 178, 171, 0.5);
  }
  100% {
    border-color: transparent;
  }
`;

export const HighlightWrapper = styled.div`
  border: 4px solid transparent;
  border-radius: 4px;
  ${(props) =>
    props.isHighlighted &&
    css`
      animation: ${blinkAnimation} 3s;
    `}
`;

export const OrSeperator = styled.p`
  margin-bottom: 8px;
  position: relative;
  width: 100%;
  font-size: 14px;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    width: calc(50% - 2ch);
    height: 1px;
    background: var(--secondaryLightGrey);
    font-weight: 500;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
`;
