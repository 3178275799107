import styled from "styled-components/macro";

export const AlertCont = styled.div`
  padding: 2rem;
  margin: 0 2rem;
  font-size: 1.4rem;
  border-radius: 4px;
  width: 100%;
  background-color: #304666;
  color: #eee;
  min-width: 20rem;
`;
