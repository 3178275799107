import React, { useState } from "react";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Button from "components/ui/Button/Button";
import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import {
  calculateAge,
  dMYtoDateString,
  mDytoDmy,
  mapServerErrorsToLocal,
} from "utils/functions";
import { API_ENDPOINTS } from "utils/constants";
import { credentialAxios } from "utils/api-calls";

import { Form, Form2 } from "styles/common-styled-components";
import DatePicker from "components/ui/DatePicker/DatePicker";
import OptionSelector from "components/ui/OptionSelector/OptionSelector";
import { useSelector } from "react-redux";

Yup.addMethod(Yup.string, "checkAdultAge", function (message) {
  return this.test("adult-age", message, function (value) {
    if (!value) {
      return this.createError({
        path: this.path,
        message: "Date of Birth is required",
      });
    }
    const dobStr = dMYtoDateString(value);

    const age = calculateAge(dobStr);
    if (age < 18) {
      return this.createError({
        path: this.path,
        message,
      });
    } else {
      return true;
    }
  });
});

const AddPersonalDetails = () => {
  const { kycInfo: localKycInfo } = useSelector((state) => state.user);

  const initialErrorState = { dob: "", gender: "", general: "" };

  const navigate = useNavigate();

  const { state } = useLocation();

  const [errorState, setErrorState] = useState(initialErrorState);

  const fromLocation = state?.fromLocation;
  const navigateTo = fromLocation || "/registration/add-professional-details";

  const validationSchema = Yup.object().shape({
    dob: Yup.string()
      .required("Date of Birth is required")
      .checkAdultAge("User should be atleast 18 years of age"),
    gender: Yup.string().required("Gender is required"),
  });

  //// API CALL

  const handleAddPersonalDetails = ({ dob, gender }) => {
    return credentialAxios.post(API_ENDPOINTS.addPersonalDetails, {
      dob: dMYtoDateString(dob),
      gender,
    });
  };

  const onSuccess = (res) => {
    setErrorState(initialErrorState);
    navigate(navigateTo);
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState, [
      "gender",
      "dob",
    ]);
    setErrorState(newErrors);
  };

  const { mutate, isLoading } = useMutation(handleAddPersonalDetails, {
    onSuccess,
    onError,
  });

  //// END API CALL
  return (
    <>
      <RegistrationCapsule
        heading={`${fromLocation ? "Edit" : "Add"} personal details`}
        para={`Please ${
          fromLocation ? "edit" : "add"
        } your gender and DOB as per PAN`}
        fromLocation={fromLocation}
      >
        <Formik
          initialValues={{
            dob: localKycInfo?.dob ? localKycInfo.dob : "",
            gender: localKycInfo?.gender || "",
          }}
          onSubmit={mutate}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => {
            return (
              <Form2 onSubmit={handleSubmit}>
                <DatePicker
                  label={"Date of Birth"}
                  selectedValue={values.dob}
                  placeholder="Select"
                  onSelect={(date) => setFieldValue("dob", date)}
                  error={touched.dob && (errors.dob || errorState.dob)}
                />

                <OptionSelector
                  value={values.gender}
                  onSelect={(val) => setFieldValue("gender", val)}
                  label={"Gender"}
                  options={[
                    { label: "Male", value: "M" },
                    { label: "Female", value: "F" },
                  ]}
                  error={touched.gender && (errors.gender || errorState.gender)}
                />

                <Button
                  title={fromLocation ? "EDIT" : "SUBMIT"}
                  type={"submit"}
                  isLoading={isLoading}
                  error={errorState.general}
                />
              </Form2>
            );
          }}
        </Formik>
      </RegistrationCapsule>
    </>
  );
};

export default AddPersonalDetails;
