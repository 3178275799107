import React, { useState } from "react";

import {
  Bottom,
  Container,
  MainCont,
  ModalLogo,
  Top,
  WidthController,
} from "components/common/LoginRegisterContent/LoginRegister.styles";

import {
  HeadingCont,
  HeadingPrimary,
  HeadingPrimaryBold,
  Paragraph,
  Paragraph2,
  StyledLink,
} from "styles/common-styled-components";

import logo from "assets/logos/logo.png";
import useDocumentTitle from "hooks/useDocumentTitle";
import LoginForm2 from "components/forms/LoginForm2";
import LoginForm from "components/forms/LoginForm";

const Login = ({ closeModal }) => {
  useDocumentTitle("Login - WiseBulls");
  const [loginWithEmail, setLoginWithEmail] = useState(false);

  return (
    <MainCont>
      <Container>
        <>
          <Top loginRegisterPage={true}>
            <StyledLink to={"/"}>
              <ModalLogo src={logo} />
            </StyledLink>

            <div style={{ position: "absolute", bottom: "1rem", left: "3rem" }}>
              <HeadingPrimary color="#f7f8fa">
                Investing in{" "}
                <HeadingPrimaryBold color="#f7f8fa">
                  Mutual Funds simplified
                </HeadingPrimaryBold>
              </HeadingPrimary>

              <HeadingPrimary color="#f7f8fa"></HeadingPrimary>
            </div>
          </Top>

          <Bottom>
            <WidthController>
              <HeadingCont>
                <HeadingPrimary>
                  {loginWithEmail ? "Login" : "Login/Signup"} to{" "}
                  <HeadingPrimaryBold>WiseBulls</HeadingPrimaryBold>
                </HeadingPrimary>
              </HeadingCont>

              <Paragraph>
                {loginWithEmail ? "Login" : "Login/Signup"} to WiseBulls for a
                more personalised experience
              </Paragraph>

              {loginWithEmail ? <LoginForm /> : <LoginForm2 />}
              <div style={{ marginBottom: "2rem", textAlign: "center" }}>
                <Paragraph
                  style={{
                    margin: 0,
                    marginBottom: "2rem",
                    color: "var(--secondary-light-grey)",
                  }}
                  textAlign="center"
                >
                  Or
                </Paragraph>

                <p
                  style={{
                    textAlign: "center",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginBottom: "2rem",
                  }}
                  onClick={() => setLoginWithEmail(!loginWithEmail)}
                >
                  {loginWithEmail
                    ? "Login/Signup with phone and OTP"
                    : "Login with email and password"}
                </p>

                {/* STATIC CONTENT */}
              </div>
            </WidthController>
          </Bottom>
        </>
      </Container>
    </MainCont>
  );
};

export default Login;
