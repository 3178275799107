import React from "react";
import styled from "styled-components";
import { List, UnderLineButton } from "styles/common-styled-components";

const DeleteAccount = () => {
  return (
    <div>
      <div style={{ marginBottom: "2rem" }}>
        <h3>Deleting your WiseBulls account</h3>
      </div>

      {/* <div style={{ marginBottom: "1.6rem" }}>
       
      </div> */}
      <List>
        <li>Dear User,</li>
        <li>
          We hope this message finds you well. We understand that circumstances
          can change, and you may have reasons to consider closing your account
          with our WiseBulls Mutual Funds App. In order to streamline and ensure
          the security of this process, we have established an account deletion
          procedure that involves sending an email to initiate the closure of
          your account.
        </li>
        <li>Here's why we've chosen this method and the steps involved:</li>
        <li>
          1. Personalized Attention: We believe in providing personalized
          services to our users, even when they decide to close their accounts.
          Sending an email allows us to verify your identity and ensures that
          your account closure request is genuine.
        </li>
        <li>
          2. Security and Authentication: Account security is our utmost
          priority. By sending an email from the registered email address
          associated with your account, we can verify that the request is
          legitimate and not made by unauthorized parties.
        </li>
        <li>
          3. Clarification of Intent: Closing an account is an important
          decision. By sending an email, you have the opportunity to communicate
          your reasons for account closure, which helps us understand your needs
          better and potentially address any concerns you might have.
        </li>
      </List>
      <h3 style={{ marginBottom: "2rem" }}>
        Steps to Initiate Account Deletion:
      </h3>
      <List>
        <li>
          1. Compose an Email: Use the email address linked to your Mutual Funds
          App account to send an email to{" "}
          <a style={{ display: "inline" }} href="mailto:help@wisebulls.com">
            help@wisebulls.com
          </a>
          . Please use the subject line: "Account Deletion Request - [Your
          Username/ID]."
        </li>
        <li>
          2. Verification: In the email, kindly provide your full name, account
          username or ID, and any additional details that can help us identify
          your account.
        </li>
        <li>
          3. Reason for Closure (Optional): While not mandatory, sharing the
          reason for your decision to close the account can be valuable feedback
          for us. It might also provide us with a chance to assist you before
          you finalize your decision.
        </li>
        <li>
          4. Confirmation: Once we receive your email, our dedicated team will
          review your request and verify the information provided. You can
          expect a confirmation email within 3 working days, acknowledging the
          receipt of your account closure request.
        </li>
        <li>
          5. Account Closure: After confirming your identity and reviewing your
          request, we will proceed with the account closure process. You will
          receive a final email notification confirming the successful closure
          of your Mutual Funds App account.
        </li>
      </List>
    </div>
  );
};

export default DeleteAccount;
