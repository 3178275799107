import axios from "axios";
import store from "redux/store";
import { addUser } from "redux/user/user-slice";
import storage from "redux-persist/lib/storage";

// DON'T FORGET TO CHANGE THIS WHEN PUSHING TO PRODUCTION
// Before changing value, logout from the account in UI

const DEV_ENABLED = false;
const DEV_IPV4_ADDRESS = "192.168.10.236";

const baseURL = "https://api.wisebulls.com";

const baseDevURL = `http://${DEV_IPV4_ADDRESS}:8000`;

const URL = DEV_ENABLED ? baseDevURL : baseURL;

const logout = () => {
  storage.removeItem("persist:root");
  sessionStorage.setItem("auth-checked", false);
  sessionStorage.clear();
  window.location.reload();
};

export const credentialAxios = axios.create({
  baseURL: URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

credentialAxios.interceptors.request.use(
  async (config) => {
    try {
      const token = store.getState().user.currentUser;

      // If the token exists, set the Authorization header
      if (token?.access) {
        config.headers["Authorization"] = `Bearer ${token.access}`;
      } else {
        config.headers["Authorization"] = `Token ${token}`;
      }

      // Always return the config, even if there is no token
      return config;
    } catch (error) {
      // Return a rejected promise in case of an error
      return Promise.reject(error);
    }
  },
  (error) => {
    // Reject the promise if there’s an error in the interceptor itself
    return Promise.reject(error);
  }
);

credentialAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const token = store.getState().user.currentUser;

    // Check if the error is due to an expired token
    if (!token.refresh) {
      logout();
    }

    if (
      error.response &&
      (error.response.data.detail === "Access token is invalid or expired" ||
        error.response.data.detail ===
          "Authentication credentials were not provided.")
    ) {
      try {
        // Perform token refresh by calling the refresh token endpoint
        const res = await axios.post(`${URL}/api/user/auth/token/refresh/`, {
          refresh: token?.refresh,
        });

        const { access, refresh } = res.data;

        // Dispatch the updated tokens to Redux store
        store.dispatch(
          addUser({
            access,
            refresh,
          })
        );

        // Update Authorization header with the new access token
        originalRequest.headers["Authorization"] = `Bearer ${access}`;

        // Retry the original request with the new token
        return credentialAxios(originalRequest);
      } catch (refreshError) {
        // Check if refresh token has also expired
        if (
          refreshError.response?.data?.detail === "Token is invalid or expired"
        ) {
          // Dispatch logout action and redirect user to login page or show a logout message
          logout();
          return Promise.reject(refreshError); // Reject with refresh token error
        }

        return Promise.reject(refreshError); // Reject other errors in refreshing token
      }
    }

    return Promise.reject(error); // If not an expired token, reject the original error
  }
);

export const publicAxios = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/json",
  },
});
