import React, { useState } from "react";
import Button from "components/ui/Button/Button";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import { StyledAlignCenter } from "styles/common-styled-components";
import { Download } from "@mui/icons-material";

const DownloadReports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleDownload = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Fetch report as blob
      const res = await credentialAxios.get(API_ENDPOINTS.downloadReports, {
        responseType: "blob",
      });

      if (res.status !== 200) {
        throw new Error("Failed to download the report.");
      }

      const today = new Date().toISOString().split("T")[0];

      const blob = new Blob([res.data], { type: res.data.type });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `transactions_${today}.csv`;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      setError(error.message || "An error occurred during download.");
    } finally {
      setIsLoading(false);
      console.log("Download process complete");
    }
  };

  return (
    <div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <Button
        title={
          <StyledAlignCenter>
            <Download />
            <span>{isLoading ? "Downloading..." : "Download Report"}</span>
          </StyledAlignCenter>
        }
        onClick={handleDownload}
        isLoading={isLoading}
      />
    </div>
  );
};

export default DownloadReports;
