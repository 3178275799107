import React from "react";
import styled from "styled-components";

import Image from "components/ui/Image/Image";
import logo from "assets/logos/logo-new.png";
import { padding } from "styles/common-styled-components";
import linkedInLogo from "assets/logos/icons8-linkedin.svg";
import instagramLogo from "assets/logos/icons8-instagram.svg";
import { centering, space_between } from "styles/common-styles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Main = styled.div`
  ${padding}
  padding-top: 6rem;
  padding-bottom: 2rem;
  background-color: #edfbfa;
  font-size: 1.2rem;
  position: relative;
  justify-self: flex-end;
  margin-top: 8rem;
`;

const BottomCont = styled.div`
  ${space_between}
  /* ${centering} */
  @media (max-width: 980px) {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

const TopCont = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 6rem;
  margin-bottom: 2rem;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const LeftCont = styled.div`
  flex: 0.75;

  @media (max-width: 980px) {
    ${centering}
    flex-direction: column;
    text-align: center;
  }
`;

const RightCont = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 980px) {
    width: 100%;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  justify-self: flex-end;

  @media (max-width: 768px) {
    min-width: auto;
  }
`;

const LinksTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 5px;
  padding: 0;
  padding-left: 12px;
  border-left: 5px solid var(--themeColor);
`;

const Links = styled.div`
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SocialMediaLinks = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
  gap: 6px;
`;

const StyledLink = styled(Link)`
  &:link,
  &:visited,
  &:active {
    color: ${({ theme }) => "#868e96"};
    text-decoration: none;
  }
`;

const Footer = () => {
  const userToken = useSelector((state) => state.user.currentUser);

  return (
    <Main>
      <TopCont>
        <LeftCont>
          <Image src={logo} size={"10rem"} style={{ marginBottom: "6px" }} />
          <p
            style={{
              fontStyle: "normal",
              marginBottom: "2rem",
              lineHeight: "26px",
              fontWeight: "500",
              color: "#868e96",
            }}
          >
            Mutual Fund investments are subject to market risks. Please read all
            scheme related documents carefully before investing. Past
            performance is not an indicator of future returns.
          </p>
        </LeftCont>
        <RightCont>
          <LinksContainer>
            <LinksTitle>Links</LinksTitle>
            <Links>
              <StyledLink to="/">Home</StyledLink>
              {userToken ? null : (
                <>
                  <StyledLink to="/register">Get Started</StyledLink>
                  <StyledLink to="/login">Login</StyledLink>
                </>
              )}

              <StyledLink to="/investments">Investments</StyledLink>
            </Links>
          </LinksContainer>
          <LinksContainer>
            <LinksTitle>Others</LinksTitle>
            <Links>
              <StyledLink to="/help">Help</StyledLink>
              <StyledLink to="/privacy">Privacy Policy</StyledLink>
              <StyledLink to="/user/delete-account">Delete Account</StyledLink>
            </Links>
          </LinksContainer>
          <LinksContainer>
            <LinksTitle>Social Media</LinksTitle>
            <p style={{ paddingLeft: 12, color: "#868e96" }}>
              Follow us on our Social Media handles
            </p>
            <SocialMediaLinks>
              <a
                href="https://www.instagram.com/wise_bulls/"
                target="_blank"
                rel="noreferrer"
              >
                <Image src={instagramLogo} size={"2.4rem"} />
              </a>
              <a
                href="https://www.linkedin.com/company/89937686/admin/feed/posts/"
                target="_blank"
                rel="noreferrer"
              >
                <Image src={linkedInLogo} size={"2.4rem"} />
              </a>
            </SocialMediaLinks>
          </LinksContainer>
        </RightCont>
      </TopCont>
      <BottomCont>
        <p>
          &#169; {new Date().getFullYear()} wisebulls.com | All Rights Reserved
          {/* Built with{" "}
          <span style={{ color: "var(--lossTextColor)", fontSize: "1.4rem" }}>
            ♥
          </span>{" "}
          in India */}
        </p>
        {/* <p style={{ textAlign: "left", fontWeight: "600" }}>English</p> */}
      </BottomCont>
    </Main>
  );
};

export default Footer;
