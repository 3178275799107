import React from "react";
import { useNavigate } from "react-router-dom";
import shrugImage from "assets/banner/Shrug-bro.svg";
import {
  BigHeading,
  StyledCentering,
  SubHeading,
} from "styles/common-styled-components";
import Button from "components/ui/Button/Button";
import { Container } from "./NoData.styles";
import useDocumentTitle from "hooks/useDocumentTitle";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Image from "components/ui/Image/Image";

const NoData = ({
  message,
  redirecting = "Explore Mutual Funds",
  onRedirect,
  imageSrc,
  subMessage,
  subMessage2,
}) => {
  useDocumentTitle("WiseBulls");
  const navigate = useNavigate();
  return (
    <StyledCentering style={{ width: "100%" }}>
      <Container>
        <StyledCentering>
          <Image
            src={imageSrc ? imageSrc : shrugImage}
            alt="A man shrugging there is not investments"
            size={"30rem"}
          />
        </StyledCentering>

        <div style={{ flex: 1, maxWidth: "36rem" }}>
          <h3 style={{ fontSize: "2.4rem", marginBottom: "1rem" }}>
            {message}
          </h3>
          <p>{subMessage}</p>
          <p>{subMessage2}</p>
          <Button
            contStyle={{ marginTop: "2rem" }}
            onClick={onRedirect ? onRedirect : () => navigate("/mutual-funds")}
            title={redirecting}
          />
        </div>
      </Container>
    </StyledCentering>
  );
};

export default NoData;
