import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ChevronLeft } from "@mui/icons-material";

import PageContainer from "components/ui/PageContainer/PageContainer";
import { API_ENDPOINTS, STYLE_VALUES } from "utils/constants";
import {
  addKycInfo,
  addNextRegistrationScreen,
  addUserDetails,
} from "redux/user/user-slice";
import { credentialAxios } from "utils/api-calls";

import { StyledAlignCenter } from "styles/common-styled-components";
import { Content } from "./RegistrationCapsule.styles";
import {
  getNextRegistrationScreen,
  mapServerErrorsToLocal,
} from "utils/functions";
import MiniLoader from "components/common/MiniLoader/MiniLoader";

const RegistrationCapsule = ({
  children,
  heading,
  para,
  afterPara,
  fromLocation,
}) => {
  const initialErrorState = { general: "" };
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState(initialErrorState);

  const handleUpdateUserData = async () => {
    try {
      // Fetch user info
      const userInfoResponse = await credentialAxios.get(
        API_ENDPOINTS.getUserInfo
      );

      console.log(`------ Setting user-info for auth ------`);
      dispatch(addUserDetails(userInfoResponse.data));

      // Fetch KYC info
      const kycInfoResponse = await credentialAxios.get(
        API_ENDPOINTS.getKycData
      );

      dispatch(addKycInfo(kycInfoResponse.data));

      const nextRegistrationScreen = getNextRegistrationScreen(
        userInfoResponse.data,
        kycInfoResponse.data
      );

      dispatch(addNextRegistrationScreen(nextRegistrationScreen));
    } catch (error) {
      console.log(error);
      const newErrors = mapServerErrorsToLocal(error, initialErrorState);
      setErrors(newErrors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleUpdateUserData();
  }, []);

  return (
    <PageContainer>
      <div
        style={{
          maxWidth: STYLE_VALUES.basicCompWidth,
          boxShadow: location.state?.nonReg ? STYLE_VALUES.boxShadow : null,
          paddingTop: location.state?.nonReg ? "4rem" : null,
          paddingBottom: location.state?.nonReg ? "4rem" : null,
        }}
      >
        <Content>
          {fromLocation ? (
            <StyledAlignCenter
              style={{
                marginBottom: "1rem",
                color: "var(--secondary)",
                cursor: "pointer",
              }}
              onClick={() => navigate(fromLocation)}
            >
              <ChevronLeft style={{ fontSize: "2.4rem" }} />
              <span>Go Back</span>
            </StyledAlignCenter>
          ) : null}

          <h2 style={{ marginBottom: "1rem" }}>{heading}</h2>
          {para && (
            <p style={{ paddingBottom: afterPara ? "0" : "2rem" }}>{para}</p>
          )}
          <>{afterPara}</>
        </Content>
        {loading ? (
          <MiniLoader contStyle={{ borderBottom: 0 }} />
        ) : (
          <div style={{}}>{children}</div>
        )}
      </div>
    </PageContainer>
  );
};

export default RegistrationCapsule;
