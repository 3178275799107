import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import InputField from "components/ui/InputField/InputField";
import Button from "components/ui/Button/Button";
import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import { mapServerErrorsToLocal } from "utils/functions";
import { API_ENDPOINTS, REGULAR_EXPRESSIONS } from "utils/constants";
import { credentialAxios } from "utils/api-calls";

import { Form2 } from "styles/common-styled-components";
import { useSelector } from "react-redux";
import DynamicTypeInput from "components/ui/DynamicTypeInput/DynamicTypeInput";

const VerifyPan = () => {
  const { kycInfo: localKycInfo } = useSelector((state) => state.user);

  const initialErrorState = { pan: "", general: "" };
  const navigate = useNavigate();
  const { state } = useLocation();

  const fromLocation = state?.fromLocation;
  const navigateTo = fromLocation || "/registration/add-personal-details";

  const [errorState, setErrorState] = useState(initialErrorState);

  const validationSchema = Yup.object().shape({
    pan: Yup.string()
      .uppercase()
      .matches(REGULAR_EXPRESSIONS.pan, "PAN is not valid")
      .required("PAN is required"),
  });

  //// API CALL

  const handleAddPan = ({ pan }) => {
    return credentialAxios.post(API_ENDPOINTS.verifyPan, {
      pan,
    });
  };

  const onSuccess = (res) => {
    setErrorState(initialErrorState);
    navigate(navigateTo);
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState, ["pan"], {
      error: "pan",
    });
    setErrorState(newErrors);
  };

  const { mutate, isLoading } = useMutation(handleAddPan, {
    onSuccess,
    onError,
  });

  //// END API CALL
  return (
    <>
      <RegistrationCapsule
        heading={"Verify your PAN"}
        para={"PAN verification is compulsory in order to Invest"}
        fromLocation={fromLocation}
      >
        <Formik
          initialValues={{
            pan: localKycInfo?.pan || "",
          }}
          onSubmit={mutate}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            isValid,
          }) => {
            return (
              <Form2 onSubmit={handleSubmit}>
                {/* <DynamicTypeInput
                  structure={[
                    { type: "text", length: 5 },
                    { type: "number", length: 4 },
                    { type: "text", length: 1 },
                  ]}
                /> */}
                <InputField
                  label={"PAN"}
                  labelInputDirection="vertical"
                  autoFocus="true"
                  value={values.pan.toUpperCase()}
                  onChange={handleChange("pan")}
                  error={touched.pan && (errors.pan || errorState.pan)}
                  noSpin
                />
                <Button
                  title={fromLocation ? "EDIT" : "SUBMIT"}
                  type={"submit"}
                  isLoading={isLoading}
                  error={errorState.general}
                />
              </Form2>
            );
          }}
        </Formik>
      </RegistrationCapsule>
    </>
  );
};

export default VerifyPan;
