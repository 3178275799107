import { useState, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import AnimatedRoutes from "AnimatedRoutes";
import NavBar from "components/global/NavBar/NavBar";
import Alert from "components/common/Alert/Alert";
import ModalPopup from "components/common/ModalPopup/ModalPopup";
import MobileNav from "components/common/MobileNav/MobileNav";
import useScrollLock from "hooks/useScrollLock";

import GlobalStyles from "styles/global-styles";
import {
  HeightCont,
  StyledContainer,
  StyledPadding,
} from "styles/common-styled-components";
import { darkTheme, lightTheme } from "styles/theme";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import {
  addKycInfo,
  addNextRegistrationScreen,
  addUserDetails,
} from "redux/user/user-slice";
import {
  getNextRegistrationScreen,
  mapServerErrorsToLocal,
} from "utils/functions";
import WiseBullLoader from "components/common/WiseBullsLoader/WiseBullLoader";
import { NavBarProvider } from "components/global/NavBar/NavBarContext";

export const AlertContext = createContext();

// TEST 2
const App = () => {
  const initialErrorState = { general: "" };

  const location = useLocation();
  const dispatch = useDispatch();
  const { lockScroll, unlockScroll } = useScrollLock();
  const theme = useSelector((state) => state.theme.mode);
  const user = useSelector((state) => state.user);

  // For Alerts and Modals
  const [alertOpen, setAlertOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(
    "Task completed successfully"
  );
  const [errors, setErrors] = useState(initialErrorState);

  const [fetchingUserData, setFetchingUserData] = useState(true);
  const [modalObj, setModalObj] = useState({ comp: <></>, style: {} });

  //// INIT API CALLS
  const handleInit = async () => {
    try {
      sessionStorage.setItem("auth-checked", true);

      // Fetch user info
      const userInfoResponse = await credentialAxios.get(
        API_ENDPOINTS.getUserInfo
      );

      console.log(`------ Setting user-info for auth ------`);
      dispatch(addUserDetails(userInfoResponse.data));

      // Fetch KYC info
      const kycInfoResponse = await credentialAxios.get(
        API_ENDPOINTS.getKycData
      );

      dispatch(addKycInfo(kycInfoResponse.data));

      const nextRegistrationScreen = getNextRegistrationScreen(
        userInfoResponse.data,
        kycInfoResponse.data
      );
      console.log(nextRegistrationScreen);

      dispatch(addNextRegistrationScreen(nextRegistrationScreen));
    } catch (error) {
      console.log(error);
      const newErrors = mapServerErrorsToLocal(error, initialErrorState);
      setErrors(newErrors);
    } finally {
      console.log("Finally block");
      setFetchingUserData(false);
    }
  };
  //// INTI - END

  const showMessage = (obj) => {
    setAlertMessage(obj);
    setAlertOpen(true);
  };

  const openModal = (obj) => {
    setModalObj(obj);
    setModalOpen(true);
  };

  const closeMessage = () => {
    setAlertOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    lockScroll();
    unlockScroll();
    if (!sessionStorage.getItem("auth-checked") || !user?.userDetails) {
      handleInit();
    } else {
      setFetchingUserData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="content">
      <Alert
        message={alertMessage}
        open={alertOpen}
        closeMessage={closeMessage}
        autoHideDuration={3000}
      />

      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        {location.pathname === "/" ||
        location.pathname === "/search" ||
        location.pathname.includes("regist") ||
        location.pathname.includes("login") ||
        location.pathname.includes("waiting-for-link") ? (
          <></>
        ) : (
          <MobileNav heightBelow="0" />
        )}

        <GlobalStyles />
        {fetchingUserData ? (
          <WiseBullLoader />
        ) : (
          <NavBarProvider>
            <AlertContext.Provider
              value={{ showMessage, closeMessage, openModal, closeModal }}
            >
              {location.pathname === "/login" ||
              location.pathname === "/register" ||
              location.pathname.includes("/registration/") ||
              location.pathname === "/payment-web" ? (
                <></>
              ) : (
                <NavBar />
              )}

              {location.pathname === "/login" ||
              location.pathname === "/register" ? (
                <></>
              ) : (
                <HeightCont />
              )}
              <StyledContainer>
                <StyledPadding
                  noPadding={
                    location.pathname.includes("/registration/") ||
                    location.pathname === "/" ||
                    location.pathname === ""
                  }
                >
                  <AnimatedRoutes />
                </StyledPadding>
              </StyledContainer>

              <ModalPopup
                show={modalOpen}
                style={modalObj.style}
                onClose={() => setModalOpen(false)}
                animationStyle={modalObj.animationStyle}
              >
                {modalObj.comp}
              </ModalPopup>
            </AlertContext.Provider>
          </NavBarProvider>
        )}
      </ThemeProvider>
    </div>
  );
};

export default App;
