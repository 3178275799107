import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import Button from "components/ui/Button/Button";
import InputField from "components/ui/InputField/InputField";
import { mapServerErrorsToLocal } from "utils/functions";
import { API_ENDPOINTS, REGULAR_EXPRESSIONS } from "utils/constants";

import { Form2 } from "styles/common-styled-components";
import { useSelector } from "react-redux";
import { credentialAxios } from "utils/api-calls";

const AddPhoneNumber = () => {
  const { kycInfo: localKycInfo } = useSelector((state) => state.user);
  const { state } = useLocation("");

  const fromLocation = state?.fromLocation;

  const initialErrorState = { phone_number: "", general: "" };

  const navigate = useNavigate();

  const [errorState, setErrorState] = useState(initialErrorState);

  const validationSchema = Yup.object().shape({
    phone_number: Yup.string()
      .matches(REGULAR_EXPRESSIONS.phone_number, "Phone number is not valid")
      .required("Phone number is required"),
  });

  //// API CALL

  const handleAddPhoneNumber = ({ phone_number }) => {
    return credentialAxios.post(API_ENDPOINTS.addPhoneNumber, {
      phone_number,
      fromLocation,
    });
  };

  const onSuccess = (res) => {
    setErrorState(initialErrorState);
    if (res?.data?.otp_sent) {
      navigate("/registration/otp-verification", {
        state: { phone_number: res.data.phone_number, fromLocation },
      });
    }
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState, [
      "phone_number",
    ]);
    setErrorState(newErrors);
  };

  const { mutate, isLoading } = useMutation(handleAddPhoneNumber, {
    onSuccess,
    onError,
  });

  //// END API CALL

  return (
    <>
      <RegistrationCapsule
        heading={`${
          fromLocation
            ? "Edit your phone number"
            : "Add your phone number to WiseBulls."
        } `}
        para={
          "A OTP(One Time Password) will be sent to your phone number for verification."
        }
        fromLocation={fromLocation}
      >
        <Formik
          initialValues={{
            phone_number:
              Number(`${localKycInfo?.phonenumber}`.slice(3, 13)) || "",
          }}
          onSubmit={mutate}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            isValid,
          }) => {
            return (
              <Form2 onSubmit={handleSubmit}>
                <InputField
                  label={"Phone Number"}
                  labelInputDirection="vertical"
                  autoFocus="true"
                  placeholder="1234 5678 90"
                  type="number"
                  value={values.phone_number}
                  onChange={handleChange("phone_number")}
                  appendLeft={<>+91</>}
                  error={
                    touched.phone_number &&
                    (errors.phone_number || errorState.phone_number)
                  }
                  noSpin
                />
                <Button
                  title={"SEND OTP"}
                  type={"submit"}
                  isLoading={isLoading}
                  error={errorState.general}
                />
              </Form2>
            );
          }}
        </Formik>
      </RegistrationCapsule>
    </>
  );
};

export default AddPhoneNumber;
