import React from "react";

import { InputWrapper } from "components/ui/InputField/InputField.styles";
import { ErrorMessage, Label } from "styles/common-styled-components";
import { Container, Options, Option } from "./OptionSelector.styles";
const OptionSelector = ({ options, label, onSelect, value, error }) => {
  return (
    <div>
      <InputWrapper>
        <Label>{label}</Label>
        <Container>
          <Options>
            {options.map((option, i) => {
              return (
                <Option
                  key={i}
                  selectedStyling={option.value === value ? true : false}
                  onClick={() => {
                    onSelect(option.value);
                  }}
                >
                  {option.label}
                </Option>
              );
            })}
          </Options>
        </Container>
      </InputWrapper>
      {error && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ErrorMessage show={error} style={{ flex: 2 }}>
            {error}
          </ErrorMessage>
        </div>
      )}
    </div>
  );
};

export default OptionSelector;
