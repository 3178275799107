import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import InnerHTML from "dangerously-set-html-content";

const PaymentWeb = () => {
  const location = useLocation();
  const [html, setHTML] = useState({ __html: location.state.html });

  return <InnerHTML html={html.__html} />;
};

export default PaymentWeb;
