import { Skeleton } from "@mui/material";
import {
  StyledAlignCenter,
  StyledSpaceBetweenAlignCenter,
} from "styles/common-styled-components";

export const LeftContSkeleton = () => {
  return (
    <div>
      <StyledSpaceBetweenAlignCenter style={{ gap: "2rem", flexWrap: "wrap" }}>
        <Skeleton variant="text" style={{ flex: 8, fontSize: "4.8rem" }} />
        <Skeleton variant="text" style={{ flex: 2, fontSize: "4.8rem" }} />
      </StyledSpaceBetweenAlignCenter>
      <StyledAlignCenter style={{ gap: "2rem", flexWrap: "wrap" }}>
        <Skeleton
          variant="text"
          width={"12rem"}
          style={{
            marginBottom: "1.4rem",
            fontSize: "4rem",
          }}
        />
        <Skeleton
          variant="text"
          width={"12rem"}
          style={{
            marginBottom: "1.4rem",

            fontSize: "4rem",
          }}
        />
        <Skeleton
          variant="text"
          width={"12rem"}
          style={{
            marginBottom: "1.4rem",

            fontSize: "4rem",
          }}
        />
      </StyledAlignCenter>
      <div style={{ height: "60rem" }}>
        <Skeleton
          style={{ height: "100%", width: "100%", transformOrigin: "top" }}
        />
      </div>
    </div>
  );
};

export const RightContSkeleton = () => {
  return (
    <Skeleton variant="rounded" style={{ height: "35rem", width: "35rem" }} />
  );
};
