import { AlertContext } from "App";
import LoginModalContent from "components/common/LoginRegisterContent/LoginModalContent";
import Button from "components/ui/Button/Button";
import { CompleteSetupCard } from "pages/Explore/Explore";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledAlignCenter } from "styles/common-styled-components";

const MobileInvestButtons = ({ data, noSip }) => {
  const { state: editStateFromPaymentMethod } = useLocation();

  const location = useLocation();
  const { openModal, closeModal } = useContext(AlertContext);

  const { currentUser, nextRegistrationScreen } = useSelector(
    (state) => state.user
  );

  const checkUserState = () => {
    if (!currentUser) {
      openModal({
        comp: (
          <LoginModalContent
            closeModal={closeModal}
            fromLocation={location.pathname}
          />
        ),
        style: { maxHeight: "75vh" },
      });
      return false;
    }
    if (nextRegistrationScreen) {
      openModal({
        comp: (
          <CompleteSetupCard
            showClose
            style={{ boxShadow: "none", width: "100%" }}
            navigate={() => {
              closeModal();
              navigate(nextRegistrationScreen);
            }}
            closeModal={closeModal}
          />
        ),
        style: {
          maxHeight: "75vh",
          width: "30rem",
          minWidth: "30rem",
        },
      });
      return false;
    }
    return true;
  };
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        padding: "1rem",
        paddingBottom: "2rem",
        boxShadow:
          "rgba(50, 50, 93, 0.75) 0px 2px 5px -1px, rgba(0, 0, 0, 0.75) 0px 1px 3px -1px",
        backgroundColor: "white",
        zIndex: 100,
      }}
    >
      <StyledAlignCenter>
        <Button
          title={"Lumpsum"}
          variant="outlined"
          onClick={() => {
            if (checkUserState()) {
              navigate(`/mutual-funds/invest`, {
                state: {
                  data,
                  investmentType: "lumpsum",
                },
              });
            }
          }}
        />
        {noSip ? null : (
          <Button
            title={"SIP"}
            onClick={() => {
              if (checkUserState()) {
                navigate(`/mutual-funds/invest`, {
                  state: {
                    data,
                    investmentType: "sip",
                  },
                });
              }
            }}
          />
        )}
      </StyledAlignCenter>
    </div>
  );
};

export default MobileInvestButtons;
