import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AutocompleteSearch from "components/ui/Autocomplete/Autocomplete";
import Button from "components/ui/Button/Button";
import DataRenderer from "components/utility/DataRenderer";
import { API_ENDPOINTS } from "utils/constants";

import { ErrorMessage, Label } from "styles/common-styled-components";

const SelectBranchForm = ({ bankId, bankName }) => {
  const navigate = useNavigate();

  const location = useLocation();

  const [bankDetails, setBankDetails] = useState(null);
  const [error, setError] = useState("");

  return (
    <div>
      <DataRenderer
        apiEndpoint={API_ENDPOINTS.getBranches(bankId)}
        queryOptions={{ staleTime: 0 }}
        queryName={"branches"}
        renderData={(branchData) => {
          return (
            <>
              <Label>{`Select your Branch of ${bankName}`}</Label>
              <AutocompleteSearch
                options={branchData.data}
                onChange={(details) => setBankDetails(details)}
                value={bankDetails?.ifsc_code}
                labelMapper="branch_name"
                valueMapper="ifsc_code"
              />
              <ErrorMessage show={error}>{error}</ErrorMessage>
              <Button
                title={"CONTINUE"}
                onClick={() => {
                  if (!bankDetails?.ifsc_code) {
                    setError("Please select a Branch to continue");
                    return;
                  }
                  navigate("/registration/add-bank", {
                    state: {
                      ...bankDetails,
                      nonReg: location.state?.nonReg,
                      fromLocation: location.state?.fromLocation,
                    },
                  });
                }}
              />
            </>
          );
        }}
      />
    </div>
  );
};

export default SelectBranchForm;
