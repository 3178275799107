import styled from "styled-components";

export const Container = styled.div`
  color: ${({ theme }) => theme.errorTextColor};
  font-size: 1.2rem;
  text-align: ${({ variant }) =>
    variant === "background" ? "center" : "start"};
  background-color: ${({ variant }) =>
    variant === "background" ? "var(--errorBgColor)" : "transparent"};
  padding: ${({ variant }) => (variant === "background" ? "1rem" : "0")};
  border-radius: ${({ variant }) => (variant === "background" ? "4px" : "0px")};
  font-weight: bold;
  display: ${({ show }) => (show ? "flex" : "none")};
  margin-top: 6px;
  gap: 6px;
  align-items: center;
`;
