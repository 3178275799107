import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import Button from "components/ui/Button/Button";
import InputField from "components/ui/InputField/InputField";
import { credentialAxios, publicAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import {
  getNextRegistrationScreen,
  mapServerErrorsToLocal,
} from "utils/functions";
import {
  addKycInfo,
  addNextRegistrationScreen,
  addUserDetails,
} from "redux/user/user-slice";

import {
  Form2,
  HeadingPrimaryBold,
  Paragraph,
  StyledLink,
  UnderLineButton,
} from "styles/common-styled-components";
import { addUser } from "redux/user/user-slice";

const LoginForm = ({ closeModal = () => {}, fromLocation = "/" }) => {
  const formikRef = useRef(null);
  const initialErrorState = { email: "", password: "", general: "" };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password too short! Must be at least 8 characters."),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errors, setErrors] = useState(initialErrorState);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  // API CALLS

  const handleLogin = async (payload) => {
    try {
      setIsLoggingIn(true);

      const response = await publicAxios.post(API_ENDPOINTS.loginTokenRequest, {
        email: payload.email,
        password: payload.password,
      });

      setErrors(initialErrorState);
      console.log(`------ Login success: Setting auth-checked to True ------`);
      sessionStorage.setItem("auth-checked", true);
      dispatch(addUser(response.data.token));

      // Fetch user info
      const userInfoResponse = await credentialAxios.get(
        API_ENDPOINTS.getUserInfo
      );

      console.log(`------ Setting user-info for auth ------`);
      dispatch(addUserDetails(userInfoResponse.data));

      // Fetch KYC info
      const kycInfoResponse = await credentialAxios.get(
        API_ENDPOINTS.getKycData
      );

      dispatch(addKycInfo(kycInfoResponse.data));

      const nextRegistrationScreen = getNextRegistrationScreen(
        userInfoResponse.data,
        kycInfoResponse.data
      );
      dispatch(addNextRegistrationScreen(nextRegistrationScreen));

      if (nextRegistrationScreen) {
        navigate(nextRegistrationScreen, { replace: true });
        window.location.reload();
      } else {
        navigate(fromLocation, { replace: true });
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      setIsLoggingIn(false);
      const newErrors = mapServerErrorsToLocal(error, initialErrorState, [
        "email",
        "password",
      ]);
      setErrors(newErrors);
    } finally {
      console.log("Finally block");
    }
  };

  ///// API CALL END

  return (
    <>
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={handleLogin}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          errors: formikErrors,
          touched,
        }) => {
          return (
            <Form2
              style={{ padding: 0, margin: "1rem 0" }}
              onSubmit={handleSubmit}
            >
              <InputField
                label={"Email"}
                placeholder={"johndoe@example.com"}
                value={values.email}
                onChange={handleChange("email")}
                autoFocus
                error={(touched.email && formikErrors.email) || errors.email}
                labelInputDirection="vertical"
              />
              <InputField
                labelInputDirection="vertical"
                label={"Password"}
                value={values.password}
                onChange={handleChange("password")}
                passwordField
                error={
                  (touched.password && formikErrors.password) || errors.password
                }
              />
              <Button
                type="submit"
                title="LOGIN"
                isLoading={isLoggingIn}
                loadingText=""
                contStyle={{ marginBottom: 0 }}
                error={errors.general}
              />
            </Form2>
          );
        }}
      </Formik>
      <div
        style={{
          margin: "1rem 0",
          marginBottom: "2rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <StyledLink to={"/forgot-password"} onClick={closeModal}>
          <UnderLineButton>Forgot Password?</UnderLineButton>
        </StyledLink>
      </div>
    </>
  );
};

export default LoginForm;
