import React from "react";

import ListRenderer from "components/utility/ListRenderer";

const Table = ({ data, style }) => {
  return (
    <table style={style}>
      <tbody>
        <ListRenderer
          items={Object.entries(data)}
          renderItem={([heading, value]) => (
            <tr key={heading}>
              <td
                style={{
                  fontWeight: "600",
                  paddingRight: "3rem",
                  paddingBottom: "1rem",
                }}
              >
                {heading}
              </td>
              <td
                style={{
                  paddingBottom: "1rem",
                }}
              >
                {value}
              </td>
            </tr>
          )}
        />
      </tbody>
    </table>
  );
};

export default Table;
