import React, { createContext, useContext, useState } from "react";

const NavBarContext = createContext();

export const NavBarProvider = ({ children }) => {
  const [isNavBarVisible, setIsNavBarVisible] = useState(true);

  const showNavBar = () => setIsNavBarVisible(true);
  const hideNavBar = () => setIsNavBarVisible(false);

  return (
    <NavBarContext.Provider value={{ isNavBarVisible, showNavBar, hideNavBar }}>
      {children}
    </NavBarContext.Provider>
  );
};

export const useNavBar = () => useContext(NavBarContext);
