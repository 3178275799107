import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import forgotPasswordImg from "assets/banner/Forgot-password-pana.svg";

import {
  HeadingPrimaryBold,
  Form,
  MobileView,
  CloseButton,
} from "styles/common-styled-components";
import Button from "components/ui/Button/Button";
import InputField from "components/ui/InputField/InputField";

import { StyledCentering } from "styles/common-styled-components";
import { useContext } from "react";
import { AlertContext } from "App";
import { useMutation } from "react-query";
import { publicAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import useDocumentTitle from "hooks/useDocumentTitle";
import { BannerFormComp } from "./ForgotPassword.styles";
import { mapServerErrorsToLocal } from "utils/functions";
import MobileNav from "components/common/MobileNav/MobileNav";

// /api/user/password_reset  email:""
// /api/user/password_reset/confirm {"token":"", "password":""}

const ForgotPassword = () => {
  const initialErrorState = {
    email: "",
    general: "",
  };

  useDocumentTitle("Forgot Password - WiseBulls");
  const { openModal, closeModal } = useContext(AlertContext);

  const [errorsState, setErrorsState] = useState(initialErrorState);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required"),
  });

  const handleSendResetPasswordLink = (values) => {
    return publicAxios.post(API_ENDPOINTS.sendResetPasswordLink, {
      email: values.email,
    });
  };

  const onSuccess = (res) => {
    setErrorsState(initialErrorState);
    if (res.data.status === "OK") {
      openModal({
        comp: (
          <div style={{ margin: "2rem", marginTop: "4rem" }}>
            <CloseButton onClick={closeModal} />
            <StyledCentering style={{ flexDirection: "column" }}>
              Reset password link has successfully been sent to your email
            </StyledCentering>
          </div>
        ),
      });
    }
  };
  const onError = (error) => {
    const newErrors = mapServerErrorsToLocal(error, initialErrorState, [
      "email",
    ]);
    setErrorsState(newErrors);
  };

  const { mutate, isLoading } = useMutation(handleSendResetPasswordLink, {
    onSuccess,
    onError,
  });

  return (
    <StyledCentering>
      <BannerFormComp
        leftCont={
          <div>
            <MobileNav headerTitle={"Forgot Password"} />
            <MobileView>
              <div style={{ height: "6rem" }} />
            </MobileView>
            <img src={forgotPasswordImg} alt="Forgot Password" />
          </div>
        }
        rightCont={
          <div style={{ width: "100%" }}>
            <HeadingPrimaryBold
              style={{ display: "inline-block", marginBottom: "1rem" }}
            >
              Forgot Password?
            </HeadingPrimaryBold>
            <p style={{ fontSize: "1.6rem", marginBottom: "3rem" }}>
              Don't worry it happens, enter your associated email address
            </p>

            <Formik
              initialValues={{ email: "" }}
              onSubmit={(values, errors) => {
                mutate(values, errors);
              }}
              validationSchema={validationSchema}
            >
              {({ values, errors, touched, handleSubmit, handleChange }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <InputField
                      label={"Email address*"}
                      placeholder="Enter your email here"
                      onChange={handleChange("email")}
                      value={values.email}
                      error={
                        (touched.email && errors.email) || errorsState.email
                      }
                      autoFocus
                    />

                    <Button
                      title={"SUBMIT"}
                      type="submit"
                      style={{ width: "100%" }}
                      isLoading={isLoading}
                      error={errors.general}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
        }
      />
    </StyledCentering>
  );
};

export default ForgotPassword;
