import React from "react";
import SearchMutualFunds from "components/global/SearchMutualFunds/SearchMutualFunds";
import useDocumentTitle from "hooks/useDocumentTitle";

const Search = () => {
  useDocumentTitle("Search Mutual Funds - WiseBulls");
  return (
    <div>
      <SearchMutualFunds isMobileSearch={true} />
    </div>
  );
};

export default Search;
