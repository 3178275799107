import React from "react";
import {
  StyledCentering,
  StyledLink,
  SubHeading,
} from "styles/common-styled-components";
import Button from "components/common/Button/Button";
import Lottie from "lottie-react";
import paymentRejectedAnimation from "assets/json/14651-error-animation.json";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import paymentSuccessAnimation from "assets/json/payment-succes.json";
import AccordionComp from "components/ui/AccordionComp/AccordionComp";
import Table from "components/ui/Table/Table";
import MiniLoader from "components/common/MiniLoader/MiniLoader";
import { addUserDetails } from "redux/user/user-slice";

const MandateStatus = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  // GETTING USER DETAILS

  const getUserDetails = () => {
    return credentialAxios.get(API_ENDPOINTS.getUserInfo);
  };

  const onSuccess = (res) => {
    dispatch(addUserDetails(res.data));
  };

  const { isLoading: isLoadingUserData } = useQuery(
    "userData",
    getUserDetails,
    {
      onSuccess,
    }
  );

  const handlePostMandateStatus = () => {
    const data = {
      status: searchParams.get("status"),
      digio_doc_id: searchParams.get("digio_doc_id"),
      message: searchParams.get("message"),
      npci_txn_id: searchParams.get("npci_txn_id"),
    };

    return credentialAxios.post(API_ENDPOINTS.postMandateStatus, data);
  };

  const { mutate, isLoading } = useMutation(handlePostMandateStatus);

  const status = searchParams.get("status") || "N/A";
  const digio_doc_id = searchParams.get("digio_doc_id") || "N/A";
  const message = searchParams.get("message") || "N/A";
  const npci_txn_id = searchParams.get("npci_txn_id") || "N/A";

  useEffect(() => {
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || isLoadingUserData) {
    return <MiniLoader />;
  } else {
    return (
      <div>
        {searchParams.get("status") === "success" ? (
          <>
            <StyledCentering style={{ width: "100%", position: "relative" }}>
              <Lottie
                animationData={paymentSuccessAnimation}
                loop={false}
                style={{
                  width: window.innerWidth < 480 ? "100%" : "40rem",
                }}
              />
              <div style={{ position: "absolute", bottom: 0 }}>
                <SubHeading style={{ textAlign: "center" }}>
                  Completed Mandate successfully.
                </SubHeading>
                <StyledCentering style={{ width: "100%" }}>
                  <StyledLink to="/bank-details">
                    <Button
                      name={"GO TO BANK DETAILS"}
                      style={{ width: "100%" }}
                    />
                  </StyledLink>
                </StyledCentering>
              </div>
            </StyledCentering>
          </>
        ) : (
          <>
            <StyledCentering style={{ width: "100%", position: "relative" }}>
              <Lottie
                animationData={paymentRejectedAnimation}
                loop={false}
                style={{
                  width: window.innerWidth < 480 ? "100%" : "40rem",
                }}
              />
              <div style={{ position: "absolute", bottom: 0 }}>
                <SubHeading style={{ textAlign: "center" }}>
                  Something went wrong
                </SubHeading>
                <SubHeading style={{ textAlign: "center" }}>
                  Completing Mandate Failed
                </SubHeading>
              </div>
            </StyledCentering>
          </>
        )}
        <StyledCentering style={{ marginTop: "20px" }}>
          <AccordionComp
            title={"See Details"}
            content={
              <Table
                style={{}}
                data={{
                  Status: status,
                  Message: message,
                  "Digio Doc ID": digio_doc_id,
                  "NPCI TXN ID": npci_txn_id,
                }}
              />
            }
          />
        </StyledCentering>
      </div>
    );
  }
};

export default MandateStatus;
