import React, { useContext, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { AlertContext } from "App.js";

import {
  Form,
  HeadingPrimaryBold,
  MobileView,
  StyledCentering,
  CloseButton,
  StyledLink,
} from "styles/common-styled-components";
import InputField from "components/ui/InputField/InputField";
import Button from "components/ui/Button/Button";
import { AlignCenter } from "styles/common-styles";
import styled from "styled-components";

import resetPasswordImg from "assets/banner/Reset-password-bro.svg";
import MobileNav from "components/common/MobileNav/MobileNav";
import { publicAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import useDocumentTitle from "hooks/useDocumentTitle.js";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { mapServerErrorsToLocal } from "utils/functions";
import { BannerFormComp } from "pages/ForgotPassword/ForgotPassword.styles";

const ResetPassword = () => {
  const initialErrorState = { password: "", general: "" };

  useDocumentTitle("Reset Password - WiseBulls");
  const { openModal, closeModal } = useContext(AlertContext);

  const [searchParams] = useSearchParams();
  const [errors, setErrors] = useState(initialErrorState);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password too short! Must be at least 8 characters."),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Confirm Password too short! Must be at least 8 characters.")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const handleResetPassword = (values) => {
    return publicAxios.post(API_ENDPOINTS.confirmResetToken, {
      token: searchParams.get("token"),
      password: values.password,
    });
  };

  const onSuccess = (res) => {
    setErrors(initialErrorState);

    if (res.data.status === "OK") {
      openModal({
        comp: (
          <div style={{ margin: "2rem", marginTop: "4rem" }}>
            <CloseButton onClick={closeModal} />
            <StyledCentering style={{ flexDirection: "column" }}>
              <p>Your password reset is successful</p>
              <StyledLink to="/login" onClick={closeModal}>
                <Button title="LOGIN" />
              </StyledLink>
            </StyledCentering>
          </div>
        ),
      });
    }
  };
  const onError = (error) => {
    const newErrors = mapServerErrorsToLocal(error, initialErrorState, [
      "password",
    ]);
    setErrors(newErrors);
  };
  const { mutate, isLoading } = useMutation(handleResetPassword, {
    onSuccess,
    onError,
  });

  return (
    <StyledCentering>
      <BannerFormComp
        leftCont={
          <div>
            <MobileNav headerTitle={"Reset Password"} />
            <MobileView>
              <div style={{ height: "6rem" }} />
            </MobileView>
            <img src={resetPasswordImg} alt="Resetting assword" />
          </div>
        }
        rightCont={
          <div style={{ width: "100%" }}>
            <HeadingPrimaryBold
              style={{ display: "block", marginBottom: "1rem" }}
            >
              Reset Password
            </HeadingPrimaryBold>
            <p
              style={{
                fontSize: "1.6rem",
                marginBottom: "3rem",
              }}
            >
              Strong password suggested
            </p>
            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              onSubmit={(values, errors) => {
                mutate(values, errors);
              }}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors: formikErrors,
                handleSubmit,
                handleChange,
                touched,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <InputField
                      label="Password*"
                      type="password"
                      onChange={handleChange("password")}
                      value={values.password}
                      autoComplete="off"
                      error={
                        (touched.password && formikErrors.password) ||
                        errors.password
                      }
                    />
                    <InputField
                      label="Confirm Password*"
                      type="password"
                      onChange={handleChange("confirmPassword")}
                      value={values.confirmPassword}
                      autoComplete="off"
                      error={
                        touched.confirmPassword && formikErrors.confirmPassword
                      }
                    />

                    <Button
                      style={{ width: "100%" }}
                      title="Reset Password"
                      type="submit"
                      isLoading={isLoading}
                      error={errors.general}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
        }
      />
    </StyledCentering>
  );
};

export default ResetPassword;

const Cont = styled.div`
  ${AlignCenter}
  gap:2rem;
  @media (max-width: 980px) {
    flex-direction: column;
  }
`;
const BannerImage = styled(LazyLoadImage)`
  width: 120%;
  height: auto;

  @media (min-width: 990px) {
    width: 100%;
  }
`;
