import React, { useState } from "react";
import styled from "styled-components/macro";
import { centering } from "styles/common-styles";

const _Allocater = ({ data, nameMapper, valueMapper }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const COLORS = [
    "var(--pumpkin)",
    "var(--delft-blue)",
    "var(--cardinal)",
    "var(--electric-indigo)",
    "var(--tufts-blue)",
    "var(--icterine)",
    "var(--atomic-tangerine)",
    "var(--red-crayola)",
    "var(--brown-sugar)",
    "var(--saffron)",
    "var(--midnight-green)",
    "var(--skobelof)",
    "var(--dun)",
    "var(--falu-red)",
    "var(--antiflash-white)",
  ];

  // Sort the data by the highest value
  const sortedData = [...data].sort((a, b) => b[valueMapper] - a[valueMapper]);

  return (
    <div style={{ margin: "4rem 0" }}>
      <LabelsCont>
        {sortedData.map((curr, i) => {
          return (
            <LabelCont
              key={`label-${i}`}
              onMouseEnter={() => setHoveredIndex(i)}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{
                opacity: hoveredIndex !== null && hoveredIndex !== i ? 0.8 : 1,
              }}
            >
              <div
                style={{
                  backgroundColor: COLORS[i],
                  width: "12px",
                  height: "12px",
                  borderRadius: "6px",
                }}
              />
              {curr[nameMapper]}({Number(curr[valueMapper]).toFixed(2)}%)
            </LabelCont>
          );
        })}
      </LabelsCont>
      <Bar>
        {sortedData.map((curr, i) => {
          return (
            <SubCont
              key={`sub-${i}`}
              className="sub-cont"
              style={{
                backgroundColor: COLORS[i],
                width: `${curr[valueMapper]}%`,
                opacity: hoveredIndex !== null && hoveredIndex !== i ? 0.5 : 1,
              }}
              onMouseEnter={() => setHoveredIndex(i)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              {/* {Number(curr[valueMapper]).toFixed(2)}% */}
            </SubCont>
          );
        })}
      </Bar>
    </div>
  );
};

export default _Allocater;

const Bar = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
`;

const LabelsCont = styled.div`
  ${centering}
  gap: 28px;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const LabelCont = styled.div`
  ${centering}
  gap: 4px;
`;

const SubCont = styled.div`
  height: 100%;
  color: white;
  ${centering}
  transition: opacity 0.3s ease;
`;
