import React from "react";
import styled from "@emotion/styled";
import Slide from "./Slide";
import PropTypes from "prop-types";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { css } from "@emotion/react";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60%;

  @media (max-width: 600px) {
    height: auto;
  }
`;

const NavigationButtons = styled.div`
  position: relative;
  display: flex;

  height: 60px;
  margin: 0 auto;
  width: 20%;
  margin-top: 1rem;
  justify-content: space-between;
  z-index: 1000;

  @media (max-width: 1000px) {
    width: 30%;
  }

  @media (max-width: 900px) {
    width: 40%;
  }
  @media (max-width: 700px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    display: none;
  }
`;

const left = css`
  left: -20px;
`;

const right = css`
  right: -20px;
`;

const NavBtn = styled.div`
  background: white;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 3px;
  @media (max-width: 600px) {
    padding: 10px;
    position: absolute;
    ${({ side }) => (side === "left" ? left : right)}
  }
`;

function mod(a, b) {
  return ((a % b) + b) % b;
}

class VerticalCarousel extends React.Component {
  state = {
    index: 0,
    goToSlide: null,
    prevPropsGoToSlide: 0,
    newSlide: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.goToSlide !== prevProps.goToSlide) {
      this.setState({
        index: this.modBySlidesLength(this.props.goToSlide),
        goToSlide: this.props.goToSlide,
      });
    }
  }
  componentDidMount() {
    const { goToSlide } = this.props;

    if (goToSlide !== undefined && goToSlide !== null) {
      this.setState({
        index: this.modBySlidesLength(goToSlide),
        goToSlide: goToSlide,
      });
    }
  }

  static propTypes = {
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any,
        content: PropTypes.object,
      })
    ).isRequired,
    goToSlide: PropTypes.number,
    showNavigation: PropTypes.bool,
    offsetRadius: PropTypes.number,
    animationConfig: PropTypes.object,
  };

  static defaultProps = {
    offsetRadius: 2,
    animationConfig: { tension: 120, friction: 14 },
  };

  modBySlidesLength = (index) => {
    return mod(index, this.props.slides.length);
  };

  moveSlide = (direction) => {
    this.setState({
      index: this.modBySlidesLength(this.state.index + direction),
      goToSlide: null,
    });
  };

  clampOffsetRadius(offsetRadius) {
    const { slides } = this.props;
    const upperBound = Math.floor((slides.length - 1) / 2);

    if (offsetRadius < 0) {
      return 0;
    }
    if (offsetRadius > upperBound) {
      return upperBound;
    }

    return offsetRadius;
  }

  getPresentableSlides() {
    const { slides } = this.props;
    const { index } = this.state;
    let { offsetRadius } = this.props;
    offsetRadius = this.clampOffsetRadius(offsetRadius);
    const presentableSlides = new Array();

    for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {
      presentableSlides.push(slides[this.modBySlidesLength(index + i)]);
    }

    return presentableSlides;
  }

  render() {
    const { animationConfig, offsetRadius, showNavigation } = this.props;

    let navigationButtons = null;
    if (showNavigation) {
      navigationButtons = (
        <NavigationButtons>
          <NavBtn side={"left"} onClick={() => this.moveSlide(-1)}>
            <ArrowUpward />
          </NavBtn>
          <NavBtn side={"right"} onClick={() => this.moveSlide(1)}>
            <ArrowDownward />
          </NavBtn>
        </NavigationButtons>
      );
    }
    return (
      <React.Fragment>
        <Wrapper>
          {this.getPresentableSlides().map((slide, presentableIndex) => (
            <Slide
              key={slide.key}
              content={slide.content}
              moveSlide={this.moveSlide}
              offsetRadius={this.clampOffsetRadius(offsetRadius)}
              index={presentableIndex}
              animationConfig={animationConfig}
            />
          ))}
        </Wrapper>
        {navigationButtons}
      </React.Fragment>
    );
  }
}

export default VerticalCarousel;
