import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useState } from "react";

const CategoryAccordion = ({ heading, content, contStyle }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      style={{
        width: "100%",
        ...contStyle,
      }}
    >
      <div
        onClick={toggleAccordion}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: "6px",
          padding: "1rem 2rem",
          width: "100%",
        }}
      >
        {isOpen ? (
          <ExpandLess style={{ fontSize: "2.4rem" }} />
        ) : (
          <ExpandMore style={{ fontSize: "2.4rem" }} />
        )}

        <p style={{ margin: 0, fontWeight: "bold", fontSize: "1.6rem" }}>
          {heading}
        </p>
      </div>
      {isOpen && (
        <div style={{ backgroundColor: "#fff" }}>
          <div>{content}</div>
        </div>
      )}
    </div>
  );
};

export default CategoryAccordion;
