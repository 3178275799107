import { ButtonBase } from "@mui/material";
import styled, { css } from "styled-components";
import { STYLE_VALUES } from "utils/constants";

const normal = css`
  background-color: var(--primary) !important;
  color: var(--light-grey) !important;
`;

const outlined = css`
  background: none !important;
  border: 1px solid var(--tertiaryLightGrey) !important;
`;

const danger = css`
  background-color: var(--danger) !important;
`;

const variants = {
  outlined,
  danger,
  normal,
};

export const StyledButtonBase = styled(ButtonBase)`
  width: 100% !important;
  padding: 10px 20px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  letter-spacing: 1px !important;
  max-width: ${STYLE_VALUES.basicCompWidth} !important;
  ${({ variant = "normal" }) => variants[variant]}
`;
