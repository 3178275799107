import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Container } from "./MobileNav.styles";
import { MobileView } from "styles/common-styled-components";
import { ButtonBase } from "@mui/material";

const MobileNav = ({ headerTitle, heightBelow = "6rem" }) => {
  const navigation = useNavigate();
  return (
    <>
      <Container>
        <ButtonBase
          onClick={() => navigation(-1)}
          style={{
            padding: "6px",
            backgroundColor: "#efefef",
            borderRadius: "4px",
          }}
        >
          <ArrowBack style={{ fontSize: "2rem" }} />
        </ButtonBase>

        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          style={{ fontSize: "1.6rem", fontWeight: "800" }}
        >
          {headerTitle}
        </motion.h2>
      </Container>
      <MobileView>
        <div style={{ height: heightBelow }} />
      </MobileView>
    </>
  );
};

export default MobileNav;
