import styled from "styled-components/macro";

import { Centering, SpaceBetweenAlignCenter } from "styles/common-styles";

export const TabsContainer = styled.div`
  ${SpaceBetweenAlignCenter}
  gap: 2rem;
`;

export const TabCont = styled.div`
  border-bottom: ${({ selectedStyle }) =>
    selectedStyle ? "4px solid var(--themeColor)" : "4px solid transparent"};

  color: ${({ selectedStyle }) =>
    selectedStyle ? "var(--themeColor)" : "var(--themeBluishGrey)"};

  padding: 1rem 0;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  ${Centering}

  @media (max-width:980px) {
    padding: 1rem 6px;
  }
`;
