import React from "react";
import { ExpandMore, ExpandLess,  } from "@mui/icons-material";
import { useState } from "react";
import styled from "styled-components"

import {
  Container,
  AccordionTop,
  Heading,
  OptionCheckBox,
  OptionTitle,
  Options,
  OptionLabel,
} from "./MultipleSelectAccordion.styles";
import InputField from "../InputField/InputField";

const StyledInput = styled(InputField)`
  height: 30px;
  border: none;
  border-bottom: 1px solid rgb(221, 221, 221);
  border-radius: 0;
  outline: none;
  padding-left: 0;
  box-shadow: none;

  &::placeholder {
    font-size: 1.3rem;
    color: rgb(150, 150, 150); 
  }

  &:focus {
    border: none;
    border-bottom: 2px solid rgb(32, 178, 170);
    outline: none;
  }
`;

const MultipleSelectAccordion = ({
  heading,
  options,
  handleChange,
  selectedOptions,
  showSearchBar,
  
}) => {
  const [open, setOpen] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredOptions = options.filter(option =>
    option.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container  >
      <AccordionTop onClick={() => setOpen(!open)}>
        <Heading>{heading}</Heading>
        
        {open ? (
          <ExpandLess style={{ fontSize: "2.4rem" }} />
        ) : (
          <ExpandMore style={{ fontSize: "2.4rem" }} />
        )}
        
      </AccordionTop>
      {showSearchBar && (
        <StyledInput placeholder={"Search for AMCs"} value={searchQuery}  onChange={handleSearchChange} 
          />
      )}

      {open && (
        <Options>
          {filteredOptions.map((option, index) => {
            return (
              option.name && (
                <OptionLabel key={index}>
                  <OptionCheckBox
                    checked={selectedOptions.includes(option.name)}
                    type={"checkbox"}
                    onChange={(e) => {
                      handleChange(e, option);
                    }}
                  />
                  <OptionTitle>{option.name}</OptionTitle>
                </OptionLabel>
              )
            );
          })}
        </Options>
      )}
    </Container>
  );
};


export default MultipleSelectAccordion;
