import styled, { css } from "styled-components/macro";
import {
  AlignCenter,
  SpaceBetween,
  SpaceBetweenAlignCenter,
  align_center,
} from "styles/common-styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { padding } from "styles/common-styled-components";

export const MainContainer = styled.div`
  ${SpaceBetween}
  gap: 6rem;

  @media (max-width: 980px) {
    padding: 0 1.4rem;
    flex-direction: column;
  }

  @media (max-width: 600px) {
    padding: 0 6px;
    flex-direction: column;
  }
`;

export const LeftCont = styled.div`
  flex: 4;
`;

export const RightCont = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const GraphDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ShareCont = styled.div`
  padding: 1rem 2rem;
  flex-wrap: wrap;
  ${SpaceBetweenAlignCenter}
  gap: 2rem;
`;

export const Categories = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const TagComp = styled.span`
  background-color: ${({ theme }) => theme.tagBackground};
  border-radius: 100px;

  padding: 1rem 2rem;
  font-size: 1.4rem;

  @media (max-width: 980px) {
    font-size: 1.4rem;
    padding: 1rem 1.5rem;
  }
`;

export const Category = styled.span`
  color: #999;
`;

export const GraphButtonsCont = styled.div`
  ${AlignCenter}
  gap:2rem;

  @media (max-width: 980px) {
    gap: 1rem;
    flex-wrap: wrap;
  }

  @media (max-width: 600px) {
    gap: 6px;
    flex-wrap: wrap;
  }
`;

export const GraphButton = styled.div`
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background-color: ${({ selectedStyle, theme }) =>
    selectedStyle ? "#20b2ab2c" : theme.tagBackground};
  color: ${({ selectedStyle }) => (selectedStyle ? "var(--themeColor)" : "")};
  border-radius: 50px;
  letter-spacing: 1px;
  cursor: pointer;
  &:hover {
    background-color: #20b2ab2c;
    color: var(--themeColor);
  }

  @media (max-width: 980px) {
    font-size: 1.2rem;
  }

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

export const UserFieldsCont = styled.div`
  flex: 3;
  width: 100%;
  position: relative;
`;

export const TabsContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
`;

export const Tab = styled.div`
  background-color: ${({ selectedStyle }) =>
    selectedStyle ? "var(--themeColor)" : null};

  color: ${({ selectedStyle }) => (selectedStyle ? "#eee" : null)};
  padding: 2rem 4rem;
  font-size: 1.6rem;

  border-radius: 100px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 980px) {
    padding: 2rem;
  }
`;

export const FieldsCont = styled.div`
  margin: 4rem 12rem;
  margin-right: 0;
  padding: 2rem 4rem;
  background-color: #f7f8fa;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  backdrop-filter: ${({ blurComp }) => (blurComp ? "blur(4px)" : 0)};
  @media (max-width: 980px) {
    margin: 0;
    padding: 1rem;
  }
`;

export const MutualFundNavBar = styled.div`
  ${padding}
  padding-top:1rem !important;
  padding-bottom: 1rem !important;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  z-index: 999;

  @media (max-width: 600px) {
  }
`;

const activeStyle = css`
  color: var(--themeColor);
  border-bottom: 2px solid var(--themeColor);
`;

export const ActiveLink = styled.p`
  border-bottom: 2px solid transparent;
  ${({ active }) => (active ? activeStyle : null)};
  transition: all 0.3s ease-in-out;
  &:hover {
    color: var(--themeBluishGrey);
    border-bottom: 2px solid var(--themeBluishGrey);
  }
`;

export const MutualFundNavBarMenu = styled.div`
  ${align_center}
  gap: 3.4rem;
  color: #ccc;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding-bottom: 6px;

  @media (max-width: 600px) {
    display: block;
    overflow: auto;
    display: none;
  }
`;

export const NameAndShare = styled.div`
  ${SpaceBetweenAlignCenter}
  gap: 1.6rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`;

export const ShareMainCont = styled.div`
  padding: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  gap: 3rem;
  ${SpaceBetweenAlignCenter}
`;
export const ShareIconCont = styled.div`
  ${AlignCenter};
  color: var(--themeColor);
  font-size: 1.8rem;
  cursor: pointer;
  font-weight: 600;
`;

export const DateInput = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.cardBackground};
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  font-size: 1.6rem;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  @media (max-width: 980px) {
    font-size: 1.2rem;
    div {
      gap: 2px;
    }
  }
`;

export const DateSelectorComp = styled.div`
  position: absolute;
  box-shadow: rgba(32, 178, 171, 0.2) 0px 7px 29px 0px;
  z-index: 10000;
  /* min-width: 40rem; */
  border-radius: 8px;
  background-color: ${({ theme }) => theme.background};
  @media (max-width: 980px) {
    width: 100%;
  }
`;

export const DateCompCont = styled.div`
  padding: 2rem;
  @media (max-width: 980px) {
    padding: 2px;
  }
`;

export const ValidationMessage = styled.p`
  color: #a30000;
  font-size: 1.2rem;
  text-align: center;
  background-color: rgba(163, 0, 0, 0.203);
  padding: 1rem;
  border-radius: 4px;
  font-weight: bold;
`;

export const ShareButton = styled.div`
  gap: 1rem;
  font-size: 1.6rem;
  color: var(--themeColor);
  border: 1px solid ${({ theme }) => theme.borderColor};
  padding: 6px;
  border-radius: 100px;
  cursor: pointer;
  ${SpaceBetweenAlignCenter}
  span {
    color: #fff;
    padding: 1rem 2rem;
    border-radius: 100px;
    border: 1px solid ${({ theme }) => theme.borderColor};
    background-color: var(--themeColor);
    font-weight: bold;
  }
`;

export const ShareLogo = styled(LazyLoadImage)`
  width: 4rem;
`;

export const DetailsCont = styled.div`
  background-color: ${({ theme }) => theme.cardBackground2};
  margin: 2rem 0;
  padding: 2rem;
  border-radius: 4px;
`;
