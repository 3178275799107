import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NoData from "pages/NoData/NoData";
import MiniLoader from "components/common/MiniLoader/MiniLoader";
import { StyledCentering } from "styles/common-styled-components";

const ProtectedRoute = (props) => {
  const userToken = useSelector((state) => state.user.currentUser);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkUserToken = () => {
    if (!userToken || userToken === "undefined") {
      // navigate("/");
      setTimeout(() => {
        setIsLoggedIn(false);
        setIsLoading(false);
      }, 1000);
      return;
    }

    setIsLoggedIn(true);
    setIsLoading(false);
  };

  useEffect(() => {
    checkUserToken();
  }, [userToken]);

  if (isLoading) {
    return (
      <StyledCentering>
        <MiniLoader />
      </StyledCentering>
    ); // Replace with a proper loading spinner or component
  }

  return (
    <>
      {!isLoggedIn ? (
        <NoData message={"Please login to access this page"} />
      ) : (
        props.children
      )}
    </>
  );
};

export default ProtectedRoute;
