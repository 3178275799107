import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";

import {
  StyledAlignCenter,
  StyledSpaceBetweenAlignCenter,
} from "styles/common-styled-components.js";
import Button from "components/ui/Button/Button";
import RadioComp from "components/ui/RadioComp/RadioComp.jsx";
import {
  Container,
  ContLeft,
  ContRight,
  OrderCard,
  OrdercardTop,
  PaymentCont,
} from "./Payment.styles.jsx";
import bhim from "assets/logos/upi.png";
import MobileNav from "components/common/MobileNav/MobileNav.jsx";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { credentialAxios } from "utils/api-calls.js";
import { API_ENDPOINTS, REGULAR_EXPRESSIONS } from "utils/constants.js";
import {
  convertNumberToCurrency,
  mapServerErrorsToLocal,
} from "utils/functions.js";
import useDocumentTitle from "hooks/useDocumentTitle.js";
import { useEffect } from "react";
import { useContext } from "react";
import { AlertContext } from "App.js";
import { ChevronLeft } from "@mui/icons-material";
import Select from "components/ui/Select/Select.jsx";
import InputField from "components/ui/InputField/InputField.jsx";
import Image from "components/ui/Image/Image.jsx";
import MandateAuthorizationPopup from "./MandateAuthorizationPopup.jsx";

const Payment = () => {
  const { state: accumulatedPayload } = useLocation();

  useDocumentTitle(
    `Payment for ${accumulatedPayload.name}${
      accumulatedPayload?.folio_number ? " - Additional Purchase" : null
    }`
  );
  const user = useSelector((state) => state.user.userDetails);

  const bank_options = user?.bankdetail?.map((curr) => {
    return {
      label: `${curr.bank_name} (${"X".repeat(
        curr.account_number.length - 4
      )}${curr.account_number.substr(curr.account_number.length - 4)})`,
      value: curr.id,
    };
  });

  let defaultBank = user.bankdetail.filter((curr) => curr.default);
  if (defaultBank.length === 0) {
    defaultBank = user.bankdetail;
  }

  const [selectedBankForPayment, setSelectedBankForPayment] = useState(
    defaultBank[0].id
  );

  const navigate = useNavigate();

  const [paymentOption, setPaymentOption] = useState("upi");

  const changeInvestmentDetails = () => {
    if (accumulatedPayload?.folio_number) {
      navigate(`/investments`, {
        state: {
          dateValue: parseInt(accumulatedPayload?.monthlySipDate),
          amount: +accumulatedPayload?.amount,
          investmentType: accumulatedPayload?.investmentType,
        },
      });
    } else {
      navigate(`/mutual-funds/${accumulatedPayload.id}`, {
        state: {
          dateValue: parseInt(accumulatedPayload.monthlySipDate),
          amount: +accumulatedPayload.amount,
          investmentType: accumulatedPayload.investmentType,
        },
      });
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Container>
      <MobileNav
        headerTitle={`${
          accumulatedPayload.investmentType === "sip" ? "SIP" : "LUMPSUM"
        } Investment`}
      />
      <ContLeft>
        <OrderCard>
          <OrdercardTop style={{}}>
            <p style={{ fontWeight: "600" }}>{accumulatedPayload.name}</p>
            {accumulatedPayload.folio_number ? (
              <p>(Additional Purchase)</p>
            ) : null}
          </OrdercardTop>

          <StyledSpaceBetweenAlignCenter style={{ padding: "0 2rem" }}>
            <p style={{ color: "#666" }}>Amount</p>
            <p style={{ fontWeight: "600" }}>
              {convertNumberToCurrency(Number(accumulatedPayload.amount))}
            </p>
          </StyledSpaceBetweenAlignCenter>
          <StyledSpaceBetweenAlignCenter style={{ padding: "0 2rem" }}>
            <p style={{ color: "#666" }}>Scheme type</p>
            <p style={{ fontWeight: "600" }}>
              {accumulatedPayload.investmentType === "sip" ? "SIP" : "LUMPSUM"}
            </p>
          </StyledSpaceBetweenAlignCenter>
          {accumulatedPayload.investmentType === "sip" ? (
            <StyledSpaceBetweenAlignCenter style={{ padding: "0 2rem" }}>
              <p style={{ color: "#666" }}>Monthly SIP Date</p>
              <p style={{ fontWeight: "600" }}>
                {accumulatedPayload.monthlySipDate}
              </p>
            </StyledSpaceBetweenAlignCenter>
          ) : null}
        </OrderCard>
        {accumulatedPayload.comingFromOrders ? null : (
          <StyledAlignCenter
            onClick={changeInvestmentDetails}
            style={{
              marginBottom: "1rem",
              color: "var(--secondary)",
              cursor: "pointer",
            }}
          >
            <ChevronLeft style={{ fontSize: "2.4rem" }} />
            <span>Change Details</span>
          </StyledAlignCenter>
        )}
      </ContLeft>

      <ContRight>
        <PaymentCont>
          <p style={{ fontSize: "2rem", fontWeight: "600" }}>Payment</p>
          <p style={{ marginBottom: "3rem" }}>
            {accumulatedPayload.comingFromOrders
              ? ""
              : "To confirm your order, we will send you an OTP on registered mobile number."}
          </p>
          {/* <p>
            Paying from{" "}
            {
              bank_options.filter(
                (curr) => curr.id === selectedBankForPayment
              )[0].label
            }
          </p> */}
          <Select
            options={bank_options}
            label={"Select Bank account"}
            value={selectedBankForPayment}
            onChange={setSelectedBankForPayment}
          />
          <MandateAuthorizationPopup
            selectedBankForPayment={selectedBankForPayment}
          />

          <p style={{ fontSize: "1.6rem", margin: "3rem 0" }}>Payment method</p>
          <RadioComp
            selected={paymentOption === "upi"}
            label={
              <StyledAlignCenter>
                <Image size={"2.4rem"} src={bhim} alt="upi logo" />
                <p>UPI</p>
              </StyledAlignCenter>
            }
            onChange={() => setPaymentOption("upi")}
          >
            <UPIForm selectedBankForPayment={selectedBankForPayment} />
          </RadioComp>
          <StyledAlignCenter
            style={{
              gap: "2rem",
              margin: "2rem 0",
            }}
          >
            <div
              style={{
                height: "1px",
                backgroundColor: "#efefef",
                width: "100%",
              }}
            />
            <div>or</div>
            <div
              style={{
                height: "1px",
                backgroundColor: "#efefef",
                width: "100%",
              }}
            />
          </StyledAlignCenter>
          <RadioComp
            selected={paymentOption === "net-banking"}
            label={"Net Banking"}
            onChange={() => setPaymentOption("net-banking")}
          >
            <NetBankingForm selectedBankForPayment={selectedBankForPayment} />
          </RadioComp>
        </PaymentCont>
      </ContRight>
    </Container>
  );
};

export default Payment;

const UPIForm = ({ selectedBankForPayment }) => {
  const initialErrorState = {
    vpa_id: "",
    general: "",
  };

  const validationSchema = yup.object().shape({
    vpa_id: yup
      .string()
      .matches(REGULAR_EXPRESSIONS.vpa_id, "UPI ID is not valid")
      .required("UPI ID is required"),
  });

  const formikRef = useRef(null);

  const { state: accumulatedPayload } = useLocation();
  const { showMessage } = useContext(AlertContext);

  const navigate = useNavigate();

  const [errorState, setErrorState] = useState(initialErrorState);

  const getPayload = (vpa_id, is_order_continued = false) => {
    let tempData = {
      payment_option: 1,
      bank: selectedBankForPayment,
      otp_required: true,
      otp: "",
      amount: +accumulatedPayload.amount,
      vpa_id,
    };
    if (is_order_continued) {
      tempData["order_number"] = accumulatedPayload.order_number;
    } else {
      tempData["bse_code"] = accumulatedPayload.bse_code;
    }
    if (accumulatedPayload?.folio_number) {
      tempData["folio_number"] = accumulatedPayload.folio_number;
    }
    if (accumulatedPayload.investmentType === "sip")
      tempData["start_date"] = parseInt(accumulatedPayload.monthlySipDate);

    return tempData;
  };

  const confirmOrder = () => {
    const payload = accumulatedPayload.comingFromOrders
      ? getPayload(formikRef.current.values.vpa_id, true)
      : getPayload(formikRef.current.values.vpa_id, false);

    navigate("/confirm-order", { state: payload, replace: true });
  };

  const initiateTransaction = ({ vpa_id }) => {
    const payload = accumulatedPayload.comingFromOrders
      ? getPayload(vpa_id, true)
      : getPayload(vpa_id, false);

    if (accumulatedPayload.comingFromOrders) {
      return credentialAxios.post(API_ENDPOINTS.payFromOrders, payload);
    } else {
      return credentialAxios.post(
        accumulatedPayload.investmentType === "sip"
          ? API_ENDPOINTS.createSipTransaction
          : API_ENDPOINTS.createOneTimeTransaction,
        payload
      );
    }
  };

  const onSuccess = (res) => {
    setErrorState(initialErrorState);

    if (accumulatedPayload.comingFromOrders) {
      if (res.data.request_sent) {
        navigate("/upi-transaction-timer", {
          state: {
            order_id: accumulatedPayload.order_number,
            amount: accumulatedPayload.amount,
          },
        });
      }
    } else {
      if (res.data.otp_sent) {
        showMessage(`OTP sent Successfully`);
        confirmOrder();
      }
    }
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState, [
      "vpa_id",
    ]);
    setErrorState(newErrors);
  };

  const { mutate, isLoading } = useMutation(initiateTransaction, {
    onError,
    onSuccess,
  });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        vpa_id: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        mutate(values);
      }}
    >
      {({ values, errors, handleSubmit, handleChange, touched, isValid }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InputField
              onChange={handleChange("vpa_id")}
              value={values.vpa_id}
              error={touched.vpa_id && (errors.vpa_id || errorState.vpa_id)}
              placeholder="9000000000@bank"
            />

            <Button
              type={"submit"}
              style={{ width: "100%", marginTop: "2rem" }}
              title={
                accumulatedPayload.comingFromOrders ? `CONTINUE` : `SEND OTP`
              }
              isLoading={isLoading}
              error={errorState.general}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const NetBankingForm = ({ selectedBankForPayment }) => {
  const initialErrorState = {
    general: "",
  };
  const { state: accumulatedPayload } = useLocation();
  const { showMessage } = useContext(AlertContext);

  const navigate = useNavigate();

  const [errorState, setErrorState] = useState(initialErrorState);

  const getPayload = (is_order_continued = false) => {
    let tempData = {
      payment_option: 2,
      bank: selectedBankForPayment,
      otp_required: true,
      otp: "",
      amount: +accumulatedPayload.amount,
    };
    if (is_order_continued) {
      tempData["order_number"] = accumulatedPayload.order_number;
    } else {
      tempData["bse_code"] = accumulatedPayload.bse_code;
    }
    if (accumulatedPayload?.folio_number) {
      tempData["folio_number"] = accumulatedPayload.folio_number;
    }
    if (accumulatedPayload.investmentType === "sip")
      tempData["start_date"] = parseInt(accumulatedPayload.monthlySipDate);

    return tempData;
  };

  const confirmOrder = () => {
    const payload = accumulatedPayload.comingFromOrders
      ? getPayload(true)
      : getPayload(false);

    navigate("/confirm-order", { state: payload });
  };

  const initiateTransaction = () => {
    const payload = accumulatedPayload.comingFromOrders
      ? getPayload(true)
      : getPayload(false);

    if (accumulatedPayload.comingFromOrders) {
      return credentialAxios.post(API_ENDPOINTS.payFromOrders, payload);
    } else {
      return credentialAxios.post(
        accumulatedPayload.investmentType === "sip"
          ? API_ENDPOINTS.createSipTransaction
          : API_ENDPOINTS.createOneTimeTransaction,
        payload
      );
    }
  };

  const onSuccess = (res) => {
    setErrorState(initialErrorState);

    if (accumulatedPayload.comingFromOrders) {
      const html = res.data.bank_html;

      navigate("/payment-web", {
        state: {
          html,
        },
      });
    } else {
      if (res.data.otp_sent) {
        showMessage(`OTP sent Successfully`);
        confirmOrder();
      }
    }
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState);
    setErrorState(newErrors);
  };

  const { mutate, isLoading } = useMutation(initiateTransaction, {
    onError,
    onSuccess,
  });

  return (
    <>
      <Button
        onClick={() => {
          mutate();
        }}
        error={errorState.general}
        style={{ width: "100%", marginTop: "2rem" }}
        isLoading={isLoading}
        title={accumulatedPayload.comingFromOrders ? `CONTINUE` : `SEND OTP`}
      />
    </>
  );
};
