import { css } from "styled-components";

export const Centering = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpaceBetween = css`
  display: flex;
  justify-content: space-between;
`;

export const AlignCenter = css`
  display: flex;
  align-items: center;
`;

export const SpaceBetweenAlignCenter = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InputStyle = css`
  padding: 1.2rem;
  background-color: ${({ theme }) => theme.cardBackground};
  width: 100%;
  border: none;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  font-size: 1.6rem;
  color: ${({ theme }) => theme.text};

  &:focus {
    outline: 1px solid var(--themeColorShade5);
  }
  &::placeholder {
    color: ${({ theme }) => theme.placeholderColor};
    font-weight: 400;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const InputCurrencyStyle = css`
  ${InputStyle}
  border-radius: 0px;
  font-weight: 700;
  font-size: 1.4rem;
  color: var(--themeColor);
  border-width: 0px;
  transition: all 0.3s ease-in-out;
  background-color: #20b2ab2c;
  text-align: right;
  border-radius: 4px;
  padding: 1rem;
  max-width: 12rem;
  width: 50%;
  &:focus {
    outline: 0px;
  }
`;

export const centering = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const space_between = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const align_center = css`
  display: flex;
  align-items: center;
`;
