import styled from "styled-components";
import { Centering, InputCurrencyStyle, centering } from "styles/common-styles";
import InputField from "components/common/InputField/InputField";

export const Container = styled.div`
  display: flex;
  gap: 10rem;

  @media (max-width: 980px) {
    flex-direction: column;
    gap: 4rem;
  }
`;

export const ContLeft = styled.div`
  flex: 2;
`;

export const OrderCard = styled.div`
  margin: 1rem 0;
  padding-bottom: 2rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border: 1px solid ${({ theme }) => theme.borderColor};
  background-color: ${({ theme }) => theme.cardBackground};
`;

export const OrdercardTop = styled.div`
  ${Centering};
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  flex-wrap: wrap;
  text-align: center;
  padding: 1rem 2rem;
`;

export const ContRight = styled.div`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  flex: 2;
  padding: 2rem;
  border-radius: 8px;
  ${centering}
`;

export const PaymentCont = styled.div`
  min-width: 40rem;
  @media (max-width: 980px) {
    min-width: 24rem;
  }
`;

export const PaymentOptionCard = styled.div`
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 3rem 0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.cardBackground};
`;

export const PaymentInput = styled(InputField)`
  ${InputCurrencyStyle}
  color: ${({ theme }) => theme.text};
`;

export const PayWithCont = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;
  position: relative;
`;
