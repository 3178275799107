import { Button } from "@mui/material";
import styled, { css } from "styled-components";

const contained = css`
  background-color: var(--themeColor) !important;
  color: var(--white) !important;

  &:hover:enabled {
    background-color: #1ea59f !important;
    color: white !important;
  }
`;

const outlined = css`
  background-color: var(--transparent) !important;
  color: ${({ theme }) => `${theme.text} !important`};

  &:hover:enabled {
    background-color: #1ea59f !important;
    color: white !important;
  }
`;

const error = css`
  background-color: rgba(163, 0, 0, 0.203) !important;
  color: var(--errorTextColor) !important;
  border: 1px solid rgba(163, 0, 0, 0.203) !important;
  margin-bottom: 1rem !important;
  width: auto !important;
`;

const VARIANTS = { contained, outlined, error };

const loadingCss = css`
  background-color: var(--themeColorShade7) !important;
  color: var(--tertiaryLightGrey) !important;
  border: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  cursor: not-allowed !important;
`;

export const StyledButton = styled(Button)`
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  width: 100% !important;
  border: 1px solid var(--themeColor) !important;
  padding: 6px 22px !important;
  ${({ loading }) => loading && loadingCss}
  ${({ variant, loading }) => !loading && VARIANTS[variant]}

  &:hover:disabled {
    cursor: not-allowed !important;
  }
`;
