import styled from "styled-components/macro";
import { Centering } from "styles/common-styles";

export const ModalBackDrop = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  z-index: 100000;
`;

export const ModalContentWrapper = styled.div`
  position: fixed;
  inset: 0;
  margin: auto;
  z-index: 100001;
  overflow: hidden;
  ${Centering}
`;

export const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 4px;
  min-width: 75rem;
  overflow: auto;

  @media (max-width: 980px) {
    min-width: 95% !important;
  }
`;
