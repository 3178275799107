import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import DataRenderer from "components/utility/DataRenderer";
import {
  API_ENDPOINTS,
  COLLECTIONS,
  TEMP_CATEGORIES,
  TOP_PERFORMING_AMOUNTS,
  TOP_PERFORMING_PERIODS,
} from "utils/constants";
import Image from "components/ui/Image/Image";
import { MFImageCont } from "pages/MutualFundsFilter/MutualFundsFilter.styles";
import { MobileView, StyledLink } from "styles/common-styled-components";
import Select from "components/ui/Select/Select";

const CollectionsDataCont = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 0 1.8rem;
  }
`;
const Collections = ({ category, lumpsum = false }) => {
  const { state } = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(TEMP_CATEGORIES[0]);
  const [amount, setAmount] = useState(1000);
  const [period, setPeriod] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  return (
    <>
      <MobileView style={{ height: "6rem" }} />
      <DataRenderer
        apiEndpoint={
          lumpsum ? API_ENDPOINTS.topLumpsumFunnds : API_ENDPOINTS.topSipFunds
        }
        queryName={"top-funds"}
        requestBody={{ category: category || selectedCategory, amount, period }}
        requestType="POST"
        queryDependencies={[selectedCategory]}
        renderData={(data) => {
          return (
            <CollectionsDataCont>
              <div>
                <FundListComponent
                  showCategoryDropdown={!category}
                  funds={data.data.schemes}
                  selectedCategory={selectedCategory}
                  onChange={(v) => {
                    setSelectedCategory(v);
                  }}
                  amount={amount}
                  onChangeAmount={(v) => setAmount(v)}
                  INDEX={state.INDEX}
                  onSubmit={() => setSubmitted(!submitted)}
                  period={period}
                  onChangePeriod={(v) => setPeriod(v)}
                />
              </div>
            </CollectionsDataCont>
          );
        }}
      />
    </>
  );
};

export default Collections;

const ListView = ({ funds, sortConfig }) => (
  <ListViewContainer>
    <ListViewHeader>
      <HeaderItem>Fund name</HeaderItem>
      <HeaderItem>Start date</HeaderItem>
      <HeaderItem>End date</HeaderItem>
      <HeaderItem>Invested Amount</HeaderItem>
      <HeaderItem>Current Value</HeaderItem>
      <HeaderItem>Returns</HeaderItem>
    </ListViewHeader>
    {funds.map((fund) => (
      <ListViewRow key={fund.id}>
        <FundDetails>
          <MFImageCont
            style={{ height: "4.8rem", width: "4.8rem", flexShrink: 0 }}
          >
            <Image
              src={fund.logo}
              alt={`${fund.wb_name} logo`}
              size={"4.8rem"}
            />
          </MFImageCont>
          <FundInfo>
            <StyledLink to={`/mutual-funds/${fund.scheme_plan_id}`}>
              <FundName>{fund.wb_name}</FundName>
            </StyledLink>
            <FundCategory>{fund.scheme_category}</FundCategory>
          </FundInfo>
        </FundDetails>
        <div>{fund.start_date}</div>
        <div>{fund.end_date}</div>
        <div>{fund.current_cost}</div>
        <div>{fund.current_value}</div>
        <div>{fund.returns}%</div>
      </ListViewRow>
    ))}
  </ListViewContainer>
);

const FundListComponent = ({
  funds,
  selectedCategory,
  onChange,
  showCategoryDropdown,
  INDEX,
  amount,
  onChangeAmount,
  onSubmit,
  period,
  onChangePeriod,
}) => {
  const [sortConfig, setSortConfig] = useState({
    key: "returns_abs_1year",
    direction: "desc",
  });

  const sortedFunds = useMemo(() => {
    return [...funds].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [funds, sortConfig]);

  const requestSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  return (
    <Container>
      <Header>
        <div>
          <h2>{COLLECTIONS[INDEX].label}</h2>
          <Title>{COLLECTIONS[INDEX].description}</Title>
        </div>
      </Header>

      <div
        style={{
          display: "flex",
          gap: "4rem",
          justifyContent: "space-between",
        }}
      >
        {showCategoryDropdown ? (
          <div>
            <p>Category</p>
            <Select
              options={TEMP_CATEGORIES.map((curr) => {
                return {
                  label: curr,
                  value: curr,
                };
              })}
              value={selectedCategory}
              onChange={onChange}
              placeholder="Select Category"
            />
          </div>
        ) : null}
        {/* <div>
          <p>Amount</p>
          <Select
            options={TOP_PERFORMING_AMOUNTS.map((curr) => {
              return {
                label: curr,
                value: curr,
              };
            })}
            value={amount}
            onChange={onChangeAmount}
            placeholder="Select Amount"
          />
        </div>
        <div>
          <p>Period</p>
          <Select
            options={TOP_PERFORMING_PERIODS.map((curr) => {
              return {
                label: curr === 1 ? "1 Year" : `${curr} Years`,
                value: curr,
              };
            })}
            value={period}
            onChange={onChangePeriod}
            placeholder="Select Period"
          />
        </div> */}
        {/* <div style={{ alignSelf: "center" }}>
          <Button name="Submit" onClick={onSubmit} />
        </div> */}
      </div>

      <ListView
        funds={sortedFunds}
        sortConfig={sortConfig}
        requestSort={requestSort}
      />
    </Container>
  );
};

const Container = styled.div``;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 2rem;
`;

const Title = styled.p`
  font-size: 1.4rem;
`;

const FundInfo = styled.div``;

const FundName = styled.p`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  &:hover {
    color: var(--themeColor);
    cursor: pointer;
  }
`;

const FundCategory = styled.p`
  margin: 5px 0 0;
  font-size: 1.2rem;
  color: #868e96;
`;

const ListViewContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  border: 1px solid var(--light-grey);
`;

const ListViewHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
  padding: 16px;
  border-bottom: 1px solid var(--light-grey);
  font-weight: bold;
  gap: 2rem;
  @media (max-width: 600px) {
    grid-template-columns: 3fr 1fr;
  }
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align || "left"};
  color: ${(props) => (props.active ? "var(--themeColor)" : "inherit")};
  font-weight: 500;

  @media (max-width: 600px) {
    display: ${({ dontShowInMobile }) => (dontShowInMobile ? "none" : "block")};
    text-align: ${({ textAlignMobile }) => textAlignMobile};
  }
`;

const ListViewRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
  padding: 16px;
  border-bottom: 1px solid var(--light-grey);
  align-items: center;
  gap: 2rem;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 600px) {
    grid-template-columns: 3fr 1fr;
  }
`;

const FundDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
