import styled from "styled-components/macro";
import { AlignCenter } from "styles/common-styles";

export const Container = styled.div`
  display: none;

  @media (max-width: 980px) {
    ${AlignCenter}
    gap: 2rem;
    top: 0;
    height: 6rem;
    position: fixed;
    padding: 2rem;
    right: 0;
    left: 0;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: ${({ theme }) => theme.cardBackground};
    z-index: 10000;
  }
`;
