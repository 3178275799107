import styled from "styled-components/macro";
import {
  AlignCenter,
  Centering,
  SpaceBetweenAlignCenter,
} from "styles/common-styles";
import { boxShadows } from "styles/theme.js";

const searchContHeight = "45vh";
const searchContWidth = "50rem";

const searchContWidthMobile = "100%";
const searchContHeightMobile = "85vh";

export const Container = styled.div`
  position: relative;

  @media (max-width: 980px) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }
`;
export const SearchBar = styled.input`
  border: 0;
  font-size: 1.2rem;
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  padding: 1rem;
  padding-left: 0;
  font-weight: 600;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--tertiaryLightGrey);
  }
`;

export const SearchBarCont = styled.div`
  border: ${({ theme }) => `1px solid ${theme.borderColor}`};
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 6px;
  padding-left: 1rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  width: ${searchContWidth};
  box-shadow: 0 1px 5px 0 ${({ theme }) => theme.boxShadow};
  @media (max-width: 980px) {
    width: ${searchContWidthMobile};
  }
`;

export const SearchResultsCont = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  background-color: ${({ theme }) => theme.background};
  box-shadow: ${boxShadows.themed};
  max-height: ${searchContHeight};
  overflow: auto;
  width: ${searchContWidth};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: var(--z-index-99);

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey color for the track */
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc; /* Grey color for the thumb */
    transition: all 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999; /* Darker grey color on hover */
  }

  @media (max-width: 980px) {
    width: ${searchContWidthMobile};
    max-height: ${searchContHeightMobile};
  }
`;

export const SearchCard = styled.div`
  ${AlignCenter};
  gap: 2rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.cardBackground2};
  }
`;

export const SearchLoadingCont = styled.div`
  ${Centering}
  height:${searchContHeight};
`;

export const NoResultCont = styled.div`
  ${Centering};
  height: ${searchContHeight};
  width: ${searchContWidth};
`;

export const RecentHistoryCard = styled.div`
  ${SpaceBetweenAlignCenter}
  gap:1rem;
  padding: 1rem 2rem;
  flex-wrap: wrap;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.cardBackground2};
  }
`;
