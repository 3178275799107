import React, { useContext } from "react";
import Button from "../Button/Button";
import { CloseButton } from "styles/common-styled-components";
import { AlertContext } from "App";
import storage from "redux-persist/lib/storage";
import { useNavigate } from "react-router-dom";

const LogoutFunctionality = ({ children }) => {
  const navigate = useNavigate();

  const { openModal, closeModal } = useContext(AlertContext);
  const LogoutComp = ({ onClose, onNo, onYes }) => {
    return (
      <div style={{ padding: "2rem", marginTop: "3rem" }}>
        <div style={{ width: "100%", textAlign: "right" }}>
          <CloseButton onClick={onClose} />
        </div>
        <h3 style={{ marginBottom: "2rem" }}>
          Are you sure you want to Logout
        </h3>
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "1rem",
            margin: "1rem 0",
          }}
        >
          <Button title={"YES"} variant="outlined" onClick={onYes} />
          <Button title="NO" onClick={onNo} />
        </div>
      </div>
    );
  };
  const logout = () => {
    openModal({
      comp: (
        <LogoutComp
          onClose={closeModal}
          onNo={closeModal}
          onYes={() => {
            storage.removeItem("persist:root");
            sessionStorage.setItem("auth-checked", false);
            sessionStorage.clear();
            navigate("/");
            window.location.reload();
          }}
        />
      ),
      style: { minWidth: "12rem" },
    });
  };

  return <div onClick={logout}>{children}</div>;
};

export default LogoutFunctionality;
