import { css } from "styled-components";
import styled from "styled-components/macro";
import { Centering } from "styles/common-styles";

const mainContDesktop = css`
  @media (min-width: 980px) {
    ${Centering}
  }
`;

export const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
  z-index: 100000;
  visibility: ${({ $visibility }) => ($visibility ? "visible" : "hidden")};

  ${({ desktop }) => !desktop && mainContDesktop};
`;

const overlayDesktop = css`
  @media (min-width: 980px) {
    position: fixed;
    inset: 0;
  }
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundOpacity = 0.6 }) =>
    `rgba(0,0,0,${backgroundOpacity})`};
  backdrop-filter: blur(3px);
  z-index: var(--z-index-highest);
  border: 1px solid #c06012;
  visibility: ${({ $visibility }) => ($visibility ? "visible" : "hidden")};

  @media (min-width: 980px) {
    backdrop-filter: blur(1px);
  }
  ${({ desktop }) => !desktop && overlayDesktop};
`;

const containerDesktop = css`
  @media (min-width: 980px) {
    border-radius: 4px;
    transition: none;
    min-height: 0;
    height: auto;
    max-width: 30rem;
    position: static;
  }
`;

export const Container = styled.div`
  position: fixed;
  background-color: ${({ theme }) => theme.cardBackground};
  bottom: 0;
  right: 0;
  left: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: auto;
  min-height: 6rem;
  padding: 2rem;
  visibility: ${({ $visibility }) => ($visibility ? "visible" : "hidden")};
  transform: ${({ show }) => (show ? `translateY(0)` : `translateY(100%)`)};

  transition: all 0.5s ease-in-out;

  @media (max-width: 980px) {
    min-width: 95vw;
  }

  @media (min-width: 980px) {
    left: 20rem;
    right: 20rem;
  }

  ${({ desktop }) => !desktop && containerDesktop};
`;
