import React from "react";

const DataTable = ({ data }) => {
  const tableData = React.useMemo(() => {
    return Object.keys(data).map((key) => ({
      property: key,
      value: data[key],
    }));
  }, [data]);

  return (
    <div
      style={{
        border: "1px solid var(--tertiaryLightGrey)",
        borderRadius: "4px",
        padding: "2rem",
      }}
    >
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td
                style={{
                  paddingRight: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                {row.property}
              </td>
              <th
                style={{
                  paddingBottom: "1rem",
                  textAlign: "left",
                }}
              >
                {row.value}
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
