import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { motion } from "framer-motion";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { FilterAltOutlined, Star } from "@mui/icons-material";

import MultipleSelectAccordion from "components/common/MultipleSelectAccordion/MultipleSelectAccordion";
import MobileNav from "components/common/MobileNav/MobileNav";
import BottomSheet from "components/common/BottomSheet/BottomSheet";
import Pagination from "components/common/Pagination/Pagination";
import { publicAxios } from "utils/api-calls";
import { ANIMATION_STYLES, API_ENDPOINTS } from "utils/constants";
import useDocumentTitle from "hooks/useDocumentTitle";

import {
  FilterMobileButton,
  FiltersContainer,
  FiltersTop,
  MFImageCont,
  MutualFundCard,
  MutualFundsContainer,
  MutualFundsList,
} from "./MutualFundsFilter.styles";
import {
  DesktopView,
  StyledAlignCenter,
  StyledCentering,
  SubHeading,
  UnderLineButton,
} from "styles/common-styled-components";
import { TagComp } from "pages/SingleMutualFund/SingleMutualFund.styles";
import {
  FiltersSkeleton,
  MutualFundCardSkeletion,
  TextSkeleton,
} from "./MutualFundsFilter.skeletons";
import { capitalizeText } from "utils/functions";

const MutualFundsFilter = () => {
  const LIMIT_PER_PAGE = 50;

  useDocumentTitle("All Mutual Funds | Filter Mutual Funds - WiseBulls");

  const ref = useRef(null);
  const navigation = useNavigate();

  const [fundParams, setFundParams] = useSearchParams();

  // CLIENT STATE: NEXT FUNDS DATA
  const [currentPage, setCurrentPage] = useState(
    Number(fundParams.get("page")) || 1
  );
  const [fundCategoryParam, setFundCategoryParam] = useState(
    fundParams.get("categories")
  );
  const [fundHouseParam, setFundHouseParam] = useState(
    fundParams.get("houses")
  );
  const [showFiltersInMobile, setShowFiltersInMobile] = useState(false);

  const onPageChange = (page) => {
    setCurrentPage(page);
    setFundParams({
      houses: fundParams.get("houses") === null ? "" : fundParams.get("houses"),
      categories:
        fundParams.get("categories") === null
          ? ""
          : fundParams.get("categories"),
      page,
    });
  };

  const handleSelectedFundHouses = (e, option) => {
    setCurrentPage(1);
    if (e.target.checked) {
      setFundParams({
        houses:
          fundParams.get("houses") === null
            ? `${option.name}`
            : `${fundParams.get("houses")},${option.name}`,
        categories:
          fundParams.get("categories") === null
            ? ""
            : fundParams.get("categories"),
      });

      setFundHouseParam(
        fundParams.get("houses") === null
          ? `${option.name}`
          : `${fundParams.get("houses")},${option.name}`
      );

      setFundCategoryParam(
        fundParams.get("categories") === null
          ? ""
          : fundParams.get("categories")
      );
    } else {
      const houses = fundParams
        .get("houses")
        .split(",")
        .filter((curr) => option.name !== curr)
        .join(",");

      setFundParams({
        houses,
        categories:
          fundParams.get("categories") === null
            ? ""
            : fundParams.get("categories"),
      });

      setFundHouseParam(houses);
      setFundCategoryParam(
        fundParams.get("categories") === null
          ? ""
          : fundParams.get("categories")
      );
    }
  };

  const handleSelectedFundCategories = (e, option) => {
    setCurrentPage(1);

    if (e.target.checked) {
      setFundParams({
        houses:
          fundParams.get("houses") === null ? "" : fundParams.get("houses"),
        categories:
          fundParams.get("categories") === null
            ? `${option.name}`
            : `${fundParams.get("categories")},${option.name}`,
      });

      setFundHouseParam(
        fundParams.get("houses") === null ? "" : fundParams.get("houses")
      );

      setFundCategoryParam(
        fundParams.get("categories") === null
          ? `${option.name}`
          : `${fundParams.get("categories")},${option.name}`
      );
    } else {
      const categories = fundParams
        .get("categories")
        .split(",")
        .filter((curr) => option.name !== curr);

      setFundParams({
        houses:
          fundParams.get("houses") === null ? "" : fundParams.get("houses"),
        categories,
      });

      setFundHouseParam(
        fundParams.get("houses") === null ? "" : fundParams.get("houses")
      );
      setFundCategoryParam(categories);
    }
  };

  // API CALLS

  const filterMutualFunds = (houseParam, categoryParam) => {
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setFundHouseParam(
      fundParams.get("houses") === null ? "" : fundParams.get("houses")
    );
    setFundCategoryParam(
      fundParams.get("categories") === null ? "" : fundParams.get("categories")
    );

    return publicAxios({
      method: "get",
      url: API_ENDPOINTS.filterMutualFunds,
      params: {
        fund_house: houseParam,
        category: categoryParam,
        limit: LIMIT_PER_PAGE,
        offset: (currentPage - 1) * LIMIT_PER_PAGE,
      },
    });
  };

  const getFundHousesData = () => {
    return publicAxios.get(API_ENDPOINTS.getFundHouses);
  };

  const getFundCategoriesData = () => {
    return publicAxios.get(API_ENDPOINTS.getFundCategories);
  };

  // SERVER STATE

  const onSuccessfulFirstFetch = (res) => {};

  const filteredResults = useQuery(
    ["mutual-funds", fundHouseParam, fundCategoryParam],
    () => filterMutualFunds(fundHouseParam, fundCategoryParam),
    {
      onSuccess: onSuccessfulFirstFetch,
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
  const fundHousesData = useQuery("fundHouses", getFundHousesData);
  const fundCatergoriesData = useQuery("fundCategories", getFundCategoriesData);

  useEffect(() => {
    filteredResults.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundCategoryParam, fundHouseParam, fundParams, currentPage]);

  const arr = new Array(5).fill(0);

  return (
    <div style={{ display: "flex" }}>
      {/* MOBILE */}

      <MobileNav headerTitle={"All Mutual Funds"} heightBelow="10rem" />
      <DesktopView style={{ flex: 1 }}>
        {fundHousesData.isLoading || fundCatergoriesData.isLoading ? (
          <FiltersSkeleton />
        ) : (
          <FiltersContainer
            as={motion.div}
            initial={ANIMATION_STYLES.opacity.initial}
            animate={ANIMATION_STYLES.opacity.animate}
            exit={ANIMATION_STYLES.opacity.exit}
          >
            <FiltersTop>
              <SubHeading style={{}}>Filters</SubHeading>
              <UnderLineButton
                onClick={() => {
                  setFundParams({
                    houses: "",
                    categories: "",
                  });
                  window.location.reload();
                  window.scrollTo(0, window.innerHeight);
                  window.scroll({
                    top: window.innerHeight - 50,
                    behavior: "smooth",
                  });
                }}
              >
                Clear all
              </UnderLineButton>
            </FiltersTop>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <MultipleSelectAccordion
                heading={"Fund Houses"}
                options={fundHousesData.data.data.results}
                handleChange={handleSelectedFundHouses}
                showSearchBar
                selectedOptions={
                  !fundParams.get("houses")
                    ? []
                    : fundParams.get("houses").split(",")
                }
              />

              <MultipleSelectAccordion
                heading={"Fund Categories"}
                options={fundCatergoriesData.data.data.results}
                handleChange={handleSelectedFundCategories}
                showSearchBar
                selectedOptions={
                  !fundParams.get("categories")
                    ? []
                    : fundParams.get("categories").split(",")
                }
              />
            </div>
          </FiltersContainer>
        )}
      </DesktopView>

      <MutualFundsList>
        <>
          {filteredResults.isLoading ? (
            <TextSkeleton />
          ) : (
            <DesktopView>
              <p style={{ marginBottom: "2rem" }}>
                <span style={{ fontSize: "1.4rem", fontWeight: "600" }}>
                  &nbsp; Mutual Funds
                </span>
              </p>
            </DesktopView>
          )}

          <FilterMobileButton
            onClick={() => {
              setShowFiltersInMobile(true);
            }}
          >
            <FilterAltOutlined />
            <p
              style={{
                fontSize: "1.4rem",
                color: "var(--themeColor)",
                fontWeight: "bold",
              }}
            >
              Filters
            </p>
          </FilterMobileButton>

          <MutualFundsContainer ref={ref}>
            {filteredResults.isLoading || filteredResults.isRefetching ? (
              <>
                {arr.map((_, i) => {
                  return <MutualFundCardSkeletion key={i} />;
                })}
              </>
            ) : (
              filteredResults.data.data.results.map((item, index) => {
                return (
                  <MutualFundCard
                    as={motion.div}
                    initial={ANIMATION_STYLES.opacity.initial}
                    animate={ANIMATION_STYLES.opacity.animate}
                    exit={ANIMATION_STYLES.opacity.exit}
                    key={index}
                    onClick={() => {
                      navigation(`/mutual-funds/${item.id}`);
                    }}
                  >
                    <StyledAlignCenter style={{ flexWrap: "wrap" }}>
                      {/* <ProfileButton style={{ backgroundColor: "#c01452" }}>
                        <img src={item.logo_url} alt={`${item.name} LOGO`} />
                      </ProfileButton> */}
                      <MFImageCont>
                        <LazyLoadImage
                          style={{ width: "100%", height: "auto" }}
                          src={item.logo_url}
                          alt={`${item.name} LOGO`}
                        />
                      </MFImageCont>
                      {capitalizeText(item.name)}
                    </StyledAlignCenter>
                    {/* <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <TagComp
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "800",
                          display: "inline-block",
                          padding: "1rem 2rem",
                        }}
                      >
                        Equity
                      </TagComp>
                      <TagComp
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "800",
                          display: "inline-block",
                          padding: "1rem 2rem",
                        }}
                      >
                        24.59% -Y
                      </TagComp>
                      <div
                        style={{
                          height: "4px",
                          width: "4px",
                          backgroundColor: "var(--themeBluishGrey)",
                          borderRadius: "50%",
                          margin: "0 2rem",
                        }}
                      />
                      <StyledAlignCenter>
                        5 <Star />
                      </StyledAlignCenter>
                    </div> */}
                  </MutualFundCard>
                );
              })
            )}
            {filteredResults.isLoading ? null : (
              <StyledCentering>
                <Pagination
                  currentPage={currentPage}
                  totalCount={filteredResults.data.data.count}
                  pageSize={50}
                  onPageChange={onPageChange}
                />
              </StyledCentering>
            )}
          </MutualFundsContainer>
        </>
      </MutualFundsList>

      <BottomSheet
        show={showFiltersInMobile}
        close={() => setShowFiltersInMobile(false)}
      >
        {fundHousesData.isLoading || fundCatergoriesData.isLoading ? (
          <FiltersSkeleton />
        ) : (
          <FiltersContainer
            as={motion.div}
            initial={ANIMATION_STYLES.opacity.initial}
            animate={ANIMATION_STYLES.opacity.animate}
            exit={ANIMATION_STYLES.opacity.exit}
          >
            <FiltersTop>
              <SubHeading style={{}}>Filters</SubHeading>
              <UnderLineButton
                onClick={() => {
                  setFundParams({
                    houses: "",
                    categories: "",
                  });
                  window.location.reload();
                  window.scrollTo(0, window.innerHeight);
                  window.scroll({
                    top: window.innerHeight - 50,
                    behavior: "smooth",
                  });
                }}
              >
                Clear all
              </UnderLineButton>
            </FiltersTop>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <MultipleSelectAccordion
                heading={"Fund Houses"}
                options={fundHousesData.data.data.results}
                handleChange={handleSelectedFundHouses}
                showSearchBar
                selectedOptions={
                  !fundParams.get("houses")
                    ? []
                    : fundParams.get("houses").split(",")
                }
              />

              <MultipleSelectAccordion
                heading={"Fund Categories"}
                options={fundCatergoriesData.data.data.results}
                handleChange={handleSelectedFundCategories}
                showSearchBar
                selectedOptions={
                  !fundParams.get("categories")
                    ? []
                    : fundParams.get("categories").split(",")
                }
              />
            </div>
          </FiltersContainer>
        )}
      </BottomSheet>
    </div>
  );
};

export default MutualFundsFilter;
