import { MoreVert } from "@mui/icons-material";
import React, { useRef, useState } from "react";

import { ButtonBase } from "@mui/material";
import styled from "styled-components";
import useOutsideClick from "hooks/useOutsideClick";

// Styled components
const Button = styled(ButtonBase)``;

const OptionsContainer = styled.div`
  position: absolute;
  top: 14px;
  left: 14px;
  display: ${(props) => (props.showOptions ? "block" : "none")};
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;

  @media (max-width: 600px) {
    right: 14px;
    left: auto;
  }
`;

const Option = styled.div`
  padding: 5px;
  cursor: pointer;
  min-width: 200px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

// Main component
const DropdownMenu = ({ options }) => {
  const optionsRef = useRef(null);

  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  useOutsideClick(optionsRef, () => {
    setShowOptions(false);
  });

  return (
    <div style={{ position: "relative" }}>
      <Button onClick={toggleOptions}>
        <MoreVert />
      </Button>
      <OptionsContainer showOptions={showOptions} ref={optionsRef}>
        {options.map((curr, i) => {
          return (
            <Option key={i} onClick={curr.functionality}>
              {curr.label}
            </Option>
          );
        })}
      </OptionsContainer>
    </div>
  );
};

export default DropdownMenu;
