import React from "react";
import { List, MobileView } from "styles/common-styled-components";
import { NumberLink } from "pages/Help/Help.styles";

const PrivacyPolicy = () => {
  return (
    <div>
      <MobileView style={{ marginTop: "8rem" }} />
      <div style={{ marginBottom: "2rem" }}>
        <h2 style={{ marginBottom: "1rem" }}>Privacy Policy</h2>
        <p>Last Updated: August 16, 2023</p>
      </div>
      <div>
        <p style={{ marginBottom: "2.4rem" }}>
          Welcome to the Privacy Policy for the WiseBulls Mutual Funds App .
          This document outlines how we collect, use, disclose, and safeguard
          your personal information when you use our App. By accessing or using
          the App, you agree to the terms outlined in this Privacy Policy.
        </p>
        <h4 style={{ marginBottom: "1rem" }}>1. Information We Collect</h4>
        <List>
          <li>
            Personal Information: We may collect personal information that you
            provide directly to us, such as your name, email address, contact
            information, and any other information you choose to provide when
            using the App.
          </li>
          <li>
            Financial Information: We may collect financial information related
            to your mutual fund investments, including your investment choices,
            transaction history, account balances, and other related
            information.
          </li>
          <li>
            Device and Usage Information: When you use the App, we may
            automatically collect information about your device, operating
            system, usage patterns, and interactions with the App to improve its
            functionality and user experience.
          </li>
        </List>
        <h4 style={{ marginBottom: "1rem" }}>2. How We Use Your Information</h4>
        <List>
          <li>
            Providing Services: We use the collected information to offer and
            maintain the functionality of the App, including managing your
            mutual fund investments, providing account statements, and
            facilitating transactions.
          </li>
          <li>
            Communication: We may use your contact information to communicate
            important updates, account information, and promotional materials
            related to mutual funds or our services.
          </li>
          <li>
            Improvement: We analyze collected data to enhance the App's
            features, troubleshoot technical issues, and personalize your user
            experience.
          </li>
        </List>
        <h4 style={{ marginBottom: "1rem" }}>3. Sharing of Your Information</h4>
        <List>
          <li>
            Service Providers: We may share your information with trusted
            third-party service providers who assist us in delivering the App's
            features, such as processing transactions, sending emails, and
            analyzing usage patterns.
          </li>
          <li>
            Legal Compliance: We may share your information when required to
            comply with legal obligations, protect our rights, respond to legal
            requests, or prevent fraudulent activities.
          </li>
        </List>
        <h4 style={{ marginBottom: "1rem" }}>4. Your Choices</h4>
        <List>
          <li>
            Account Information: You have the right to review, update, or delete
            your account information at any time by accessing your account
            settings within the App.
          </li>
          <li>
            Communication Preferences: You can opt out of receiving promotional
            emails by following the instructions in the emails or contacting us
            directly.
          </li>
        </List>
        <h4 style={{ marginBottom: "1rem" }}>5. Security</h4>
        <List>
          <li>
            We take reasonable measures to protect the security of your personal
            information and financial data. However, no method of transmission
            over the internet or electronic storage is completely secure. We
            cannot guarantee absolute security.
          </li>
        </List>
        <h4 style={{ marginBottom: "1rem" }}>6. Children's Privacy</h4>
        <List>
          <li>
            The App is not intended for use by individuals under the age of 18.
            We do not knowingly collect personal information from children.
          </li>
        </List>
        <h4 style={{ marginBottom: "1rem" }}>
          7. Changes to this Privacy Policy
        </h4>
        <List>
          <li>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or legal requirements. The updated policy
            will be posted on this page, and the "Last Updated" date will be
            revised accordingly.
          </li>
        </List>
        <h4 style={{ marginBottom: "1rem" }}>8. Contact Us</h4>
        <List>
          <li>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or the use of your personal information, please
            contact us at{" "}
            <a style={{}} href="mailto:info@wisebulls.com">
              info@wisebulls.com
            </a>
          </li>
          <li>
            By using the WiseBulls Mutual Funds App, you agree to the terms
            outlined in this Privacy Policy. Please review this policy
            periodically for any updates.
          </li>
        </List>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
