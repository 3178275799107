import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { config } from "react-spring";

import { Launch } from "@mui/icons-material";

import VerticalCarousel from "components/ui/VerticalCarousel/VeticalCarousel";
import Image from "components/ui/Image/Image";
import { REGISTRATION_STEPS, STYLE_VALUES } from "utils/constants";

import {
  StyledAlignCenter,
  StyledCentering,
  StyledLink,
} from "styles/common-styled-components";
import {
  RegistationCapsuleCont,
  RegistrationLeftCont,
  RegistrationRightCont,
  SimpleNavCont,
} from "components/ui/RegistrationCapsule/RegistrationCapsule.styles";
import { createPortal } from "react-dom";

import wiseBullsLogo from "assets/logos/Wisebulls_hd_1523_1523.png";
import Button from "components/ui/Button/Button";

const SimpleNav = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return createPortal(
    <SimpleNavCont
      style={{
        boxShadow: STYLE_VALUES.boxShadow,
      }}
    >
      {/* LOGO */}
      <Image
        src={wiseBullsLogo}
        alt="Logo"
        size={"6rem"}
        onClick={() => navigate("/")}
      />

      {/* RIGHT */}
      {location.state?.nonReg ? null : (
        <StyledAlignCenter style={{ gap: "2rem" }}>
          <>
            <Button
              title="CONTINUE LATER"
              onClick={() => navigate("/")}
              loadingText="Logging Out"
              contStyle={{ marginBottom: 0 }}
            />
          </>
        </StyledAlignCenter>
      )}
    </SimpleNavCont>,
    document.body
  );
};
const Registration = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const { currentUser } = useSelector((state) => state.user);

  const [registrationProgress, setRegistrationProgress] = useState({
    index: 0,
    percentage: 0,
    currentStep: 0,
  });

  useEffect(() => {
    // Find the matching step based on the current pathname
    const matchingStep = REGISTRATION_STEPS.find((step) =>
      step.url.some((path) => location.pathname.includes(path))
    );

    if (matchingStep) {
      // Calculate the percentage of the index in the total length
      const percentage =
        (REGISTRATION_STEPS.indexOf(matchingStep) /
          (REGISTRATION_STEPS.length - 1)) *
        100;

      // Update the registration progress state
      setRegistrationProgress({
        index: REGISTRATION_STEPS.indexOf(matchingStep),
        percentage: percentage,
        currentStep: matchingStep,
      });
    }
  }, [location.pathname]);

  const slides = REGISTRATION_STEPS.map((curr, index) => {
    return {
      key: index + 1,
      content: (
        <div style={{ padding: "2rem" }}>
          <h1 style={{ color: "#20b2aa", fontSize: "5rem" }}>{index + 1}</h1>
          <StyledAlignCenter style={{ gap: "1rem", marginBottom: "4px" }}>
            <h4 style={{ fontSize: "1.6rem" }}>{curr.label}</h4>
            {registrationProgress.index === index ? null : (
              <StyledLink to={curr.url[0]} style={{ fontSize: "1.4rem" }}>
                <Launch />
              </StyledLink>
            )}
          </StyledAlignCenter>

          <p style={{ fontSize: "1.4rem", marginBottom: "0" }}>
            {curr.description}
          </p>
        </div>
      ),
    };
  });

  useEffect(() => {
    if (!currentUser) {
      navigate("/");
    }
  }, []);

  const offsetRadius = 2;
  const showNavigation = true;
  const config2 = config.gentle;

  return (
    <div>
      <SimpleNav />
      <div
        style={{
          height: "calc(100vh - 10rem)",
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        <StyledCentering>
          <RegistationCapsuleCont>
            {location?.state?.nonReg ? null : (
              <RegistrationLeftCont>
                <>
                  <div style={{ marginTop: "6rem" }} />
                  <VerticalCarousel
                    slides={slides}
                    offsetRadius={offsetRadius}
                    showNavigation={showNavigation}
                    animationConfig={config2}
                    goToSlide={registrationProgress.index}
                  />
                </>
              </RegistrationLeftCont>
            )}

            <RegistrationRightCont nonReg={location.state?.nonReg}>
              <Outlet />
            </RegistrationRightCont>
          </RegistationCapsuleCont>
        </StyledCentering>
      </div>
    </div>
  );
};

export default Registration;
