import { createGlobalStyle } from "styled-components/macro";
const GlobalStyles = createGlobalStyle`
* {
margin: 0;
padding: 0;
box-sizing: border-box;
min-height: 0;
min-width: 0;
}

body {
background-color: ${({ theme }) => theme.background};
color: ${({ theme }) => theme.text};
font-family: 'Inter Tight', sans-serif;
font-family: 'Poppins', sans-serif;

font-size: 1.4rem;
color-scheme: ${({ theme }) => theme.colorScheme};
}

html,
body {
overflow-x: hidden;
overflow-y: scroll;
}

html {
font-size: 62.5%;

@media (max-width:600px){
    font-size: 56%;
}
@media (max-width:1024px){
    font-size: 57%;
}
@media (max-width:1136px){
    font-size: 58%;
}
@media (min-width:1600px){
    font-size: 60%;
}

.dim-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */
    z-index: 1; /* Ensure it's above other elements */
  }
}
`;

export default GlobalStyles;
