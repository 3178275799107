import { useCallback } from "react";

const useScrollLock = () => {
  const lockScroll = useCallback(() => {
    const content = document.querySelector("#content");
    content.style.overflowY = "hidden";
    content.style.width = "100%";
    content.style.position = "absolute";
    content.style.top = 0;
    content.style.bottom = 0;
  }, []);

  const unlockScroll = useCallback(() => {
    const content = document.querySelector("#content");
    content.style.overflowY = "";
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};

export default useScrollLock;
