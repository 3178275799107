import styled from "styled-components/macro";

export const Container = styled.div`
  overflow: hidden;
`;

export const Text = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
  font-size: 1.4rem;
  line-height: 1.6;
`;
