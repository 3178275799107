import React from "react";
import { useLocation } from "react-router-dom";
import InvestOptions from "components/common/InvestOptions/InvestOptions";
import MobileNav from "components/common/MobileNav/MobileNav";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useQuery } from "react-query";
import { publicAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";

const InvestMore = () => {
  const { state } = useLocation();
  useDocumentTitle(`Invest More in ${state.scheme_name} - WiseBulls`);
  const getMutualFundDetails = () => {
    return publicAxios.get(API_ENDPOINTS.getMFDetails(state.scheme_id));
  };
  const { isLoading, data: mfData } = useQuery(
    ["mutualFundData", state.scheme_id],
    getMutualFundDetails
  );

  return isLoading ? (
    <></>
  ) : (
    <div>
      <MobileNav headerTitle={"Invest More"} />

      <InvestOptions
        data={mfData.data}
        additionalPurchase
        folio_number={state.folio_number}
        noSip
        noSipMessage="Additional Purchase in SIP is not available right now"
      />
    </div>
  );
};

export default InvestMore;
