import React from "react";
import { Add } from "@mui/icons-material";
import Button from "components/ui/Button/Button";
import { StyledAlignCenter } from "styles/common-styled-components";

const FileInput = ({ setBase64URL, setSelectedFile }) => {
  const _handleReaderLoaded = (e) => {
    let binaryString = e.target.result;
    setBase64URL(btoa(binaryString));
  };

  const handleChange = (e) => {
    let file = e.target.files[0];

    // Validate file type
    const validImageTypes = ["image/jpeg", "image/png"];
    if (file && !validImageTypes.includes(file.type)) {
      alert("Please select a valid image file (JPG or PNG)");
      return;
    }

    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const handleButtonClick = () => {
    // Trigger the hidden input
    document.getElementById("fileInput").click();
  };

  return (
    <div>
      <input
        type="file"
        name="image"
        id="fileInput"
        accept=".jpg, .jpeg, .png"
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <Button
        contStyle={{ marginBottom: "2rem" }}
        variant="outlined"
        title={
          <StyledAlignCenter style={{ gap: "1rem" }}>
            <Add />
            <span>Choose File</span>
          </StyledAlignCenter>
        }
        onClick={handleButtonClick}
      />
    </div>
  );
};

export default FileInput;
