import DownloadReports from "pages/Investments/DownloadReports";
import React from "react";
import { MobileView, StyledCentering } from "styles/common-styled-components";

const Reports = () => {
  return (
    <div>
      <MobileView>
        <div style={{ height: "8rem" }} />
      </MobileView>
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          alignItems: "flex-start",
          paddingBottom: "2rem",
          borderBottom: "1px solid var(--secondaryLightGrey)",
        }}
      >
        <p style={{ marginBottom: "1rem" }}>
          Download your Mutual Funds Report here:
        </p>
        <DownloadReports />
      </div>
      <h2 style={{ padding: "1rem 0" }}>
        Additional mutual fund reports will be available shortly.
      </h2>
    </div>
  );
};

export default Reports;
