import styled from "styled-components/macro";

export const Container = styled.div`
  border: 1px solid #e9ecef;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 120px);

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);  */
`;

export const TopRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

export const MetricBoxLabel = styled.div`
  padding: 2rem;
  border-bottom: 1px solid #e9ecef;
  background-color: #f8f9fa;

  @media (max-width: 600px) {
    min-width: 24rem;
  }
`;

export const MetricBoxValue = styled(MetricBoxLabel)`
  padding: 1rem 2rem;
  border-left: 1px solid #e9ecef;
  background-color: var(--white);
  display: flex;
  align-items: center;
`;
