import Skeleton from "@mui/material/Skeleton";
import styled from "styled-components";

export const StyledSkeleton = styled(Skeleton)`
  background-color: ${({ theme }) => theme.skeletonBg};
`;

export const InvestmentsSummarySkeleton = ({
  height = 160,
  width = "100%",
}) => {
  return (
    <StyledSkeleton
      variant="rounded"
      width={width}
      height={height}
      style={{ display: "inline-block" }}
    />
  );
};
