import React, { useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import Button from "components/ui/Button/Button";
import InputField from "components/ui/InputField/InputField";
import { mapServerErrorsToLocal } from "utils/functions";
import { API_ENDPOINTS } from "utils/constants";

import { Form2 } from "styles/common-styled-components";
import { useSelector } from "react-redux";
import { credentialAxios } from "utils/api-calls";

const AddEmail = () => {
  const { userDetails } = useSelector((state) => state.user);
  const { state } = useLocation("");
  const formikRef = useRef();

  const fromLocation = state?.fromLocation;

  const initialErrorState = { email: "", password: "", general: "" };

  const navigate = useNavigate();

  const [errorState, setErrorState] = useState(initialErrorState);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password too short! Must be at least 8 characters.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Confirm Password too short! Must be at least 8 characters.")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  //// API CALL

  const handleAddEmail = ({ email, password }) => {
    return credentialAxios.post(API_ENDPOINTS.authSendEmailOtp, {
      email,
      password,
    });
  };

  const onSuccess = (res) => {
    setErrorState(initialErrorState);
    if (res?.data?.otp_sent) {
      navigate("/registration/otp-verification", {
        state: {
          email: res.data.email,
          password: formikRef.current.values?.password,
          fromLocation,
        },
      });
    }
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState, [
      "email",
      "password",
    ]);
    setErrorState(newErrors);
  };

  const { mutate, isLoading } = useMutation(handleAddEmail, {
    onSuccess,
    onError,
  });

  //// END API CALL

  return (
    <>
      <RegistrationCapsule
        heading={`${
          fromLocation
            ? "Please add your email"
            : "Additional authentication for WiseBulls."
        } `}
        para={
          "A OTP(One Time Password) will be sent to your email for verification."
        }
        fromLocation={fromLocation}
      >
        <Formik
          innerRef={formikRef}
          initialValues={{
            email: userDetails?.email || "",
            password: "",
            confirmPassword: "",
          }}
          onSubmit={mutate}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors: formikErrors,
            touched,
            handleSubmit,
            handleChange,
            isValid,
          }) => {
            return (
              <Form2 onSubmit={handleSubmit}>
                <InputField
                  label={"Email"}
                  value={values.email}
                  placeholder={"johndoe@example.com"}
                  onChange={handleChange("email")}
                  error={
                    (touched.email && formikErrors.email) || errorState.email
                  }
                />
                <InputField
                  label={"Password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  passwordField
                  error={
                    (touched.password && formikErrors.password) ||
                    errorState.password
                  }
                />
                <InputField
                  label={"Confirm Password"}
                  value={values.confirmPassword}
                  onChange={handleChange("confirmPassword")}
                  passwordField
                  error={
                    touched.confirmPassword && formikErrors.confirmPassword
                  }
                />

                <Button
                  title={"SEND OTP"}
                  type={"submit"}
                  isLoading={isLoading}
                  error={errorState.general}
                />
              </Form2>
            );
          }}
        </Formik>
      </RegistrationCapsule>
    </>
  );
};

export default AddEmail;
