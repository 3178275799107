import React, { useState } from "react";

import {
  ModalContainer,
  ModalLeft,
  ModalLogo,
  ModalRight,
} from "./LoginRegister.styles";

import {
  HeadingCont,
  HeadingPrimary,
  HeadingPrimaryBold,
  Paragraph,
  CloseButton,
} from "styles/common-styled-components";

import logo from "assets/logos/logo.png";
import LoginForm2 from "components/forms/LoginForm2";
import LoginForm from "components/forms/LoginForm";

const LoginModalContent = ({ closeModal, fromLocation = "/" }) => {
  const [loginWithEmail, setLoginWithEmail] = useState(false);

  return (
    <ModalContainer>
      <>
        <ModalLeft>
          <ModalLogo src={logo} />

          <div style={{ position: "absolute", bottom: "1rem", left: "3rem" }}>
            <HeadingPrimary color="#f7f8fa">
              Investing in{" "}
              <HeadingPrimaryBold color="#f7f8fa">
                Mutual Funds simplified
              </HeadingPrimaryBold>
            </HeadingPrimary>

            <HeadingPrimary color="#f7f8fa"></HeadingPrimary>
          </div>
        </ModalLeft>

        <ModalRight>
          <div style={{ width: "100%" }}>
            <HeadingCont>
              <HeadingPrimary>
                {loginWithEmail ? "Login" : "Login/Signup"} to{" "}
                <HeadingPrimaryBold>WiseBulls</HeadingPrimaryBold>
              </HeadingPrimary>

              <div style={{ position: "absolute", top: "1rem", right: "1rem" }}>
                <CloseButton onClick={closeModal} />
              </div>
            </HeadingCont>

            <Paragraph>
              {loginWithEmail ? "Login" : "Login/Signup"} to WiseBulls for a
              more personalised experience
            </Paragraph>

            {loginWithEmail ? (
              <LoginForm closeModal={closeModal} fromLocation={fromLocation} />
            ) : (
              <LoginForm2 closeModal={closeModal} fromLocation={fromLocation} />
            )}

            <Paragraph
              style={{
                margin: 0,
                marginBottom: "4px",
                color: "var(--secondary-light-grey)",
              }}
              textAlign="center"
            >
              Or
            </Paragraph>

            <p
              style={{
                textAlign: "center",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setLoginWithEmail(!loginWithEmail)}
            >
              {loginWithEmail
                ? "Login/Signup with phone and OTP"
                : "Login with email and password"}
            </p>
          </div>
        </ModalRight>
      </>
    </ModalContainer>
  );
};

export default LoginModalContent;
