import React, { useRef, useState } from "react";
import Button from "components/ui/Button/Button";

const SignaturePad = ({ setBase64URL, setError, onSave, setSelectedFile }) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const startDrawing = (event) => {
    event.preventDefault();
    const { clientX, clientY } = getEventCoordinates(event);
    const ctx = canvasRef.current.getContext("2d");

    const rect = canvasRef.current.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const offsetY = clientY - rect.top;

    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const draw = (event) => {
    event.preventDefault();
    if (!isDrawing) return;

    const { clientX, clientY } = getEventCoordinates(event);
    const ctx = canvasRef.current.getContext("2d");

    const rect = canvasRef.current.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const offsetY = clientY - rect.top;

    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
  };

  const endDrawing = () => {
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  };

  const handleSaveSignature = () => {
    const canvas = canvasRef.current;
    setBase64URL(canvas.toDataURL());
    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], "signature.png", { type: "image/png" });

        // Now 'file' is the actual File object you can send to the backend
        setSelectedFile(file);

        // Clear any previous errors
        setError("");

        // Perform additional actions if needed
        onSave();
      }
    }, "image/png");
  };

  const getEventCoordinates = (event) => {
    return event.touches ? event.touches[0] : event;
  };

  return (
    <div style={{ maxWidth: "320px", margin: "auto" }}>
      <canvas
        ref={canvasRef}
        width={320}
        height={200}
        style={{
          border: "1px solid #ccc",
          marginBottom: "2rem",
          width: "100%",
        }}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={endDrawing}
        onTouchStart={startDrawing}
        onTouchMove={draw}
        onTouchEnd={endDrawing}
      />
      <div>
        <Button
          variant="outlined"
          title="Clear Signature"
          onClick={clearCanvas}
          contStyle={{ marginBottom: "1rem" }}
        />
        <Button
          variant="outlined"
          title="Save Signature"
          onClick={handleSaveSignature}
          contStyle={{ marginBottom: "1rem" }}
        />
      </div>
    </div>
  );
};

export default SignaturePad;
