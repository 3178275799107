import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
    // You can log the error or perform other actions here
  }

  render() {
    const { fallback, children } = this.props;

    if (this.state.hasError) {
      // Render the error boundary fallback component
      return fallback || null;
    }

    return children;
  }
}

export default ErrorBoundary;
