import styled from "styled-components/macro";
import loginBanner from "assets/banner/loginbanner.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Centering } from "styles/common-styles";

export const MainCont = styled.div`
  ${Centering};
  height: calc(100vh - 8rem);
  @media (max-width: 980px) {
    height: auto;
  }
`;

export const ModalContainer = styled.div`
  height: 75vh;
  display: flex;
  align-items: "center";
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  background-color: white;
`;

export const Container = styled(ModalContainer)`
  @media (max-width: 980px) {
    flex-direction: column;
    box-shadow: none;
    position: fixed;
    top: 0;
    overflow: auto;
    height: 100vh;
  }
`;

export const Top = styled.div`
  flex: 1;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  position: relative;
  background-image: linear-gradient(to top, var(--themeColor), transparent),
    url(${loginBanner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;

  @media (max-width: 980px) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`;

export const ModalLeft = styled(Top)`
  @media (max-width: 980px) {
    display: none;
  }
`;

export const ModalLogo = styled(LazyLoadImage)`
  width: 10rem;
  position: absolute;
  top: 3rem;
  left: 3rem;

  @media (max-width: 980px) {
    width: 8rem;
  }
`;

export const Bottom = styled.div`
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey color for the track */
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc; /* Grey color for the thumb */
    transition: all 0.3s ease-in-out;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999; /* Darker grey color on hover */
  }
  @media (max-width: 980px) {
    flex: 2;
    display: block;
  }
`;

export const ModalRight = styled(Bottom)`
  display: flex;
  overflow: auto;
`;

export const WidthController = styled.div`
  width: 100%;

  @media (min-width: 980px) {
    width: 65%;
  }
`;

export const BackgroundPattern = styled.div`
  position: fixed;
  inset: 0;
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #e5e5f7 10px
    ),
    repeating-linear-gradient(#20b2aa55, #20b2aa);
`;
