import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { CheckCircle } from "@mui/icons-material";

import { AlertCont } from "./Alert.styles";

const Alert = ({ open, closeMessage, message }) => {
  return (
    <Snackbar open={open} onClose={closeMessage} autoHideDuration={3000}>
      <AlertCont>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <CheckCircle
            style={{ color: "var(--theme-color)", fontSize: "2rem" }}
          />
          <p>{message}</p>
        </div>
      </AlertCont>
    </Snackbar>
  );
};

export default Alert;
