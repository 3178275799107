import React from "react";
import styled from "styled-components";
import { ButtonBase } from "@mui/material";
import { Backspace } from "@mui/icons-material";

const DigitButton = ({ digit, onKeyPress, onBottomLeftPress }) => {
  return (
    <StyledButton
      focusRipple
      onClick={
        digit === "."
          ? () => onBottomLeftPress()
          : () => onKeyPress(digit.value)
      }
    >
      <ButtonText>{digit.title}</ButtonText>
    </StyledButton>
  );
};

const CustomPinKeyboard = ({ onKeyPress = () => {} }) => {
  const keypadLayout = [
    [
      { title: 1, value: 1 },
      { title: 2, value: 2 },
      { title: 3, value: 3 },
    ],
    [
      { title: 4, value: 4 },
      { title: 5, value: 5 },
      { title: 6, value: 6 },
    ],
    [
      { title: 7, value: 7 },
      { title: 8, value: 8 },
      { title: 9, value: 9 },
    ],
    [
      {
        title: ".",
        value: ".",
      },
      { title: 0, value: 0 },
      { title: <Backspace style={{ fontSize: "24px" }} />, value: "-" },
    ],
  ];
  return (
    <KeyboardContainer>
      {keypadLayout.map((row, rowIndex) => (
        <Row key={rowIndex}>
          {row.map((digit, index) => (
            <DigitButton key={index} digit={digit} onKeyPress={onKeyPress} />
          ))}
        </Row>
      ))}
    </KeyboardContainer>
  );
};

const KeyboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--secondaryLightGrey);
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const StyledButton = styled(ButtonBase)`
  width: 33.3333% !important;
  height: 70px !important;
`;

const ButtonText = styled.p`
  font-size: 24px;
`;

export default CustomPinKeyboard;
