import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import SelectBranchForm from "components/forms/SelectBranchForm";
import { Form2 } from "styles/common-styled-components";

const SelectBranch = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.bankId) {
      navigate("/registration/select-bank");
    }
  }, [state?.bankId, navigate]);

  return (
    <>
      <RegistrationCapsule
        heading={"Add your Bank account"}
        para={"Select your Branch to proceed with the adding Bank."}
      >
        <Form2>
          {state?.bankId ? (
            <SelectBranchForm
              bankId={state?.bankId}
              bankName={state?.bankName}
            />
          ) : null}
        </Form2>
      </RegistrationCapsule>
    </>
  );
};

export default SelectBranch;
