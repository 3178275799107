import styled from "styled-components";

export const PageCont = styled.div`
  display: flex;
  gap: 4rem;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;
export const BankAccountsCont = styled.div`
  flex: 1;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-bottom: 0px;
  border-radius: 6px;
  width: 100%;
  align-self: flex-start;
`;

export const BankAccountCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 3rem 2rem;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};

  background-color: ${({ theme, active }) =>
    active ? theme.tagBackground : theme.background};
  &:hover {
    background-color: ${({ noHover, theme }) =>
      noHover ? "" : theme.tagBackground};
  }
`;

export const PictureHolder = styled.div`
  height: ${({ dimension }) => (dimension ? `${dimension}rem` : "3rem")};
  width: ${({ dimension }) => (dimension ? `${dimension}rem` : "3rem")};

  background-color: #c01452;
  border-radius: 50%;
`;

export const BankDetailsCont = styled.div`
  flex: 1;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 6px;
  width: 100%;
`;

export const MandateCont = styled.div`
  display: flex;
  gap: 8rem;
  align-items: center;
  padding: 3rem;
  border-top: 1px solid ${({ theme }) => theme.borderColor};
`;

export const WaitingForLinkUIStyles = styled.div`
  @media (min-width: 980px) {
    max-width: 48rem;
  }
`;
