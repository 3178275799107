import React from "react";
import Note from "components/common/Note/Note";
import {
  BottomMessageCont,
  StyledCentering,
} from "styles/common-styled-components";
import Timer from "components/common/Timer/Timer";
import Button from "components/common/Button/Button";
import { WaitingForLinkUIStyles } from "../BankDetails.styles";

const WaitingForLinkUI = ({
  checkingLink,
  recentMandateId,
  onExpire,
  onContinueLater,
}) => {
  return (
    <div
      style={{
        position: "fixed",
        inset: 0,
        padding: "2.4rem",
        backgroundColor: "white",
        zIndex: "100000",
      }}
    >
      <StyledCentering>
        <WaitingForLinkUIStyles>
          <Note />
          <div>
            <StyledCentering
              style={{
                marginTop: "6rem",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid var(--tertiaryLightGrey)",
                  padding: "1.6rem",
                  borderRadius: "16px",
                }}
              >
                <Timer
                  style={{
                    fontSize: "2.8rem",
                    fontWeight: 800,
                    letterSpacing: "2px",
                  }}
                  numberOfMinutes={1.5}
                  onExpire={onExpire}
                />
              </div>
              <p style={{ fontWeight: "800", fontSize: "2.4rem" }}>
                {checkingLink ? "Checking for availability" : "waiting"}
              </p>
              <p style={{ textAlign: "center" }}>
                (Please Wait for 90 seconds to continue adding Mandate)
              </p>
            </StyledCentering>
            <BottomMessageCont>
              <p style={{ marginBottom: "1rem" }}>
                If you wish to continue later, you can continue from "Activate
                Autopay Mandates" in "Bank Details"
              </p>
              <p style={{ marginBottom: "1rem", fontWeight: "600" }}>
                (Mandate ID: {recentMandateId})
              </p>

              <Button
                name="Continue Later"
                variant="outlined"
                onClick={onContinueLater}
              />
            </BottomMessageCont>
          </div>
        </WaitingForLinkUIStyles>
      </StyledCentering>
    </div>
  );
};

export default WaitingForLinkUI;
