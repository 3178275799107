import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

const AuthenticatedUserNotAllowed = (props) => {
  const userToken = useSelector((state) => state.user.currentUser);

  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const checkUserToken = () => {
    if (userToken) {
      navigate("/");
      setTimeout(() => {
        setIsLoggedIn(true);
      }, 1000);
      return;
    } else {
      setIsLoggedIn(false);
    }
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return <>{!isLoggedIn ? props.children : <></>}</>;
};
export default AuthenticatedUserNotAllowed;
