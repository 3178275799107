import React, { useState } from "react";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Button from "components/ui/Button/Button";
import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import Select from "components/ui/Select/Select";
import { mapServerErrorsToLocal } from "utils/functions";
import {
  API_ENDPOINTS,
  INCOMES,
  OCCUPATIONS,
  TAX_STATUSES,
} from "utils/constants";
import { credentialAxios } from "utils/api-calls";

import { Form, Form2 } from "styles/common-styled-components";
import { useSelector } from "react-redux";

const AddProfessionalDetails = () => {
  const { kycInfo: localKycInfo } = useSelector((state) => state.user);

  const initialErrorState = {
    tax_status: "",
    occupation: "",
    income_slab: "",
    general: "",
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const [errorState, setErrorState] = useState(initialErrorState);

  const fromLocation = state?.fromLocation;
  const navigateTo = fromLocation || "/registration/select-bank";

  const validationSchema = Yup.object().shape({
    tax_status: Yup.string().required("Tax Status is required"),
    occupation: Yup.string().required("Occupation is required"),
    income_slab: Yup.string().required("Income Slab is required"),
  });

  //// API CALL

  const handleAddProfessionalDetails = (data) => {
    return credentialAxios.post(API_ENDPOINTS.addProfessionalDetails, data);
  };

  const onSuccess = (res) => {
    setErrorState(initialErrorState);
    navigate(navigateTo);
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState, [
      "tax_status",
      "occupation",
      "income_slab",
    ]);
    setErrorState(newErrors);
  };

  const { mutate, isLoading } = useMutation(handleAddProfessionalDetails, {
    onSuccess,
    onError,
  });

  //// END API CALL
  return (
    <>
      <RegistrationCapsule
        heading={`${fromLocation ? "Edit" : "Add"} professional details`}
        para={`Please ${
          fromLocation ? "edit" : "add"
        } your professional details`}
        fromLocation={fromLocation}
      >
        <Formik
          initialValues={{
            tax_status: localKycInfo.tax_status || "",
            occupation: localKycInfo.occ_code || "",
            income_slab: localKycInfo.income_slab || "",
          }}
          onSubmit={mutate}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, handleSubmit, handleChange }) => {
            return (
              <Form2 onSubmit={handleSubmit}>
                <Select
                  labelMapper={"Occupation"}
                  valueMapper={"OccupationCode"}
                  label={"Occupation"}
                  value={values.occupation}
                  onChange={handleChange("occupation")}
                  options={OCCUPATIONS}
                  error={
                    touched.occupation &&
                    (errors.occupation || errorState.occupation)
                  }
                />
                <Select
                  options={INCOMES}
                  label={"Income Slab"}
                  value={values.income_slab}
                  onChange={handleChange("income_slab")}
                  error={
                    touched.income_slab &&
                    (errors.income_slab || errorState.income_slab)
                  }
                />

                <Select
                  labelMapper={"taxStatus"}
                  valueMapper={"taxCode"}
                  options={TAX_STATUSES}
                  label={"Tax Status"}
                  value={values.tax_status}
                  onChange={handleChange("tax_status")}
                  error={
                    touched.tax_status &&
                    (errors.tax_status || errorState.tax_status)
                  }
                />

                <Button
                  title={fromLocation ? "EDIT" : "SUBMIT"}
                  type={"submit"}
                  isLoading={isLoading}
                  error={errorState.general}
                />
              </Form2>
            );
          }}
        </Formik>
      </RegistrationCapsule>
    </>
  );
};

export default AddProfessionalDetails;
