import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { KeyboardArrowUp } from "@mui/icons-material";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Function to check if the scroll height is greater than the device height
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const deviceHeight = window.innerHeight;
      setIsVisible(scrollTop > deviceHeight);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove scroll event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  return (
    <ButtonContainer isVisible={isVisible} onClick={scrollToTop}>
      <KeyboardArrowUp style={{ color: "#fff", fontSize: "2rem" }} />
    </ButtonContainer>
  );
};

export default ScrollToTop;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: ${({ isVisible }) =>
    isVisible ? "20px" : "-100px"}; /* Hide the button by default */
  right: 20px;
  background-color: var(--themeBluishGrey);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  cursor: pointer;
  z-index: 9999;
  transition: bottom 0.3s ease; /* Add transition effect */

  @media (max-width: 600px) {
    bottom: ${({ isVisible }) =>
      isVisible ? "80px" : "-100px"}; /* Hide the button by default */
  }
`;
